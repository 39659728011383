<div class="alert-list-wrapper">
  <div (click)="openAlert()" [ngClass]="{'active': isCurrentAlert}" class="alert-list-item">
    <div class="alert-list-item-header">
      <div class="alert-list-item-header-title">
        <h2>{{alert.title}}</h2>

        <img *ngIf="!alert?.delivered" src="./assets/icons/general/green-circle-icon.svg">
      </div>

      <p class="alert-list-item-header-time">{{alert?.createdTimestamp |  localDate:'shortDateTime'}}</p>
    </div>

    <p class="alert-list-item-subtitle">{{alert.message}}</p>
  </div>
</div>
