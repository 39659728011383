import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CountryInterface, LanguageInterface, TimeZoneInterface } from '../../interfaces';


@Injectable({
  providedIn: 'root',
})
export class StaticDataApiService {
  private readonly apiUrl: string = `${environment.apiAddress}/api`;

  constructor(private readonly httpClient: HttpClient) {
  }

  public getCountries(): Observable<CountryInterface[]> {
    return this.httpClient.get<CountryInterface[]>(`${this.apiUrl}/StaticData/country`);
  }

  public getStates(countryCode: string): Observable<string[]> {
    return this.httpClient.get<string[]>(`${environment.apiAddress}/api/StaticData/states?countryCode=${countryCode}`);
  }

  public getLanguages(): Observable<LanguageInterface[]> {
    return this.httpClient.get<LanguageInterface[]>(`${environment.apiAddress}/api/StaticData/language`);
  }

  public getTimeZones(): Observable<TimeZoneInterface[]> {
    return this.httpClient.get<TimeZoneInterface[]>(`${environment.apiAddress}/api/StaticData/timezones`);
  }
}
