<div class="modal-wrapper">
  <div class="small-icon-button d-flex justify-content-flex-end">
    <button (click)="closeDialog()" class="close-button" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="drag-and-drop-box"
       [ngClass]="{'box-bordered': FILE_UPLOADER_TYPE === fileUploaderTypeEnum.MULTIPLE}">
    <span class="upload-title">
      {{ FILE_UPLOADER_TYPE === fileUploaderTypeEnum.SINGLE ? ('fileUpload.uploadTitleShort' | translate) : ('fileUpload.uploadTitle' | translate) }}
    </span>
    <span class="upload-formats">{{ uploadExtensionsLabel }}</span>
    <button (click)="messageAttachment.click()" class="button-primary upload-button" mat-button>
      {{ 'fileUpload.uploadButtonLabel' | translate }}
    </button>
    <input #messageAttachment type="file" (change)="onFileSelected($event)"
           [accept]="ACCEPT_EXTENSION_TYPES">
  </div>

  <div class="file-item-box" *ngIf="this.fileForm.get('file').value?.name"
       [ngClass]="{'border-error': fileForm.invalid}">
    <img class="file-icon" src="../../../../assets/icons/general/file-icon.svg" alt="file">
    <div class="file-status-box">
      <span class="file-name">{{ this.fileForm.get('file').value.name }}</span>
      <div class="d-flex align-items-center">
        <span class="file-size">
          {{ fileForm.get('file').value.size | formatFileSize }}
        </span>

        <img class="upload-icon"
             [src]="fileForm.valid ?
              'assets/icons/general/file-upload-uploaded.svg' :
               'assets/icons/general/file-upload-failed.svg' ">

          <ng-container
            [ngTemplateOutlet]="validationErrors"
            [ngTemplateOutletContext]="{ form: fileForm }"
          >
            </ng-container>
      </div>
    </div>
    <button (click)="removeFile()" class="close-button" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <vi-clinic-chat-footer
    (onAttachFileClick)="messageAttachment.click()"
    (onSendMessage)="sendMessage($event)">
  </vi-clinic-chat-footer>
</div>

<ng-template #validationErrors let-form="form">
   <span class="file-upload-status" *ngIf="form.valid">
    {{ 'fileUpload.uploaded' | translate }}
  </span>

   <span class="file-upload-status error-status" *ngIf="form.get('file').hasError('incorrectSize')">
    {{ 'validationService.incorrectSize' | translate }}
  </span>

  <span class="file-upload-status error-status" *ngIf="form.get('file').hasError('incorrectType')">
    {{ 'validationService.wrongFileType' | translate }}
  </span>
</ng-template>
