<div class="doctor-profile-wrapper"
     *ngIf="selectedDoctorProfilePageRightPart === doctorProfilePageRightPartEnum.Profile && doctorInfo?.isVirtualAssistant == false">
  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" class="overflow-auto tab-group" (selectedTabChange)="tabChanged($event)">
    <mat-tab label="{{'general.personalInfo' | translate}}">
      <vi-clinic-doctor-profile-personal-info [personalInfo]="personalInfo"></vi-clinic-doctor-profile-personal-info>
    </mat-tab>

    <mat-tab label="{{'general.reviews' | translate}}">
      <vi-clinic-doctor-profile-reviews [doctorReviews]="doctorReviews"></vi-clinic-doctor-profile-reviews>
    </mat-tab>
  </mat-tab-group>

  <div class="tab-footer-container">
    <button *ngIf="personalInfo?.isFavorite" [ngClass]="{'margin-right-button': selectedTab === 1}" class="button-primary"
            (click)="setChatId()"
            mat-button>{{'doctorProfile.sendMessageLabel' | translate}}
    </button>
    <button *ngIf="selectedTab === 1" class="button-green"
            [routerLink]="['../visits/create-appointment']"
            [queryParams]="{doctorId: personalInfo?.id}"
            mat-button>{{'doctorProfile.scheduleVisitLabel' | translate}}</button>
  </div>

  <div class="heart-icon" (click)="markAsFavorite()">
    <mat-icon [svgIcon]="personalInfo?.isFavorite ? 'active-heart' : 'inactive-heart'"></mat-icon>
  </div>
</div>


<vi-clinic-chat
  *ngIf="selectedDoctorProfilePageRightPart === doctorProfilePageRightPartEnum.Message"
  (openDoctorProfileOnDoctorProfileEvent)="selectedDoctorProfilePageRightPart = doctorProfilePageRightPartEnum.Profile"
  [isDoctorProfilePage]="true">
</vi-clinic-chat>
