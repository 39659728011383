<div (click)="sendPatientInfo()" class="list-item">
  <div [ngClass]="{'active': isCurrentPatient}" class="list-item-wrapper">
    <div class="list-item-info-container">
      <div class="list-item-info">
        <div *ngIf="patientInfo?.lastVisitDate" class="patients-last-visit">
          <span class="patients-last-visit-label">{{'general.lastVisitLabel' | translate}}:</span>

          <span class="patients-last-visit-date">
            {{ patientInfo?.lastVisitDate | localDate:'longDateTime' }}
          </span>
        </div>

        <div *ngIf="!patientInfo?.lastVisitDate" class="patients-last-visit">
          <span class="patients-last-visit-label">{{'patients.notVisitedYetLabel' | translate}}</span>
        </div>
      </div>

      <div class="user-info">
        <span class="user-full-name">{{ patientInfo?.fullName }}</span>

        <div class="user-location">
          <img alt="location icon" class="user-location-icon" src="assets/icons/general/location.svg">

          <span>{{ patientInfo?.countryName }}</span>
        </div>
      </div>
    </div>

    <button mat-icon-button>
      <mat-icon>arrow_forward</mat-icon>
    </button>
  </div>
</div>

