import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  Patient,
  PatientInterface,
  RequestAppointmentInterface,
  UserGeneralInfoInterface
} from '../../../core/interfaces';

import { PatientService, SelectedAppointmentService } from '../../../core/services';

import { appointmentStatusListConstant } from '../../../core/constants';
import { RoleEnum } from '../../../core/enums';


@Component({
  selector: 'vi-clinic-visit-history-list-item',
  templateUrl: './visit-history-list-item.component.html',
  styleUrls: ['./visit-history-list-item.component.scss']
})
export class VisitHistoryListItemComponent implements OnInit, OnDestroy {
  @Input() public appointmentInfo: RequestAppointmentInterface;
  @Input() public role: RoleEnum;
  @Input() public status: number;

  @Output() public isProposeTimeOptionState = new EventEmitter();
  @Output() public patientId = new EventEmitter<string>();

  public currentPatientInfo: UserGeneralInfoInterface;
  public isCurrentItem: boolean;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private readonly patientService: PatientService,
              private readonly selectedAppointmentService: SelectedAppointmentService) {
  }

  public get Status(): string {
    return appointmentStatusListConstant
      .find((el) => el.code === this.appointmentInfo.status).name;
  }

  public ngOnInit(): void {
    this.getPatient();

    this.currentPatientInfo = this.appointmentInfo.familyMemberInfo || this.appointmentInfo.patientInfo;
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public sendPatientInfo(): void {
    if (!this.isCurrentItem) {
      this.patientId.emit(this.currentPatientInfo.id);
      this.isProposeTimeOptionState.emit(false);
      this.patientService.setPatientInfo(new Patient(this.currentPatientInfo, this.appointmentInfo) as PatientInterface);
      this.selectedAppointmentService.setSelectedAppointment(this.appointmentInfo);
    }
  }

  private getPatient(): void {
    this.patientService.getPatientInfo()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (patient) => {
          this.isCurrentItem = patient?.appointmentId === this.appointmentInfo.id;
        }
      );
  }
}
