<div class="select-patient-time">
  <p
    class="details-label">{{isDoctor ? ('requests.chooseAppointmentAvailabilityLabel' | translate) : ('visits.physicianProposedOptionsLabel' | translate)}}</p>

  <div [formGroup]="timeOptionsFormGroup" class="time-options">
    <mat-radio-group class="d-flex" formControlName="scheduleOptionId">
      <div class="time-options-item">
        <p class="label">{{'requests.firstTimeOptionLabel' | translate}}</p>

        <mat-radio-button disableRipple [disabled]="timeOptionsValidChecked[timeOptions[0]?.id]" [value]="timeOptions[0].id">
          {{timeOptions[0].value | localDate: 'shortDateTime'}}
        </mat-radio-button>

        <p *ngIf="timeOptionsValidChecked[timeOptions[0]?.id]"
           class="error">{{'requests.isNotAvailableAlreadyLabel' | translate}}</p>
      </div>

      <div *ngIf="timeOptions[1]" class="time-options-item">
        <p class="label">{{'requests.secondTimeOptionLabel' | translate}}</p>

        <mat-radio-button disableRipple [disabled]="timeOptionsValidChecked[timeOptions[1]?.id]" [value]="timeOptions[1].id">
          {{timeOptions[1].value | localDate: 'shortDateTime'}}
        </mat-radio-button>

        <p *ngIf="timeOptionsValidChecked[timeOptions[1]?.id]" class="error">
          {{'requests.isNotAvailableAlreadyLabel' | translate}}
        </p>
      </div>

      <div *ngIf="timeOptions[2]" class="time-options-item">
        <p class="label">{{'requests.thirdTimeOptionLabel' | translate}}</p>

        <mat-radio-button disableRipple [disabled]="timeOptionsValidChecked[timeOptions[2]?.id]" [value]="timeOptions[2].id">
          {{timeOptions[2].value | localDate: 'shortDateTime'}}
        </mat-radio-button>

        <p *ngIf="timeOptionsValidChecked[timeOptions[2]?.id]" class="error">
          {{'requests.isNotAvailableAlreadyLabel' | translate}}
        </p>
      </div>
    </mat-radio-group>
  </div>
</div>
