import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DoctorStatusEnum } from '../enums';

import { AuthService } from '../services';


@Injectable({
  providedIn: 'root'
})
export class StatusGuard implements CanActivate {

  constructor(private authService: AuthService,
              private router: Router) {
  }

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
    const acceptableStatuses: number[] = route.data.statuses;

    if (this.authService.isDoctor === false) {
      return true;
    }

    return this.authService.status$.pipe(
      map((status) => this.checkAccess(acceptableStatuses, status))
    );
  }

  private checkAccess(acceptableStatuses: number[], status: number): boolean {
    const isPageAvailable = acceptableStatuses.includes(status);

    if (!isPageAvailable && status === DoctorStatusEnum.PendingApproval) {
      this.router.navigate(['/pending-approval']).then();
    } else if (!isPageAvailable && status === DoctorStatusEnum.ChangeRequested || !isPageAvailable && status === DoctorStatusEnum.Registered) {
      this.router.navigate(['/profile-settings']).then();
    } else if (!isPageAvailable && status === DoctorStatusEnum.Rejected) {
      this.router.navigate(['/rejected']).then();
    }

    return isPageAvailable;
  }
}
