import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

import { PatientAddCardInterface, PatientAddCardResponseInterface, PatientBillingInterface } from '../../interfaces';

@Injectable({
  providedIn: 'root'
})
export class PatientBillingApiService {
  private url = `${environment.apiAddress}/api/PatientBilling`;

  constructor(private readonly httpClient: HttpClient) {
  }

  public getPatientBilling(providerId: number): Observable<PatientBillingInterface> {
    return this.httpClient.get<PatientBillingInterface>(`${this.url}/${providerId}`);
  }

  public addPatientBilling(body: PatientAddCardInterface): Observable<PatientAddCardResponseInterface> {
    return this.httpClient.post<PatientAddCardResponseInterface>(`${this.url}/card`, body);
  }

  public deletePatientBilling(providerId: number, cardId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/${providerId}/${cardId}`);
  }
}
