import {UsageFrequencyEnum} from '../enums';

export const usageFrequencyListConstant = [
  {
    'code': UsageFrequencyEnum.Daily,
    'name': 'patientEHR.frequency.dailyLabel',
  },
  {
    'code': UsageFrequencyEnum.Weekly,
    'name': 'patientEHR.frequency.weeklyLabel',
  }
];
