<div class="suggested-time-option">
  <p class="details-label">{{'requests.myAppointmentOptionsLabel' | translate}}</p>

  <div class="time-options">
    <div class="time-options-item">
      <p class="label">{{'requests.firstTimeOptionLabel' | translate}}</p>

      <p class="time-value">
        <mat-icon [svgIcon]="'calendar-icon'"
                  class="calendar-icon">
        </mat-icon>

        {{timeOptions[0]?.value | localDate:'longDateTime'}}
      </p>
    </div>

    <div class="time-options-item">
      <p class="label">{{'requests.secondTimeOptionLabel' | translate}}</p>

      <p class="time-value">
        <mat-icon [svgIcon]="'calendar-icon'"
                  class="calendar-icon">
        </mat-icon>

        {{timeOptions[1]?.value | localDate:'longDateTime'}}
      </p>
    </div>

    <div class="time-options-item">
      <p class="label">{{'requests.thirdTimeOptionLabel' | translate}}</p>

      <p class="time-value">
        <mat-icon [svgIcon]="'calendar-icon'"
                  class="calendar-icon">
        </mat-icon>

        {{timeOptions[2]?.value | localDate:'longDateTime'}}
      </p>
    </div>
  </div>
</div>
