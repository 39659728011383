import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {PatientVisitComponent} from '../../components/patient-visit/patient-visit.component';

import {RecommendationInterface} from '../../../core/interfaces';


@Component({
  templateUrl: './edit-recommendation-dialog.component.html',
  styleUrls: ['./edit-recommendation-dialog.component.scss']
})
export class EditRecommendationDialogComponent {

  constructor(public dialogRef: MatDialogRef<PatientVisitComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  public saveForm(data: RecommendationInterface): void {
    this.dialogRef.close(data);
  }

  public closeForm(): void {
    this.dialogRef.close();
  }
}
