<div class="patient-visit-wrapper">
  <div class="patient-visit-header">
    <div class="patient-visit-header-info">
      <h2 *ngIf="visitInfo?.doctorInfo?.id === doctorId" class="patient-visit-title">
        {{'patientVisit.patientVisitLabel' | translate}}
      </h2>

      <h2 *ngIf="visitInfo?.doctorInfo?.id !== doctorId" class="patient-visit-title">
        {{visitInfo?.doctorInfo?.firstName + ' ' + visitInfo?.doctorInfo?.lastName }}
      </h2>

      <span class="patient-visit-header-info-date">{{visitInfo?.visitDate | localDate:'longDateTime'}}</span>

      <span class="patient-visit-header-info-profession">{{visitInfo?.specialty?.name}}</span>

      <p [class.visit-type]="visitInfo.visitType === 1">
        {{visitInfo.visitType === 0 ? ('general.onlineLabel' | translate) : ('general.offlineLabel' | translate)}}
      </p>
    </div>

    <div *ngIf="(visitInfo.recommendations.length || visitInfo.summary) ||
     ((!visitInfo.recommendations.length || !visitInfo.summary) && allowingEditingDate && visitInfo?.doctorInfo?.id === doctorId)"
         class="small-icon-button">
      <button (click)="changeVisitSection()" mat-icon-button>
        <mat-icon>{{visitSectionStatus ? 'expand_less' : 'expand_more'}}</mat-icon>
      </button>
    </div>
  </div>

  <div *ngIf="visitSectionStatus">
    <vi-clinic-records [role]="role" [visitInfo]="visitInfo.summary"></vi-clinic-records>

    <div *ngIf="visitInfo.doctorInfo.id === doctorId && allowingEditingDate" class="patient-visit-button-wrapper">
      <button (click)="openEditRecommendationDialog()" class="button-transparent" mat-button>
        <span *ngIf="visitInfo.summary">{{'patientVisit.editRecordLabel' | translate}}</span>

        <span *ngIf="!visitInfo.summary">{{'patientVisit.addRecordLabel' | translate}}</span>
      </button>
    </div>

    <div class="patient-visit-basic-information-prescription">
      <div class="d-flex">
        <h3 *ngIf="visitInfo.recommendations?.length"
            class="patient-visit-basic-information-prescription-title">
          {{'patientVisit.recommendationLabel' | translate}}
        </h3>


        <button *ngIf="visitInfo.recommendations?.length" (click)="exportRecommendation()" class="button-primary"
                mat-button>{{'patientEHR.exportRecommendationLabel' | translate}}</button>
      </div>

      <vi-clinic-recommendation (prescriptionId)="deletedPrescription($event)"
                                *ngFor="let prescription of visitInfo?.recommendations"
                                [doctorId]="doctorId"
                                [recommendationInfo]="prescription"
                                [visitInfo]="visitInfo">
      </vi-clinic-recommendation>
    </div>

    <div *ngIf="showNewRecommendationButton && allowingEditingDate" class="patient-visit-button-wrapper">
      <button (click)="openAddNewPrescriptionDialog(visitInfo.id)" class="button-primary" mat-button>
        {{'patientVisit.addNewRecommendationsLabel' | translate}}
      </button>
    </div>
  </div>
</div>
