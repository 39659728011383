<div (click)="$event.stopPropagation()" [formGroup]="patientFiltersFormGroup" class="filter-menu">
  <h2 class="filter-title">{{'filterPatients.filterPatientsLabel' | translate}}</h2>

  <h3 class="filter-label">{{'filterPatients.patientNameLabel' | translate}}</h3>

  <mat-radio-group (change)="selectionChangePatientName($event.value)" aria-label="Select an option"
                   formControlName="patientName">
    <mat-radio-button disableRipple [checked]="patientFiltersFormGroup.get('patientName').value === filterItem.None"
                      [value]="filterItem.None">
      {{'filterPatients.noneLabel' | translate}}
    </mat-radio-button>

    <mat-radio-button disableRipple [checked]="patientFiltersFormGroup.get('patientName').value === filterItem.Ascending"
                      [value]="filterItem.Ascending">
      {{'filterPatients.ascendingLabel' | translate}}
    </mat-radio-button>

    <mat-radio-button disableRipple [checked]="patientFiltersFormGroup.get('patientName').value === filterItem.Descending"
                      [value]="filterItem.Descending">
      {{'filterPatients.descendingLabel' | translate}}
    </mat-radio-button>
  </mat-radio-group>

  <h3 class="filter-label">{{'filterPatients.visitTimeLabel' | translate}}</h3>

  <mat-radio-group (change)="selectionChangeVisitTime($event.value)" aria-label="Select an option"
                   formControlName="visitTime">
    <mat-radio-button disableRipple [checked]="patientFiltersFormGroup.get('visitTime').value == filterItem.None"
                      [value]="filterItem.None">
      {{'filterPatients.noneLabel' | translate}}
    </mat-radio-button>

    <mat-radio-button disableRipple [checked]="patientFiltersFormGroup.get('visitTime').value == filterItem.Ascending"
                      [value]="filterItem.Ascending">
      {{'filterPatients.ascendingLabel' | translate}}
    </mat-radio-button>

    <mat-radio-button disableRipple [checked]="patientFiltersFormGroup.get('visitTime').value == filterItem.Descending"
                      [value]="filterItem.Descending">
      {{'filterPatients.descendingLabel' | translate}}
    </mat-radio-button>
  </mat-radio-group>

  <h3 class="filter-label">{{'filterPatients.locationLabel' | translate}}</h3>

  <div class="input-container">
    <mat-form-field appearance="outline" class="input-container-item-large">
      <input formControlName="location" matInput placeholder="Ex. Great Britain"/>
    </mat-form-field>
  </div>

  <button (click)="applyFilters()" class="filter-button button-primary wide-button" mat-button>
    {{'filterPatients.applyFiltersLabel' | translate}}
  </button>
</div>
