import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {PrescriptionInterface, PreviousVisitInfoInterface} from '../../../core/interfaces';

import {
  strengthTypeListConstant,
  usageFormListConstant,
  usageFrequencyListConstant,
  usageRouteListConstant
} from '../../../core/constants';

import {EditPrescriptionDialogComponent} from '../../dialogs/edit-prescription/edit-prescription-dialog.component';

import {NotificationService, PatientApiService} from '../../../core/services';


@Component({
  selector: 'vi-clinic-recommendation',
  templateUrl: './recommendation.component.html',
  styleUrls: ['./recommendation.component.scss']
})
export class RecommendationComponent {
  @Input() public recommendationInfo: PrescriptionInterface;
  @Input() public visitInfo: PreviousVisitInfoInterface;
  @Input() public doctorId: string;
  @Input() public showActionButtons: boolean = false;
  @Input() public appointmentId: string;

  @Output() public prescriptionId = new EventEmitter();
  public usageFrequency = usageFrequencyListConstant;
  public strengthType = strengthTypeListConstant;
  public usageForm = usageFormListConstant;
  public usageRoute = usageRouteListConstant;
  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(public dialog: MatDialog,
              private readonly patientApiService: PatientApiService,
              private readonly notificationService: NotificationService) {
  }

  public get allowingEditingDate(): boolean {
    const currentDate: Date = new Date();
    const visitDate: Date = new Date(this.visitInfo.visitDate);
    const allowEditingTime: number = 604800000;

    return +currentDate < (+visitDate + allowEditingTime);
  }

  public getUsageFrequencyName(code: number): string {
    const index = this.usageFrequency.findIndex(element => element?.code === code);

    return this.usageFrequency[index]?.name;
  }

  public getStrengthTypeName(code: number): string {
    const index = this.strengthType.findIndex(element => element?.code === code);

    return this.strengthType[index]?.name;
  }

  public getUsageFormName(code: number): string {
    const index = this.usageForm.findIndex(element => element?.code === code);

    return this.usageForm[index]?.name;
  }

  public getUsageRouteName(code: number): string {
    const index = this.usageRoute.findIndex(element => element?.code === code);

    return this.usageRoute[index]?.name;
  }

  public openEditPrescriptionDialog(prescription: PrescriptionInterface): void {
    const dialogRefs = this.dialog.open(EditPrescriptionDialogComponent, {
      data: {
        prescription: prescription,
        isEdit: true
      }
    });

    dialogRefs.afterClosed()
      .subscribe(
        (result) => {
          if (result) {
            this.patientApiService.editPrescription(this.visitInfo?.id || this.appointmentId, result.id, result)
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe(
                () => {
                  this.recommendationInfo = result;
                  this.notificationService.showSuccessNotification();
                },
                (error) => this.notificationService.showErrorNotification(error?.error?.detail));
          }
        });
  }

  public deletedPrescription(prescriptionId: string): void {
    this.prescriptionId.emit(prescriptionId);
  }
}
