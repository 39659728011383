import {Pipe, PipeTransform} from '@angular/core';

import * as moment from 'moment';

import {LanguageService} from '../../core/services';


@Pipe({
  name: 'timeAgo',
  pure: false
})
export class TimeAgoPipe implements PipeTransform {
  constructor(private languageService: LanguageService) {
  }

  public transform(value: string): string {
    return value ? moment(value).locale(this.languageService.currentLanguage).fromNow() : '';
  }
}
