import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';

import {environment} from '../../../../environments/environment';

import { FamilyMemberInterface, NewFamilyMemberInterface } from '../../interfaces';

@Injectable({
  providedIn: 'root'
})
export class MedicalInformationApiService {
  private url = `${environment.apiAddress}/api`;

  constructor(private readonly httpClient: HttpClient) {
  }

  public getFamilyMembers(): Observable<FamilyMemberInterface[]> {
    return this.httpClient.get<FamilyMemberInterface[]>(`${this.url}/familymember`);
  }

  public addFamilyMember(familyMember: NewFamilyMemberInterface): Observable<FamilyMemberInterface> {
    return this.httpClient.post<FamilyMemberInterface>(`${this.url}/familymember`, familyMember);
  }

  public deleteFamilyMember(familyMemberId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/familymember/${familyMemberId}`);
  }
}
