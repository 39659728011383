<div class="prescription-content">
  <div class="prescription-content-items">
    <div class="prescription-block">
      <p>{{recommendationInfo?.tradeName}}</p>

      <p>{{recommendationInfo?.usageCount}} / {{getUsageFormName(recommendationInfo?.usageForm) | translate}}
        / {{getUsageRouteName(recommendationInfo?.usageRoute) | translate}}</p>
    </div>

    <div class="prescription-block">
      <p>{{recommendationInfo?.genericName}}</p>

      <p>{{recommendationInfo?.usageReps}} {{'patientVisit.timeLabel' | translate}}
        / {{getUsageFrequencyName(recommendationInfo?.usageFrequency) | translate}}</p>
    </div>

    <div class="prescription-block">
      <p>{{recommendationInfo?.strength}} / {{getStrengthTypeName(recommendationInfo?.strengthType) | translate}}</p>

      <p>{{'patientVisit.forDaysLabel' | translate: {days: recommendationInfo?.during} }}</p>
    </div>

    <div class="column-gap-1 d-flex" *ngIf="(visitInfo?.doctorInfo?.id === doctorId && allowingEditingDate) || showActionButtons">
      <button (click)="openEditPrescriptionDialog(recommendationInfo)" mat-icon-button>
        <img alt="" src="./assets/icons/general/edit-icon.svg">
      </button>

      <button (click)="deletedPrescription(recommendationInfo?.id)" mat-icon-button>
        <img alt="" src="./assets/icons/general/delete-icon.svg">
      </button>
    </div>
  </div>

  <p class="prescription-content-description">{{recommendationInfo?.description}}</p>
</div>
