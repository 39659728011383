import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';

import { OidcSecurityService } from 'angular-auth-oidc-client';

import { environment } from 'src/environments/environment';

import { SignalrEvent } from '../constants';

import { AlertsService } from './alerts.service';

import { AlertsInterface } from '../interfaces';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  public active = false;
  private connection: HubConnection;

  constructor(private readonly router: Router,
              private readonly oidc: OidcSecurityService,
              private readonly alertService: AlertsService) {
  }

  public start(): void {
    if (this.active) {
      return;
    }

    this.oidc.getAccessToken().pipe(first()).subscribe((value => {
      this.connection = new HubConnectionBuilder()
        .withUrl(environment.apiAddress + '/clientHub', {accessTokenFactory: () => value})
        .build();

      this.connection.onclose(() => this.startConnection());

      this.connection.on(
        SignalrEvent.doctorAcceptedYourRequest,
        (value: any) => this.alertService.newAlert$.next(value));

      this.connection.on(
        SignalrEvent.doctorRejectedYourRequest,
        (value: any) => this.alertService.newAlert$.next(value));


      this.connection.on(
        SignalrEvent.newAppointmentRequest,
        (value: any) => this.alertService.newAlert$.next(value));

      this.connection.on(
        SignalrEvent.newAsapAppointmentRequest,
        (value: AlertsInterface) => this.alertService.newAlert$.next(value));

      this.connection.on(
        SignalrEvent.patientAcceptedFollowUp,
        (value: any) => this.alertService.newAlert$.next(value));


      this.connection.on(
        SignalrEvent.patientRejectedFollowUp,
        (value: any) => this.alertService.newAlert$.next(value));

      this.connection.on(
        SignalrEvent.patientAcceptedScheduleProposal,
        (value: any) => this.alertService.newAlert$.next(value));

      this.connection.on(
        SignalrEvent.patientRejectedScheduleProposal,
        (value: any) => this.alertService.newAlert$.next(value));

      this.connection.on(
        SignalrEvent.patientAppointmentConfirmed,
        (value: any) => this.alertService.newAlert$.next(value));

      this.connection.on(
        SignalrEvent.patientAppointmentRejected,
        (value: any) => this.alertService.newAlert$.next(value));

      this.connection.on(
        SignalrEvent.newChatMessage,
        (value: any) => this.alertService.newAlert$.next(value));

      this.connection.on(
        SignalrEvent.appointmentReminder,
        (value: any) => this.alertService.newAlert$.next(value));

      this.connection.on(
        SignalrEvent.followUpReminder,
        (value: any) => this.alertService.newAlert$.next(value));

      this.connection.on(
        SignalrEvent.patientAcceptedInvitation,
        (value: any) => this.alertService.newAlert$.next(value));

      this.connection.on(
        SignalrEvent.adminApprovedDoctorRequest,
        (value: any) => this.alertService.newAlert$.next(value));

      this.connection.on(
        SignalrEvent.adminRequestedChangeOnDoctorRequest,
        (value: any) => this.alertService.newAlert$.next(value));

      this.connection.on(
        SignalrEvent.adminRejectedDoctorRequest,
        (value: any) => this.alertService.newAlert$.next(value));

      this.connection.on(
        SignalrEvent.patientAppointmentCanceled,
        (value: any) => this.alertService.newAlert$.next(value));

      this.connection.on(
        SignalrEvent.patientAppointmentNoAnswer,
        (value: any) => this.alertService.newAlert$.next(value));

      this.connection.on(
        SignalrEvent.errorPatientFundsCapture,
        (value: any) => this.alertService.newAlert$.next(value));

      this.connection.on(
        SignalrEvent.withdrawRequestApproved,
        (value: any) => this.alertService.newAlert$.next(value));

      this.connection.on(
        SignalrEvent.withdrawRequestDone,
        (value: any) => this.alertService.newAlert$.next(value));

      this.connection.on(
        SignalrEvent.withdrawRequestDeclined,
        (value: any) => this.alertService.newAlert$.next(value));

      this.connection.on(
        SignalrEvent.doctorNotifyAbsence,
        (value: any) => this.alertService.newAlert$.next(value));
      this.connection.on(
        SignalrEvent.appointmentExpired,
        (value: any) => this.alertService.newAlert$.next(value));

      this.connection.on(
        SignalrEvent.paymentHoldExpired,
        (value: any) => this.alertService.newAlert$.next(value));

      this.connection.on(
        SignalrEvent.paymentSucceeded,
        (value: any) => this.alertService.newAlert$.next(value));

      this.connection.on(
        SignalrEvent.paymentFailed,
        (value: any) => this.alertService.newAlert$.next(value));

      this.connection.on(
        SignalrEvent.errorFundsCapture,
        (value: any) => this.alertService.newAlert$.next(value));

      this.connection.on(
        SignalrEvent.newAppointmentScheduleProposal,
        (value: any) => this.alertService.newAlert$.next(value));

      this.connection.on(
        SignalrEvent.newFollowUpRequest,
        (value: any) => this.alertService.newAlert$.next(value));

      this.connection.on(
        SignalrEvent.followUpExpired,
        (value: any) => this.alertService.newAlert$.next(value));

      this.connection.on(
        SignalrEvent.videoRoomCreatedOrUpdated,
        (value: any) => {
          this.alertService.newAlert$.next(value);
        });

      this.connection.on(
        SignalrEvent.waitingRoomUpdated,
        (value: any) => {
          this.alertService.newAlert$.next(value);
        });

      this.connection.on(
        SignalrEvent.newAppointmentNote,
        (value: any) => {
          this.alertService.newAlert$.next(value);
        });

      this.connection.on(
        SignalrEvent.appointmentNoteUpdated,
        (value: any) => {
          this.alertService.newAlert$.next(value);
        });

      this.connection.on(
        SignalrEvent.newPatientRating,
        (value: any) => {
          this.alertService.newAlert$.next(value);
        });

      this.connection.on(
        SignalrEvent.patientRatingUpdated,
        (value: any) => {
          this.alertService.newAlert$.next(value);
        });

      this.connection.on(
        SignalrEvent.newAppointmentRecommendation,
        (value: any) => {
          this.alertService.newAlert$.next(value);
        });

      this.connection.on(
        SignalrEvent.appointmentRecommendationUpdated,
        (value: any) => {
          this.alertService.newAlert$.next(value);
        });

      this.connection.on(
        SignalrEvent.doctorAcceptedYourRequestAsap,
        (value: any) => {
          this.alertService.newAlert$.next(value);
        });

      this.connection.on(
        SignalrEvent.newDoctorRequestCreated,
        (value: any) => {
          this.alertService.newAlert$.next(value);
        });

      this.connection.on(
        SignalrEvent.doctorAcceptedInvitation,
        (value: any) => {
          this.alertService.newAlert$.next(value);
        });

      this.connection.on(
        SignalrEvent.appointmentFinished,
        (value: any) => {
          this.alertService.newAlert$.next(value);
        });

      this.connection.on(
        SignalrEvent.userIsWaiting,
        (value: any) => {
          this.alertService.newAlert$.next(value);
        });

      this.connection.on(
        SignalrEvent.patientNotifyAbsence,
        (value: any) => {
          this.alertService.newAlert$.next(value);
        });

      this.connection.on(
        SignalrEvent.newChatMessage,
        (value: any) => {
          this.alertService.newChatEvent$.next(value);
        });

      this.connection.on(
        SignalrEvent.newChat,
        (value: any) => {
          this.alertService.newChatEvent$.next(value);
        });

      this.connection.on(
        SignalrEvent.chatMessageRemoved,
        (value: any) => {
          this.alertService.newChatEvent$.next(value);
        });

      this.connection.on(
        SignalrEvent.chatNameUpdated,
        (value: any) => {
          this.alertService.newChatEvent$.next(value);
        });

      this.connection.on(
        SignalrEvent.newCustomOffer,
        (value: any) => {
          this.alertService.newChatEvent$.next(value);
        });

      this.startConnection();
    }));
  }

  public joinWaitingRoom(groupName: string): void {
    if (this.active) {
      this.connection.invoke('JoinRoom', groupName);
    }
  }

  public leaveWaitingRoom(groupName: string): void {
    if (this.active) {
      this.connection.invoke('LeaveRoom', groupName);
    }

  }

  public stop(): void {
    if (this.connection) {
      this.connection.stop();
    }
  }

  private startConnection(): void {
    this.connection.start()
      .then(() => this.active = true)
      .catch(async () => {
        this.active = false;
        await new Promise(resolve => setTimeout(resolve, 5000));
        this.startConnection();
      });
  }
}
