import {Component} from '@angular/core';


@Component({
  selector: 'vi-clinic-section-spinner',
  templateUrl: './section-spinner.component.html',
  styleUrls: ['./section-spinner.component.scss']
})
export class SectionSpinnerComponent {
}
