<div (click)="openFamilyMember()" [ngClass]="{'active': isCurrentFamilyMember}" class="medical-info-list-wrapper active">
  <div class="medical-info">
    <p class="medical-info-name">{{familyMember.firstName + ' ' + familyMember.lastName}}</p>

    <div *ngIf="true" class="medical-info-last-update">
      <span class="label">{{'general.lastUpdatedOnLabel' | translate}}:</span>

      <span class="date">
            {{ familyMember?.medicalHistoryUpdatedTimestamp ?
            (familyMember.medicalHistoryUpdatedTimestamp | localDate:'shortDateTime')
            : 'general.notUpdatedOnLabel' | translate }}
      </span>
    </div>
  </div>

  <button mat-icon-button>
    <mat-icon>arrow_forward</mat-icon>
  </button>
</div>
