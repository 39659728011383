import {formatDate} from '@angular/common';
import {Injectable} from '@angular/core';

import {CalendarDateFormatter, DateFormatterParams} from 'angular-calendar';


@Injectable()
export class CustomDateFormatter extends CalendarDateFormatter {
  public weekViewColumnHeader({date, locale}: DateFormatterParams): string {
    return formatDate(date, 'EEEE', locale);
  }

  public weekViewColumnSubHeader({date, locale}: DateFormatterParams): string {
    return formatDate(date, 'MMMM d', locale);
  }

  public weekViewHour({date, locale}: DateFormatterParams): string {
    return formatDate(date, 'HH:mm', locale);
  }
}
