import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AccountInfoInterface, PatientGeneralInfoInterface } from '../../interfaces';

@Injectable({
  providedIn: 'root'
})
export class PatientRequestsApiService {
  private url = `${environment.apiAddress}/api/patient`;

  constructor(private readonly httpClient: HttpClient) {
  }

  public getPatientInfo(): Observable<PatientGeneralInfoInterface> {
    return this.httpClient.get<PatientGeneralInfoInterface>(`${this.url}/ensure-created`);
  }

  public updatePatient(body: AccountInfoInterface): Observable<PatientGeneralInfoInterface> {
    return this.httpClient.put<PatientGeneralInfoInterface>(this.url, body);
  }

  public deletePatient(): Observable<void> {
    return this.httpClient.delete<void>(this.url);
  }
}
