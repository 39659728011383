import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';

import { forkJoin, Subject } from 'rxjs';
import { debounceTime, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { RoleEnum } from '../../../core/enums';

import {
  ChatHistoryInterface,
  ChatInterface,
  MarkMessagesAsDeliveredInterface,
  MessageInterface,
  UserGeneralInfoInterface
} from '../../../core/interfaces';

import { User } from '../../../core/models/user.model';

import { AlertsService, AuthService, MessageApiService, NotificationService } from '../../../core/services';
import { AddColleagueDialogComponent } from '../../dialogs/add-colleague-dialog/add-colleague-dialog.component';
import { ChatFileUploadDialogComponent } from '../../dialogs/chat-file-upload-dialog/chat-file-upload-dialog.component';
import {
  ChatParticipantsDialogComponent
} from '../../dialogs/chat-participants-dialog/chat-participants-dialog.component';
import {
  OfflineEhrPersonalMedicalHistoryDialogComponent
} from '../../dialogs/offline-ehr-personal-medical-history-dialog/offline-ehr-personal-medical-history-dialog.component';

import {
  RemoveChatWarningDialogComponent
} from '../../dialogs/remove-chat-warning-dialog/remove-chat-warning-dialog.component';
import { RenameChatDialogComponent } from '../../dialogs/rename-chat-dialog/rename-chat-dialog.component';
import { AddPatientDialogComponent } from '../../dialogs/add-patient-dialog/add-patient-dialog.component';


@Component({
  selector: 'vi-clinic-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {
  @ViewChild('messageContainer') public messageContainer: ElementRef;

  @Output() public markMessageAsDeliveredEvent = new EventEmitter<{ chatId: string, messageDeliveredCount: number }>();
  @Output() public updateLastMessageEvent = new EventEmitter<MessageInterface>();
  @Output() public deleteChatEvent = new EventEmitter<string>();
  @Output() public openEhrOnVideoCallEvent = new EventEmitter();
  @Output() public openEhrOnAppointmentEvent = new EventEmitter();
  @Output() public openEhrOnVisitHistoryEvent = new EventEmitter();
  @Output() public openRequestsDetailsOnRequestsEvent = new EventEmitter();
  @Output() public openDoctorProfileOnDoctorProfileEvent = new EventEmitter();
  @Output() public clearChatEvent = new EventEmitter<string>();
  @Output() public addParticipantsEvent = new EventEmitter<ChatInterface>();
  @Output() public renameChatEvent = new EventEmitter<{ chatId: string, chatName: string }>();
  @Output() public goToPatientChats = new EventEmitter();

  @Input() public isVideoCall: boolean = false;
  @Input() public isAppointmentPage: boolean = false;
  @Input() public isVisitHistoryPage: boolean = false;
  @Input() public isDoctorProfilePage: boolean = false;
  @Input() public isDoctorAiPage: boolean = false;
  @Input() public isRequestsPage: boolean = false;
  public currentUser: User;
  public chatHistory: ChatHistoryInterface;
  public messages: MessageInterface[];
  public participantsNames: string;
  public roleEnum = RoleEnum;
  private unsubscribe$: Subject<void> = new Subject();
  private reachedTop$: Subject<void> = new Subject();
  private markMessagesTrigger$: Subject<void> = new Subject();
  private lastMessageId: string;
  private messagesToMark: string[] = [];
  private participants: UserGeneralInfoInterface[];
  private patientId: string;

  constructor(private readonly messageApiService: MessageApiService,
              private readonly notificationService: NotificationService,
              private readonly activatedRoute: ActivatedRoute,
              private readonly dialog: MatDialog,
              private readonly authService: AuthService,
              private readonly alertService: AlertsService) {
  }

  public ngOnInit(): void {
    this.setChat();
    this.setCurrentUser();
    this.subscribeMarkMessages();
    this.subscribeScrollTop();

    this.chatDataEvent();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public sendMessage(messageValue: string): void {
    const text = messageValue.trim();

    if (text) {
      const newMessage = this.newMessage(text);
      const formData = this.messageFormData(text);
      this.messages.unshift(newMessage);
      setTimeout(() => this.scrollToBottom(true));
      this.requestSendMessage(formData, newMessage, text);
    }
  }

  public openFileUploadDialog(): void {
    const dialogRef: MatDialogRef<ChatFileUploadDialogComponent> = this.dialog.open(ChatFileUploadDialogComponent);
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: { message: string, file: File }) => {
        if (result) {
          if (result.file) {
            this.attachFile(result.file, result.message);
          } else if (result.message) {
            this.sendMessage(result.message);
          }
        }
      });
  }

  public attachFile(file: File, messageValue: string): void {
    const text = messageValue.trim();
    const newMessage = this.newMessage(text, file);
    const formData = this.messageFormData(text, file);
    this.messages.unshift(newMessage);

    setTimeout(() => this.scrollToBottom(true));

    this.requestSendMessage(formData, newMessage, text, file);
  }

  public messageBecomesVisible(message: MessageInterface): void {
    if (message.delivered || !this.currentUser || message.createdBy === this.currentUser.id) {
      return;
    }
    this.messagesToMark.push(message.id);
    this.markMessagesTrigger$.next();
  }

  public onChatScroll(event: any): void {
    const offsetTop = event.target.scrollTop + event.target.scrollHeight - event.target.offsetHeight;
    if (offsetTop > 100) {
      return;
    }

    this.reachedTop$.next();
  }

  public trackMessages = (index: number, message: MessageInterface) => message.id;

  public removeMessage(messageId: string): void {
    this.messageApiService.deleteMessage(this.chatHistory.id, messageId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        () => {
          const deleteMessageIndex = this.messages.findIndex((message) => message.id === messageId);
          if (deleteMessageIndex === 0 && !this.isVideoCall) {
            this.updateLastMessageEvent.emit({...this.messages[1], chatId: this.chatHistory.id});
          }

          this.messages.splice(deleteMessageIndex, 1);
        }, (error) => this.notificationService.showErrorNotification(error?.error?.detail));
  }

  public openEhrPage(): void {
    if (!this.isVideoCall && !this.isAppointmentPage && !this.isVisitHistoryPage && !this.isDoctorProfilePage) {
      const patientId = this.participants.find((user) => user.id !== this.currentUser.id).id;
      this.dialog.open(OfflineEhrPersonalMedicalHistoryDialogComponent, {
        data: {patientId, readonly: true},
        disableClose: true
      });
    } else if (this.isVideoCall) {
      this.openEhrOnVideoCallEvent.emit();
    } else if (this.isAppointmentPage) {
      this.openEhrOnAppointmentEvent.emit();
    } else if (this.isVisitHistoryPage) {
      this.openEhrOnVisitHistoryEvent.emit();
    }
  }

  public returnBack(): void {
    if (this.isDoctorProfilePage) {
      this.openDoctorProfileOnDoctorProfileEvent.emit();
    }

    if (this.isRequestsPage) {
      this.openRequestsDetailsOnRequestsEvent.emit();
    }
  }

  public removeChat(): void {
    const dialogRef = this.dialog.open(RemoveChatWarningDialogComponent);

    dialogRef.afterClosed()
      .pipe(
        filter((value: boolean) => value),
        switchMap(() => this.messageApiService.deleteChat(this.chatHistory.id)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(
        () => {
          this.deleteChatEvent.emit(this.chatHistory.id);
          this.chatHistory = null;
        },
        (error) => this.notificationService.showErrorNotification(error?.error?.detail));
  }

  public cleanChat(): void {
    this.messageApiService.cleanChat(this.chatHistory.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        () => {
          this.messages = [];
          this.clearChatEvent.emit(this.chatHistory.id);
        }
      );
  }

  public editChatName(): void {
    const dialogRef = this.dialog.open(RenameChatDialogComponent, {data: {chatName: this.chatHistory.chatName}});

    dialogRef.afterClosed()
      .pipe(
        filter((chatName) => !!chatName),
        switchMap((chatName) => {
          const data = {chatName};

          return this.messageApiService.renameChat(this.chatHistory.id, data)
            .pipe(
              tap(() => {
                  this.chatHistory.chatName = chatName;
                  this.renameChatEvent.emit({
                    chatId: this.chatHistory.id,
                    chatName
                  });
                }
              )
            );
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  public openAddColleaguesDialog(): void {
    const participantsIds = this.participants.map((user) => user.id);
    const dialogRef = this.dialog.open(AddColleagueDialogComponent, {
      data: { participantsIds }
    });

    dialogRef.afterClosed()
      .pipe(
        filter((data) => !!data),
        tap(
          (value) => {
            this.participants.push(...value.participants);
            this.setChatName();
          }
        ),
        switchMap((data) => this.messageApiService.addParticipantsToChat(this.chatHistory.id, {otherUserIds: data.otherUserIds})),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((chat) => {
        this.addParticipantsEvent.emit(chat);
      });
  }

  public openAddPatientsDialog(): void {
    const participantsIds = this.participants.map((user) => user.id);
    const dialogRef = this.dialog.open(AddPatientDialogComponent, {
      data: { participantsIds }
    });

    dialogRef.afterClosed()
      .pipe(
        filter((data) => !!data),
        tap(
          (value) => {
            this.participants.push(...value.participants);
            this.setChatName();
          }
        ),
        switchMap((data) => this.messageApiService.addParticipantsToChat(this.chatHistory.id, {otherUserIds: data.otherUserIds})),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((chat) => {
        this.chatHistory.isDoctors = chat.isDoctors;
        this.goToPatientChats.next()
      });
  }

  public openChatMembers(): void {
   this.dialog.open(ChatParticipantsDialogComponent, {
      data: {participants: this.participants}
    });
  }

  private setChat(): void {
    this.manageUrlWithChatId();
    this.manageUrlWithPatientOrDoctorId();
  }

  private manageUrlWithChatId(): void {
    this.activatedRoute.queryParams
      .pipe(
        filter((params) => !!params.chatId),
        switchMap((params: Params) => {
          const requests = [this.messageApiService.getChatHistory(params.chatId), this.messageApiService.getChatParticipants(params.chatId)];

          return forkJoin(requests);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(
        (response) => {
          this.messagesToMark = [];
          this.participantsNames = '';

          this.chatHistory = response[0] as ChatHistoryInterface;
          this.messages = this.chatHistory.messages.values;
          this.lastMessageId = this.messages[this.messages.length - 1]?.id;

          this.participants = response[1] as UserGeneralInfoInterface[];
          this.setChatName();

          setTimeout(() => this.scrollToBottom(true), 0);
        }
      );
  }

  private manageUrlWithPatientOrDoctorId(): void {
    this.activatedRoute.queryParams
      .pipe(
        filter((params) => !!params['patientId'] || !!params['doctorId']),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(
        (params) => {
          this.patientId = params['patientId'] || params['doctorId'];
          this.chatHistory = {};
          this.messages = [];
        }
      );
  }

  private setCurrentUser(): void {
    this.authService.currentUser$
      .pipe(
        filter((user) => !!user),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((user) => this.currentUser = user);
  }

  private subscribeMarkMessages(): void {
    this.markMessagesTrigger$
      .pipe(
        debounceTime(500),
        filter(() => !!this.messagesToMark.length),
        switchMap(() => {
          const messageIds = [...this.messagesToMark];
          this.markMessageAsDeliveredEvent.emit({
            chatId: this.chatHistory.id,
            messageDeliveredCount: this.messagesToMark.length
          });

          this.messagesToMark = [];
          const data: MarkMessagesAsDeliveredInterface = {
            userId: this.currentUser.id,
            messageIds
          };

          return this.messageApiService.markMessageAsDelivered(data, this.chatHistory.id);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => this.alertService.markMessageAsRead$.next());
  }

  private scrollToBottom(smooth: boolean): void {
    this.messageContainer?.nativeElement.scroll({
      top: 0,
      behavior: smooth ? 'smooth' : 'auto'
    });
  }

  private subscribeScrollTop(): void {
    this.reachedTop$
      .pipe(
        debounceTime(100),
        filter(() => this.chatHistory.messages.total > this.messages.length),
        switchMap(() => this.messageApiService.getChatHistory(this.chatHistory.id, this.lastMessageId)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(
        (chatHistory) => {
          this.messages = [...this.messages, ...chatHistory.messages.values];
          this.lastMessageId = this.messages[this.messages.length - 1].id;
        },
        (error) => this.notificationService.showErrorNotification(error?.error?.detail));
  }

  private chatDataEvent(): void {
    this.alertService.newChatEvent$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (chatData) => {
          const isCurrentChat = chatData.chatId === this.chatHistory?.id;
          if (!chatData.isNewChat && chatData.value && isCurrentChat || this.isVideoCall && chatData.value) {
            this.messages.unshift(chatData.value);
          } else if (isCurrentChat && !chatData.value && !chatData.chatName || this.isVideoCall && !chatData.value && !chatData.chatName) {
            const deletedMessageIndex = this.messages.findIndex((message) => message.id === chatData.notificationId);

            if (deletedMessageIndex !== -1) {
              this.messages.splice(deletedMessageIndex, 1);

              if (deletedMessageIndex === 0) {
                this.updateLastMessageEvent.emit(this.messages[0]);
              }
            }
          } else if (chatData.chatName && isCurrentChat && !chatData.value) {
            this.chatHistory.chatName = chatData.chatName;
          }
        }
      );
  }

  private newMessage(text: string, file?: File): MessageInterface {
    return {
      createdTimestamp: new Date().toUTCString(),
      createdBy: this.currentUser.id,
      text,
      originalFileName: file?.name,
      participant: {
        firstName: this.currentUser.firstName,
        lastName: this.currentUser.lastName,
        id: this.currentUser.id
      }
    };
  }

  private messageFormData(text: string, file?: File): FormData {
    let data: MessageInterface;
    if (this.chatHistory.id) {
      data = {
        chatId: this.chatHistory.id,
        text,
        file
      };
    } else {
      data = {
        otherUserId: this.patientId,
        text,
        file
      };
    }

    const formData: FormData = new FormData();

    for (const key of Object.keys(data)) {
      const value = data[key];
      formData.append(key, value);
    }

    return formData;
  }

  private requestSendMessage(formData: FormData, newMessage: MessageInterface, text: string, file?: File): void {
    this.messageApiService.sendMessage(formData, true)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (message) => {
          if (this.messages.length === 1) {
            this.chatHistory.id = message.chatId;
          }

          newMessage.id = message.id;
          newMessage.attachmentUrl = message.attachmentUrl;
          this.updateLastMessageEvent.emit({
            chatId: this.chatHistory.id,
            createdTimestamp: new Date().toUTCString(),
            text,
            originalFileName: file?.name
          });
        }, (error) => {
          this.notificationService.showErrorNotification(error?.error?.detail);
          this.messages.shift();
        }
      );
  }

  private setChatName(): void {
    if (!this.chatHistory || !this.chatHistory.isGroupChat) {
      return;
    }

    this.participantsNames = this.participants.map((user) => `${user.firstName} ${user.lastName}`).join(', ');
  }
}
