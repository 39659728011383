// modules
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
  CalendarCommonModule,
  CalendarDateFormatter,
  CalendarEventTitleFormatter,
  CalendarMonthModule,
  CalendarWeekModule
} from 'angular-calendar';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxStripeModule } from 'ngx-stripe';

import { environment } from '../../environments/environment';

// components
import { AlertsDetailsComponent } from './components/alerts-details/alerts-details.component';
import { AlertsListItemComponent } from './components/alerts-list-item/alerts-list-item.component';
import { AppointmentsListItemComponent } from './components/appointments-list-item/appointments-list-item.component';
import { AvailableTimeOptionsComponent } from './components/available-time-options/available-time-options.component';
import { CalendarViewComponent } from './components/calendar-view/calendar-view.component';
import { CustomDateFormatter } from './components/calendar-view/helpers/custom-date-formatter';
import { CustomEventTitleFormatter } from './components/calendar-view/helpers/custom-event-title-formatter';
import { CardsComponent } from './components/cards/cards.component';
import { ChatComponent } from './components/chat/chat.component';
import {
  ControlValidationMessageComponent
} from './components/control-validation-message/control-validation-message.component';
import { DoctorListItemComponent } from './components/doctor-list-item/doctor-list-item.component';
import {
  DoctorProfilePersonalInfoComponent
} from './components/doctor-profile-personal-info/doctor-profile-personal-info.component';
import { DoctorProfileReviewsComponent } from './components/doctor-profile-reviews/doctor-profile-reviews.component';
import { DoctorProfileComponent } from './components/doctor-profile/doctor-profile.component';
import { EditPrescriptionComponent } from './components/edit-prescription/edit-prescription.component';
import { EditRecommendationComponent } from './components/edit-recommendation/edit-recommendation.component';
import { FilterDoctorsComponent } from './components/filter-doctors/filter-doctors.component';
import { FilterPatientsComponent } from './components/filter-patients/filter-patients.component';
import { FilterVisitsComponent } from './components/filter-visit/filter-visits.component';
import { MedicalHistoryComponent } from './components/medical-history/medical-history.component';
import {
  MedicalInformationListItemComponent
} from './components/medical-information-list-item/medical-information-list-item.component';
import {
  OfflineEhrGeneralInformationComponent
} from './components/offline-ehr-general-information/offline-ehr-general-information.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { PatientEhrComponent } from './components/patient-ehr/patient-ehr.component';
import { PatientVisitComponent } from './components/patient-visit/patient-visit.component';
import { PatientsListItemComponent } from './components/patients-list-item/patients-list-item.component';
import { ProposeTimeOptionsComponent } from './components/propose-time-options/propose-time-options.component';
import { RecommendationComponent } from './components/recommendation/recommendation.component';
import { RecordsComponent } from './components/record/records.component';
import { RequestStatusComponent } from './components/request-status/request-status.component';
import { SectionSpinnerComponent } from './components/section-spinner/section-spinner.component';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
import { SuggestedTimeOptionsComponent } from './components/suggested-time-options/suggested-time-options.component';
import { VideoCardComponent } from './components/video-card/video-card.component';
import { VisitHistoryListItemComponent } from './components/visit-history-list-item/visit-history-list-item.component';
import { AddColleagueDialogComponent } from './dialogs/add-colleague-dialog/add-colleague-dialog.component';
import { AddFamilyMemberDialogComponent } from './dialogs/add-family-member-dialog/add-family-member-dialog.component';
import { AddNewPatientsDialogComponent } from './dialogs/add-new-patients-dialog/add-new-patients-dialog.component';
import { ChatFileUploadDialogComponent } from './dialogs/chat-file-upload-dialog/chat-file-upload-dialog.component';
import { ChatParticipantsDialogComponent } from './dialogs/chat-participants-dialog/chat-participants-dialog.component';
import {
  CompleteMedicalHistoryDialogComponent
} from './dialogs/complete-medical-history-dialog/complete-medical-history-dialog.component';
import { ConfirmationDialogComponent } from './dialogs/confirmation-dialog/confirmation-dialog.component';
import { DeleteDialogComponent } from './dialogs/delete-patient-dialog/delete-dialog.component';
import { EditPrescriptionDialogComponent } from './dialogs/edit-prescription/edit-prescription-dialog.component';
import { EditRecommendationDialogComponent } from './dialogs/edit-recommendation/edit-recommendation-dialog.component';
import {
  OfflineEhrPersonalMedicalHistoryDialogComponent
} from './dialogs/offline-ehr-personal-medical-history-dialog/offline-ehr-personal-medical-history-dialog.component';
import { OfflineVisitDialogComponent } from './dialogs/offline-visit-dialog/offline-visit-dialog.component';
import {
  RemoveChatWarningDialogComponent
} from './dialogs/remove-chat-warning-dialog/remove-chat-warning-dialog.component';
import { RenameChatDialogComponent } from './dialogs/rename-chat-dialog/rename-chat-dialog.component';
import { WriteMessageDialogComponent } from './dialogs/write-message-dialog/write-message-dialog.component';
import { ChatFooterComponent } from './components/chat-footer/chat-footer.component';
import { AddPatientDialogComponent } from './dialogs/add-patient-dialog/add-patient-dialog.component';
import {
  CreateCustomAppointmentOfferDialogComponent
} from './dialogs/create-custom-appointment-offer-dialog/create-custom-appointment-offer-dialog.component';
import {InviteUserComponent} from "./components/invite-user/invite-user.component";
import {AddNewColleagueDialogComponent} from "./dialogs/add-new-colleague-dialog/add-new-colleague-dialog.component";
import {DoctorProfileDialogComponent} from "./dialogs/doctor-profile-dialog/doctor-profile-dialog.component";
import {
  RegistrationPatientDialogComponent
} from "./dialogs/registration-patient-dialog/registration-patient-dialog.component";

// directives
import { ObserveVisibilityDirective } from './directives/observe-visibility.directive';
import { MaterialModule } from './modules/material.module';
import { CentTransformPipe } from './pipes/cent-transform.pipe';
import { ChatNamePipe } from './pipes/chat-name.pipe';
import { FileSizeConverterPipe } from './pipes/file-size-converter.pipe';
import { LocalDatePipe } from './pipes/local-date.pipe';
import { MaskPipe } from './pipes/mask.pipe';

// pipes
import { SearchPipe } from './pipes/search.pipe';
import { TimeAgoPipe } from './pipes/time-ago.pipe';


@NgModule({
  declarations: [
    MaskPipe,
    AppointmentsListItemComponent,
    RequestStatusComponent,
    PatientsListItemComponent,
    FilterPatientsComponent,
    SearchPipe,
    ControlValidationMessageComponent,
    EditRecommendationDialogComponent,
    EditPrescriptionDialogComponent,
    PatientEhrComponent,
    PatientVisitComponent,
    FilterVisitsComponent,
    DeleteDialogComponent,
    AlertsListItemComponent,
    AlertsDetailsComponent,
    CentTransformPipe,
    TimeAgoPipe,
    ChatComponent,
    ObserveVisibilityDirective,
    CentTransformPipe,
    VideoCardComponent,
    RecommendationComponent,
    RecordsComponent,
    SectionSpinnerComponent,
    WriteMessageDialogComponent,
    ChatNamePipe,
    AddColleagueDialogComponent,
    RemoveChatWarningDialogComponent,
    RenameChatDialogComponent,
    ChatParticipantsDialogComponent,
    VisitHistoryListItemComponent,
    ProposeTimeOptionsComponent,
    CalendarViewComponent,
    AddNewPatientsDialogComponent,
    OfflineEhrGeneralInformationComponent,
    OfflineEhrPersonalMedicalHistoryDialogComponent,
    OfflineVisitDialogComponent,
    EditRecommendationComponent,
    EditPrescriptionComponent,
    ConfirmationDialogComponent,
    MedicalInformationListItemComponent,
    AddFamilyMemberDialogComponent,
    CompleteMedicalHistoryDialogComponent,
    FilterDoctorsComponent,
    DoctorListItemComponent,
    PaginatorComponent,
    DoctorProfileComponent,
    DoctorProfilePersonalInfoComponent,
    DoctorProfileReviewsComponent,
    StarRatingComponent,
    AvailableTimeOptionsComponent,
    SuggestedTimeOptionsComponent,
    LocalDatePipe,
    FileSizeConverterPipe,
    CardsComponent,
    MedicalHistoryComponent,
    ChatFileUploadDialogComponent,
    ChatFooterComponent,
    AddPatientDialogComponent,
    CreateCustomAppointmentOfferDialogComponent,
    RegistrationPatientDialogComponent,
    InviteUserComponent,
    AddNewColleagueDialogComponent,
    DoctorProfileDialogComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MaterialModule,
    NgxIntlTelInputModule,
    CalendarMonthModule,
    CalendarWeekModule,
    CalendarCommonModule,
    MatRippleModule,
    NgxMaterialTimepickerModule,
    NgxStripeModule.forRoot(environment.stripeKey)
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MaterialModule,
    AppointmentsListItemComponent,
    RequestStatusComponent,
    PatientsListItemComponent,
    NgxIntlTelInputModule,
    NgxMaterialTimepickerModule,
    FilterPatientsComponent,
    ControlValidationMessageComponent,
    EditRecommendationDialogComponent,
    EditPrescriptionDialogComponent,
    PatientEhrComponent,
    PatientVisitComponent,
    SearchPipe,
    FilterVisitsComponent,
    DeleteDialogComponent,
    AlertsListItemComponent,
    AlertsDetailsComponent,
    CentTransformPipe,
    TimeAgoPipe,
    ChatComponent,
    ObserveVisibilityDirective,
    CentTransformPipe,
    VideoCardComponent,
    RecommendationComponent,
    RecordsComponent,
    SectionSpinnerComponent,
    ChatNamePipe,
    AddColleagueDialogComponent,
    VisitHistoryListItemComponent,
    ProposeTimeOptionsComponent,
    MaskPipe,
    CalendarMonthModule,
    CalendarWeekModule,
    CalendarCommonModule,
    CalendarViewComponent,
    FilterDoctorsComponent,
    DoctorListItemComponent,
    MedicalInformationListItemComponent,
    AddFamilyMemberDialogComponent,
    CompleteMedicalHistoryDialogComponent,
    PaginatorComponent,
    DoctorProfileComponent,
    AvailableTimeOptionsComponent,
    SuggestedTimeOptionsComponent,
    StarRatingComponent,
    LocalDatePipe,
    CardsComponent,
    MedicalHistoryComponent,
    FileSizeConverterPipe,
    InviteUserComponent
  ],
  providers: [
    LocalDatePipe,
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
    {
      provide: CalendarEventTitleFormatter,
      useClass: CustomEventTitleFormatter,
    }
  ]
})
export class SharedModule {
}
