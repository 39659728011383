import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PatientInterface } from '../../../core/interfaces';

import { PatientService } from '../../../core/services';


@Component({
  selector: 'vi-clinic-patients-list-item',
  templateUrl: './patients-list-item.component.html',
  styleUrls: ['./patients-list-item.component.scss']
})
export class PatientsListItemComponent implements OnInit, OnDestroy {
  @Input() public patientInfo: PatientInterface;
  public isCurrentPatient: boolean;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private readonly patientService: PatientService) {
  }

  public ngOnInit(): void {
    this.patientService.getPatientInfo()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (patient) => {
          this.isCurrentPatient = patient?.id === this.patientInfo.id;
        }
      );
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public sendPatientInfo(): void {
    if (!this.isCurrentPatient) {
      this.patientService.setPatientInfo(this.patientInfo);
    }
  }
}
