import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import {FilterItemEnum, FilterVisitItemEnum} from '../../../core/enums';

import { FilterVisitsInterface } from '../../../core/interfaces';

import { FilterVisitsFormGroup } from '../../../core/types';


@Component({
  selector: 'vi-clinic-filter-visits',
  templateUrl: './filter-visits.component.html',
  styleUrls: ['./filter-visits.component.scss']
})

export class FilterVisitsComponent implements OnInit {
  @Output() public visitFilters = new EventEmitter<FilterVisitsInterface>();

  public filterItem = FilterItemEnum;
  public filterVisitItem = FilterVisitItemEnum;
  public visitFiltersFormGroup: FormGroup<FilterVisitsFormGroup>;

  constructor(private readonly fb: FormBuilder) {
  }

  public ngOnInit(): void {
    this.setForms();
  }

  public applyFilters(): void {
    this.visitFilters.emit(this.visitFiltersFormGroup.value as FilterVisitsInterface);
  }

  public setForms(): void {
    this.visitFiltersFormGroup = this.fb.group<FilterVisitsFormGroup>({
      byDoctor: this.fb.control(FilterVisitItemEnum.None),
      visitTime: this.fb.control(FilterVisitItemEnum.None),
      language: this.fb.control(null),
    });
  }
}
