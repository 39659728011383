import {Component, Input, OnInit} from '@angular/core';

import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

import {AlertsInterface} from '../../../core/interfaces';

import {AlertsService} from '../../../core/services';


@Component({
  selector: 'vi-clinic-alerts-list-item',
  templateUrl: './alerts-list-item.component.html',
  styleUrls: ['./alerts-list-item.component.scss']
})
export class AlertsListItemComponent implements OnInit {
  @Input() public alert: AlertsInterface;
  public isCurrentAlert: boolean;
  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private readonly alertService: AlertsService) {
  }

  public ngOnInit(): void {
    this.alertService.alertInfo$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (alert) => {
          if (alert) {
            this.isCurrentAlert = alert?.id === this.alert.id;
          }
        }
      );
  }

  public openAlert(): void {
    this.alertService.alertInfo$.next(this.alert);
  }
}
