import {Injectable} from '@angular/core';

import {BehaviorSubject} from 'rxjs';

import {RequestAppointmentInterface} from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class RequestDetailsService {
  private selectedRequest$: BehaviorSubject<RequestAppointmentInterface> = new BehaviorSubject<RequestAppointmentInterface>(null);

  public getSelectedRequest(): BehaviorSubject<RequestAppointmentInterface> {
    return this.selectedRequest$;
  }

  public setSelectedRequest(request: RequestAppointmentInterface): void {
    this.selectedRequest$.next(request);
  }
}
