import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CodeVerificationApiService {
  private readonly apiUrl = `${environment.apiAddress}/api/Patient`;

  constructor(private readonly http: HttpClient) {
  }

  public sendCodeVerification(model: { token: string, userId: string }): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}/codeVerification`, model);
  }

  public resendCodeVerification(model: { userId: string }): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}/resendVerificationCode`, model);
  }
}
