<div class="mobile-header" *ngIf="display && (systemOs === 'ios' || systemOs === 'android')">
  <div class="header-content">
    <div class="close-icon" (click)="display = false">
      <img src="assets/icons/general/close.svg" alt="&times;">
    </div>
    <div class="header-logo-container">
      <img src="assets/images/vc-logo-mobile.svg" alt="logo">
      <div class="header-text-container">
        <span class="vc-text">ViClinic</span>
        <span class="header-description">{{'mobileHeader.tryMobileHeaderLabel' | translate}}</span>
      </div>
    </div>
  </div>
  <a class="mobile-link"
     target="_blank"
     [href]="systemOs === 'ios' ? 'https://apps.apple.com/us/app/viclinic/id1591979875' :
     'https://play.google.com/store/apps/details?id=com.ViClinic.Viclinic&amp;pli=1'">
    {{'general.openLabel' | translate}}
  </a>
</div>
