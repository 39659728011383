import {Component, EventEmitter, OnInit, Output} from '@angular/core';

import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

import {AlertsInterface} from '../../../core/interfaces';

import {AlertsService} from '../../../core/services';


@Component({
  selector: 'vi-clinic-alert-response',
  templateUrl: './alerts-details.component.html',
  styleUrls: ['./alerts-details.component.scss']
})
export class AlertsDetailsComponent implements OnInit {
  @Output() public alertId = new EventEmitter();
  public alertInfo: AlertsInterface;
  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private readonly alertService: AlertsService) {
  }

  public ngOnInit(): void {
    this.alertService.alertInfo$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (alert) => {
          this.alertInfo = alert;
        }
      );
  }
}
