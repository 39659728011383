import {Injectable} from '@angular/core';

import {BehaviorSubject, Observable} from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class SelectedRecommendationDialogService {
  private selectedRecommendationDialogSubject: BehaviorSubject<number> = new BehaviorSubject(null);
  public selectedRecommendationDialog$: Observable<number> = this.selectedRecommendationDialogSubject.asObservable();


  public onSelectRecommendationDialog(selectedRecommendationDialog: number): void {
    this.selectedRecommendationDialogSubject.next(selectedRecommendationDialog);
  }
}
