<div (click)="$event.stopPropagation()" [formGroup]="doctorFiltersFormGroup" class="filter-menu">
  <h2 class="filter-title">{{'filterDoctors.filterDoctorsLabel' | translate}}</h2>

  <h3 class="filter-label">{{'filterDoctors.countryLabel' | translate}}</h3>

  <div class="input-container">
    <mat-form-field appearance="outline" class="input-container-item-large">
      <mat-label>{{'filterDoctors.countryLabel' | translate}}</mat-label>
      <mat-select formControlName="countryCode">
        <mat-option *ngFor="let country of (countries$ | async)" [value]="country.code">
          {{country?.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <ng-container
    *ngIf="doctorFiltersFormGroup?.value.countryCode?.toLowerCase() === countryISO.UnitedStates.toLowerCase()">
    <h3 class="filter-label">{{'doctorProfile.licenseStateLabel' | translate}}</h3>
    <div class="input-container">
      <mat-form-field appearance="outline" class="input-container-item-large">
        <mat-label>{{'doctorProfile.licenseStateLabel' | translate}}</mat-label>
        <mat-select formControlName="state">
          <mat-option *ngFor="let state of filteredStates" [value]="state">
            {{state}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>

  <h3 class="filter-label">{{'filterDoctors.specialtyLabel' | translate}}</h3>

  <div class="input-container">
    <mat-form-field appearance="outline" class="input-container-item-large">
      <mat-label>{{'filterDoctors.specialtyLabel' | translate}}</mat-label>

      <mat-select formControlName="specialtyId">
        <mat-option *ngFor="let speciality of specialities" [value]="speciality.id">
          {{speciality?.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <button (click)="clearFilters()" class="filter-button button-primary wide-button" mat-button>
    {{'filterDoctors.clearFiltersLabel' | translate}}
  </button>

  <button (click)="applyFilters()" class="filter-button button-primary wide-button" mat-button>
    {{'filterPatients.applyFiltersLabel' | translate}}
  </button>
</div>
