import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private configUrl = 'assets/configs/';

  constructor(private readonly http: HttpClient) {
  }

  public getConfigMenu(): Observable<any> {
    return this.http.get(`${this.configUrl}menu.config.json`);
  }
}
