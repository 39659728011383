import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

import {
  AppointmentPaginationRequestDataInterface, CustomAppointmentOfferInterface,
  OfflineAppointmentInterface,
  RequestAppointmentInterface,
  RequestsPaginationInterface
} from '../../interfaces';

@Injectable({
  providedIn: 'root'
})
export class AppointmentsApiService {

  constructor(private readonly httpClient: HttpClient) {
  }

  public getAppointments(data: AppointmentPaginationRequestDataInterface): Observable<RequestsPaginationInterface<RequestAppointmentInterface>> {
    return this.httpClient.post<RequestsPaginationInterface<RequestAppointmentInterface>>(`${environment.apiAddress}/api/doctor/appointments`, data);
  }

  public getCalendarAppointments(startDate: Date, endDate: Date): Observable<RequestAppointmentInterface[]> {
    return this.httpClient.post<RequestAppointmentInterface[]>(`${environment.apiAddress}/api/doctor/calendar`, {
      from: startDate,
      to: endDate
    });
  }

  public cancelAppointment(id: string): Observable<RequestAppointmentInterface> {
    return this.httpClient.patch<RequestAppointmentInterface>(`${environment.apiAddress}/api/appointment/cancel/${id}`, {});
  }

  public finalizeAppointment(id: string): Observable<RequestAppointmentInterface> {
    return this.httpClient.patch<RequestAppointmentInterface>(`${environment.apiAddress}/api/appointment/finalize/${id}`, {});
  }

  public createOfflineAppointment(model: OfflineAppointmentInterface): Observable<RequestAppointmentInterface> {
    return this.httpClient.post<RequestAppointmentInterface>(`${environment.apiAddress}/api/Appointment/offline`, model);
  }

  public CreateCustomAppointmentOffer(data: CustomAppointmentOfferInterface): Observable<RequestAppointmentInterface> {
    return this.httpClient.post<RequestAppointmentInterface>(`${environment.apiAddress}/api/Appointment/custom-offer`, data);
  }
}
