import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { DoctorAvailabilityStatusEnum, RoleEnum } from '../../enums';
import { BadgesInterface } from '../../interfaces';


@Injectable({
  providedIn: 'root',
})
export class SideBarApiService {
  private readonly apiUrl: string = `${environment.apiAddress}/api`;

  constructor(private readonly httpClient: HttpClient) {
  }

  public getBadges(role: RoleEnum): Observable<BadgesInterface> {
    const url = role === RoleEnum.Doctor ? `${this.apiUrl}/doctor` : `${this.apiUrl}/patient`;

    return this.httpClient.get<BadgesInterface>(`${url}/badges`);
  }

  public changeAvailability(availabilityStatus: DoctorAvailabilityStatusEnum): Observable<any> {
    return this.httpClient.put<any>(`${this.apiUrl}/Doctor/availability`, {availabilityStatus});
  }
}
