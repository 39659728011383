import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PatientEhrComponent } from '../../components/patient-ehr/patient-ehr.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  templateUrl: './create-custom-appointment-offer-dialog.component.html',
  styleUrls: ['./create-custom-appointment-offer-dialog.component.scss']
})
export class CreateCustomAppointmentOfferDialogComponent {
  public options: Date[] = [];
  public customAppointmentForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<PatientEhrComponent>,
              private readonly fb: FormBuilder) {
    this.setForm();
  }

  public setProposedTimeOptions(options: Date[]): void {
    this.options = options;
  }

  public send(): void {
    if (!this.options.length || this.customAppointmentForm.invalid) {
      return;
    }
    this.dialogRef.close({scheduleOptions: this.options, ...this.customAppointmentForm.value});
  }

  private setForm(): void {
    this.customAppointmentForm = this.fb.group({
      customPrice: this.fb.control(null, [Validators.required, Validators.min(1), Validators.max(999)]),
      additionalInformation: this.fb.control(''),
    });
  }
}
