<div (click)="$event.stopPropagation(); sendPatientInfo()" class="list-item">
  <div [ngClass]="{'active': isCurrentItem}" class="list-item-wrapper">
    <div *ngIf="role === 'doctor'; else patientView" class="list-item-info-container">
      <div class="list-item-info">
        <div *ngIf="appointmentInfo?.followUpScheduledStart || appointmentInfo?.scheduledStart"
             class="patients-last-visit">
          <span class="patients-last-visit-label">{{'general.visitTimeLabel' | translate}}:</span>

          <span class="patients-last-visit-date">
            {{ (appointmentInfo?.followUpScheduledStart || appointmentInfo?.scheduledStart) | localDate:'shortDateTime' }}
          </span>
        </div>
      </div>

      <div class="user-info">
        <div class="user-basic">
          <span class="user-full-name">{{currentPatientInfo?.firstName + ' ' + currentPatientInfo?.lastName}}</span>

          <p [class.visit-type]="appointmentInfo?.visitType === 1">
            {{appointmentInfo?.visitType === 0 ? ('general.onlineLabel' | translate) : ('general.offlineLabel' | translate)}}
          </p>
        </div>

        <div class="user-location">
          <img alt="location icon" class="user-location-icon" src="assets/icons/general/location.svg">

          <span>{{ appointmentInfo?.countryName }}</span>
        </div>
      </div>
    </div>

    <button mat-icon-button>
      <mat-icon>arrow_forward</mat-icon>
    </button>
  </div>
</div>

<ng-template #patientView>
  <div class="list-item-info-container">
    <div class="list-item-info">
      <div *ngIf="appointmentInfo?.followUpScheduledStart || appointmentInfo?.scheduledStart"
           class="patients-last-visit">
        <span class="patients-last-visit-label">{{'general.visitTimeLabel' | translate}}:</span>

        <span class="patients-last-visit-date">
            {{ (appointmentInfo?.followUpScheduledStart || appointmentInfo?.scheduledStart) | localDate:'shortDateTime' }}
          </span>
      </div>
    </div>

    <div class="user-info">
      <div class="user-basic">
        <span
          class="user-full-name">{{('general.patientLabel' | translate) + ': ' + currentPatientInfo?.firstName + ' ' + currentPatientInfo?.lastName}}</span>

        <div class="d-flex">
          <div class="visit-history-status">{{Status | translate}}</div>

          <p [class.visit-type]="appointmentInfo?.visitType === 1">
            {{appointmentInfo?.visitType === 0 ? ('general.onlineLabel' | translate) : ('general.offlineLabel' | translate)}}
          </p>
        </div>
      </div>


      <div class="user-location">
        <img alt="location icon" class="user-location-icon" src="assets/icons/general/location.svg">

        <span>{{ appointmentInfo?.countryName }}</span>
      </div>
    </div>
  </div>
</ng-template>

