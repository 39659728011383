<div class="modal-wrapper">
  <div *ngIf="doctorPersonalInfo" class="overflow-auto h-100">
    <vi-clinic-doctor-profile-personal-info [isDialog]="true" [personalInfo]="doctorPersonalInfo"></vi-clinic-doctor-profile-personal-info>
  </div>

  <div class="button-container">
    <button mat-dialog-close class="transparent-button" mat-button>
      {{'general.cancelLabel' | translate}}
    </button>
  </div>
</div>
