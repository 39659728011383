<div class="footer">
  <button (click)="onAttachFileClick.emit()" *ngIf="isAttachmentsAllowed" mat-icon-button>
    <mat-icon>attach_file</mat-icon>
  </button>

  <mat-form-field appearance="outline" class="chat-input w-100">
      <textarea (focus)="isSendMessageControlFocused = true" (focusout)="isSendMessageControlFocused = false"
                (keyup.enter)="sendMessage()" [formControl]="sendMessageControl"
                [placeholder]="'messages.sendMessageLabel' | translate" matInput
                 rows="1">
      </textarea>
  </mat-form-field>

  <button (click)="sendMessage()"
          [ngClass]="{'send-message-button-active': isSendMessageControlFocused && !!sendMessageControl.value}"
          mat-icon-button>
    <mat-icon>send</mat-icon>
  </button>
</div>
