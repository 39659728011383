<div class="reviews-container h-100">
  <ng-container *ngIf="doctorReviews?.length > 0 else emptyReview">
    <div class="review-box" *ngFor="let review of doctorReviews">
      <div class="patient-name-container">
        <img class="patient-avatar" src="assets/icons/profile-settings/avatar-placeholder.svg" alt="x">
        <span class="patient-name">{{review?.patientFullName}}</span>
      </div>

      <div class="rating-container">
        <span class="review-date">{{review?.createdTimestamp | localDate:'longDateTime'}}</span>
        <vi-clinic-star-rating [rating]="review?.value"></vi-clinic-star-rating>
      </div>

      <span class="review-description">{{review?.description}}</span>
    </div>
  </ng-container>

  <ng-template #emptyReview>
    <div class="empty-reviews-container h-100">
      <h3 class="empty-title">{{'doctorProfile.emptyReviewsTitleLabel' | translate}}</h3>
      <img class="empty-doctors-image" src="assets/images/doctors.png" alt="doctors">
    </div>
  </ng-template>
</div>
