<div *ngIf="alertInfo" class="alert-details-wrapper">
  <div class="alerts-details-header">
    <button class="basic-icon-button button-transparent-red" (click)="alertId.emit(alertInfo?.id)" mat-button>
      <mat-icon>delete</mat-icon>

      {{'general.removeLabel' | translate}}
    </button>
  </div>

  <div class="alert-details-content-wrapper">
    <div class="alert-details-content">
      <h2 class="alert-details-title">{{alertInfo.title}}</h2>

      <p class="alert-details-description">{{alertInfo.message}}</p>
    </div>
  </div>
</div>
