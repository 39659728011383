import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
  name: 'centTransform'
})
export class CentTransformPipe implements PipeTransform {

  public transform(value: number, ...args: unknown[]): string {
    const integerMoney = value / 100;

    if (Number.isInteger(integerMoney)) {
      return `${integerMoney}.00`;
    } else {
      const [, coins] = integerMoney.toString().split('.');

      return coins?.length === 1 ? `${integerMoney}0` : integerMoney.toString();
    }
  }


}
