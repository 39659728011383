import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';

import {environment} from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})
export class VideoApiService {
  private readonly apiUrl: string = `${environment.apiAddress}/api`;

  constructor(private readonly httpClient: HttpClient) {
  }

  public joinWaitingRoom(appointmentId: string): Observable<any> {
    return this.httpClient.post<null>(`${this.apiUrl}/Video/waitingRoom/join/${appointmentId}`, {});
  }

  public updatePing(appointmentId: string): Observable<any> {
    return this.httpClient.patch<null>(`${this.apiUrl}/Video/waitingRoom/ping/${appointmentId}`, {});
  }

  public getInfoRoom(appointmentId: string): Observable<any> {
    return this.httpClient.get<null>(`${this.apiUrl}/Video/waitingRoom/info/${appointmentId}`);
  }

  public leaveWaitingRoom(appointmentId: string): Observable<any> {
    return this.httpClient.put<null>(`${this.apiUrl}/Video/waitingRoom/leave/${appointmentId}`, {});
  }

  public joinVideo(appointmentId: string): Observable<any> {
    return this.httpClient.post<null>(`${this.apiUrl}/Video/join/${appointmentId}`, {});
  }

  public onJoin(appointmentId: string): Observable<any> {
    return this.httpClient.put<null>(`${this.apiUrl}/Video/onJoin/${appointmentId}`, {});
  }

  public leaveVideo(appointmentId: string): Observable<any> {
    return this.httpClient.put<null>(`${this.apiUrl}/Video/leave/${appointmentId}`, {});
  }
}
