import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

import {
  AcceptFollowUpVisitInterface, AcceptVisitInterface,
  AppointmentPaginationRequestDataInterface, CreateAppointmentRequest, DoctorInterface,
  LanguageInterface,
  MedicalHistoryAvailabilityInterface,
  PriceRequestInterface,
  RequestAppointmentInterface,
  RequestsPaginationInterface,
  SpecialityInterface,
  PriceResponseInterface, PatientRequiredInfoInterface
} from '../../interfaces';


@Injectable({
  providedIn: 'root'
})
export class VisitsApiService {
  private url = `${environment.apiAddress}/api/patient`;

  constructor(private readonly httpClient: HttpClient) {
  }

  public getLanguages(): Observable<LanguageInterface[]> {
    return this.httpClient.get<LanguageInterface[]>(`${environment.apiAddress}/api/doctorsearch/languages`);
  }

  public getDoctor(doctorId: string): Observable<DoctorInterface> {
    return this.httpClient.get<DoctorInterface>(`${environment.apiAddress}/api/doctorsearch/${doctorId}`);
  }

  public getSpecialties(languageCode: string): Observable<SpecialityInterface[]> {
    return this.httpClient.get<SpecialityInterface[]>(`${environment.apiAddress}/api/DoctorSearch/specialties?languageCode=${languageCode}`);
  }

  public getVisits(model: AppointmentPaginationRequestDataInterface): Observable<RequestsPaginationInterface<RequestAppointmentInterface>> {
    return this.httpClient.post<RequestsPaginationInterface<RequestAppointmentInterface>>(`${this.url}/appointments`, model);
  }

  public getPrice(body: PriceRequestInterface): Observable<PriceResponseInterface> {
    return this.httpClient.post<PriceResponseInterface>(`${environment.apiAddress}/api/appointment/getPrice`, body);
  }

  public createAppointment(body: CreateAppointmentRequest): Observable<RequestAppointmentInterface> {
    return this.httpClient.post<RequestAppointmentInterface>(`${environment.apiAddress}/api/appointment`, body);
  }

  public checkMedicalHistory(familyMemberId: string): Observable<MedicalHistoryAvailabilityInterface> {
    const url = familyMemberId ?
      `${environment.apiAddress}/api/medicalHistory/medicalhistoryavailability?familyMemberId=${familyMemberId}` :
      `${environment.apiAddress}/api/medicalHistory/medicalhistoryavailability`;

    return this.httpClient.get<MedicalHistoryAvailabilityInterface>(url);
  }


  public followUpDecisionRequest(body: AcceptFollowUpVisitInterface): Observable<void> {
    return this.httpClient.post<void>(`${environment.apiAddress}/api/followup/makedecision/${body.appointmentId}`, body);
  }

  public cancelAppointment(id: string): Observable<void> {
    return this.httpClient.patch<void>(`${environment.apiAddress}/api/appointment/cancel/${id}`, {});
  }

  public rejectAppointment(id: string): Observable<void> {
    return this.httpClient.patch<void>(`${environment.apiAddress}/api/appointment/patientreject/${id}`, {});
  }

  public acceptAppointment(id: string, body: AcceptVisitInterface): Observable<void> {
    return this.httpClient.patch<void>(`${environment.apiAddress}/api/appointment/patientaccept/${id}`, body);
  }

  public getRequiredInfo(): Observable<PatientRequiredInfoInterface> {
    return this.httpClient.get<PatientRequiredInfoInterface>(`${this.url}/requiredInfo`);
  }
}
