import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { AuthService, VideoService } from '../services';
import { CallRoomComponent } from '../../+video/component/call-room/call-room.component';


@Injectable({
  providedIn: 'root',
})
export class VideoGuard implements CanActivate {

  constructor(private readonly videoService: VideoService,
              private authService: AuthService,
              private readonly router: Router) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.videoService.appointmentInfo$
      .pipe(
        take(1),
        map(result => !!result),
        tap(result => {
          if (!result) {
            if (this.authService.isDoctor) {
              this.router.navigate(['/appointments']).then();
            } else {
              this.router.navigate(['/visits']).then();
            }
          }
        }));
  }
}

