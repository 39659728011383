import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import * as moment from 'moment';

import { RequestAppointmentInterface, RequestStatusInterface } from '../../../core/interfaces';

import { AppointmentStatusEnum, AppointmentTypeEnum, PaymentProviderTypeEnum, RoleEnum } from '../../../core/enums';

import { RequestStatusService } from '../../../core/services';
import {LocalDatePipe} from "../../pipes/local-date.pipe";


@Component({
  selector: 'vi-clinic-request-status',
  templateUrl: './request-status.component.html',
  styleUrls: ['./request-status.component.scss']
})
export class RequestStatusComponent implements OnInit, OnChanges {
  @Input() public appointmentInfo: RequestAppointmentInterface;
  @Input() public showAppointmentStatus: boolean = true;
  @Input() public isAppointment: boolean = false;
  @Input() public role: RoleEnum;

  @Output() public joinToWaitingRoomStatus = new EventEmitter<boolean>();

  public appointmentTypeLabel: string = '';
  public appointmentStatusLabel: string = '';
  private appointmentStartTime: string;
  public appointmentTypeClass: string;
  public appointmentStatusClass: string;
  public minutesToCall: number;
  private isCertainDoctor: boolean;
  private isAvailableDoctor: boolean;
  private hasTimeOptions: boolean;
  private hasScheduleDate: boolean;
  private isFree: boolean;

  constructor(private readonly requestStatusService: RequestStatusService,
              private readonly localDatePipe: LocalDatePipe) {
  }

  public ngOnInit(): void {
    setInterval(() => {
      this.setLabels(this.role);
    }, 60000);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('appointmentInfo' in changes) {
      this.setLabels(this.role);
    }
  }

  private setAppointmentStartTime(): void {
    this.appointmentStartTime = this.appointmentInfo.followUpScheduledStart || this.appointmentInfo.scheduledStart;
  }

  private setLabels(role: RoleEnum): void {
    if (!role) {
      return;
    }
    this.setAppointmentStartTime();
    this.isCertainDoctor = this.appointmentInfo.appointmentType === AppointmentTypeEnum.CertainDoctor;
    this.isAvailableDoctor = this.appointmentInfo.appointmentType === AppointmentTypeEnum.AvailableDoctor;
    this.minutesToCall = moment(this.appointmentStartTime).diff(moment(), 'minutes');
    this.hasTimeOptions = !!this.appointmentInfo.scheduleOptions?.length;
    this.isFree = this.appointmentInfo.payment === null ||
      this.appointmentInfo.payment?.providerType === PaymentProviderTypeEnum.Free ||
      this.appointmentInfo.isFree;
    this.hasScheduleDate = !!this.appointmentInfo.followUpScheduledStart || !!this.appointmentInfo.scheduledStart;

    const callSetLabel = (arg) => role === RoleEnum.Doctor ?
      this.requestStatusService.setDoctorLabel(arg) :
      this.requestStatusService.setPatientLabel(arg);

    let result: RequestStatusInterface;

    switch (this.appointmentInfo.status) {
      case AppointmentStatusEnum.ResponsePending:
        switch (true) {
          case this.isAvailableDoctor && !this.hasTimeOptions:
            result = callSetLabel('ResponsePendingWithoutScheduleOptionsStatusStyle');
            break;
          case this.isAvailableDoctor && this.hasTimeOptions:
            result = callSetLabel('ResponsePendingWithScheduleOptionsStatusStyle');
            break;
          default:
            break;
        }
        break;

      case AppointmentStatusEnum.DoctorDecisionPending:
        switch (true) {
          case  this.isCertainDoctor:
            result = callSetLabel('CertainDoctorDecisionPendingStatusStyle');
            break;
          default:
            break;
        }
        break;
      case AppointmentStatusEnum.PatientDecisionPending:
        switch (true) {
          case this.isAvailableDoctor && this.hasScheduleDate && this.isFree:
            result = callSetLabel('isAvailableDoctorWaitPatientDecisionFreeVisitStatusStyle');
            break;
          case this.isAvailableDoctor && !this.hasScheduleDate && this.isFree:
            result = callSetLabel('isAvailableDoctorWaitPatientDecisionAlternativeTimeFreeVisitStatusStyle');
            break;
          case this.isAvailableDoctor && this.hasScheduleDate:
            result = callSetLabel('isAvailableDoctorWaitPatientDecisionPaidVisitStatusStyle');
            break;
          case this.isAvailableDoctor && !this.hasScheduleDate:
            result = callSetLabel('isAvailableDoctorWaitPatientDecisionAlternativeTimePaidVisitStatusStyle');
            break;
          case this.isCertainDoctor && this.hasScheduleDate:
            result = callSetLabel('isCertainDoctorWaitPatientDecisionPaidVisitStatusStyle');
            break;
          case this.isCertainDoctor && !this.hasScheduleDate:
            result = callSetLabel('isCertainDoctorWaitPatientDecisionAlternativeTimePaidVisitStatusStyle');
            break;
          default:
            break;
        }
        break;


      case AppointmentStatusEnum.Scheduled:
        switch (true) {
          case this.isAvailableDoctor && this.appointmentStartTime && this.minutesToCall > 60:
            result = callSetLabel('AvailableDoctorDateStartStatusStyle');
            break;
          case this.isAvailableDoctor && this.appointmentStartTime && this.minutesToCall > 10:
            result = callSetLabel('AvailableDoctorTimeToStartStatusStyle');
            break;
          case this.isAvailableDoctor && this.appointmentStartTime && this.minutesToCall >= 0:
            result = callSetLabel('AvailableDoctorEnterWaitingRoomStatusStyle');
            this.joinToWaitingRoomStatus.emit(true);
            break;
          case this.isAvailableDoctor && this.appointmentStartTime && this.minutesToCall < 0:
            result = callSetLabel('AvailableDoctorLateStatusStyle');
            this.joinToWaitingRoomStatus.emit(true);
            break;
          case this.isCertainDoctor && this.appointmentStartTime && this.minutesToCall > 60:
            result = callSetLabel('CertainDoctorDateStartStatusStyle');
            break;
          case this.isCertainDoctor && this.appointmentStartTime && this.minutesToCall > 10:
            result = callSetLabel('CertainDoctorTimeToStartStatusStyle');
            break;
          case this.isCertainDoctor && this.appointmentStartTime && this.minutesToCall >= 0:
            result = callSetLabel('CertainDoctorEnterWaitingRoomStatusStyle');
            this.joinToWaitingRoomStatus.emit(true);
            break;
          case this.isCertainDoctor && this.appointmentStartTime && this.minutesToCall < 0:
            result = callSetLabel('CertainDoctorLateStatusStyle');
            this.joinToWaitingRoomStatus.emit(true);
            break;
          default:
            break;
        }
        break;

      case AppointmentStatusEnum.FollowUpDecisionPending:
        result = callSetLabel('FollowUpDecisionPendingStatusStyle');
        break;

      case AppointmentStatusEnum.FollowUpScheduled:
        switch (true) {
          case this.appointmentStartTime && this.minutesToCall > 60:
            result = callSetLabel('DateStartStatusStyle');
            break;
          case this.appointmentStartTime && this.minutesToCall > 10:
            result = callSetLabel('TimeToStartStatusStyle');
            break;
          case this.appointmentStartTime && this.minutesToCall >= 0:
            result = callSetLabel('EnterWaitingRoomStatusStyle');
            this.joinToWaitingRoomStatus.emit(true);
            break;
          case this.appointmentStartTime && this.minutesToCall < 0:
            result = callSetLabel('LateStatusStyle');
            this.joinToWaitingRoomStatus.emit(true);
            break;
          default:
            break;
        }
        break;

      case AppointmentStatusEnum.Ongoing:
        result = callSetLabel('OngoingStatusStyle');
        this.joinToWaitingRoomStatus.emit(true);
        break;

      case AppointmentStatusEnum.VisitFinished:
      case AppointmentStatusEnum.FollowUpDeclined:
      case AppointmentStatusEnum.FollowUpFinished:
        result = callSetLabel('FinishedStyle');
        break;
      case AppointmentStatusEnum.Canceled:
        result = callSetLabel('CanceledStyle');
        break;
      case AppointmentStatusEnum.Expired:
        result = callSetLabel('ExpiredStyle');
        break;
      default:
        break;
    }

    this.appointmentTypeLabel = result?.appointmentTypeLabel;
    this.appointmentStatusLabel = result?.appointmentStatusLabel === 'time' ?
      `${this.localDatePipe.transform(moment(this.appointmentStartTime), 'shortDateTime')}` :
      result?.appointmentStatusLabel;

    this.appointmentTypeClass = result?.appointmentTypeClass;
    this.appointmentStatusClass = result?.appointmentStatusClass;
  }
}
