<div class="modal-wrapper">
  <mat-tab-group animationDuration="0ms">
    <mat-tab label="{{'managePractice.addNewColleagueLabel' | translate}}">
      <div class="content-wrapper">
        <h1 class="page-title">
          {{'managePractice.addAnExistingViClinicColleagueLabel' | translate}}
        </h1>

        <mat-form-field appearance="outline" class="input-container-item-large m-t-2 mb-2">
          <mat-label>{{'patientEHR.searchColleagueLabel' | translate}}</mat-label>

          <input [matAutocomplete]="auto"
                 [formControl]="colleagueControl"
                 matInput/>

          <button mat-icon-button matSuffix class="m-r-1" (click)="resetSearch()" *ngIf="colleagueControl.value">
            <mat-icon>close</mat-icon>
          </button>

          <mat-autocomplete [displayWith]="displayFn" #auto="matAutocomplete" autoActiveFirstOption class="no-scroll">
            <div (scroll)="onListScroll($event)" class="option-scroll">
              <mat-option
                *ngFor="let colleague of colleagues"
                [value]="colleague">
                {{colleague.fullName}}
              </mat-option>

              <mat-option *ngIf="colleagues?.length === 0" class="link-button alarm-message">
                {{'patientVisit.noRecordsLabel' | translate}}
              </mat-option>
            </div>
          </mat-autocomplete>
        </mat-form-field>

        <div class="button-container">
          <button mat-dialog-close class="transparent-button" mat-button>
            {{'general.cancelLabel' | translate}}
          </button>

          <button (click)="addFavoriteUser()" [disabled]="!colleagueId" class="button-primary" mat-button>
            {{'general.addLabel' | translate}}
          </button>
        </div>
      </div>

    </mat-tab>
    <mat-tab label="{{'patients.inviteToViClinicLabel' | translate}}">
      <div class="content-wrapper">
        <h1 class="page-title">
          {{'managePractice.inviteColleaguesLabel' | translate}}
        </h1>

        <vi-clinic-invite-user #inviteUser
                               (closeDialog)="onNoClick(false)"
                               (sendInvitationEmitter)="sendInvitation($event)">
        </vi-clinic-invite-user>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
