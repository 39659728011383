<div [ngClass]="{'vc-sidebar-closed-container': !sidebarExpanded}" class="vc-sidebar-container">
  <div class="vc-sidebar-header">
    <img *ngIf="sidebarExpanded" alt="ViClinic Doctor Portal" src="assets/images/vc-logo.svg">

    <button (click)="doToggleSidebar()" mat-icon-button>
      <mat-icon>menu</mat-icon>
    </button>
  </div>

  <div class="vc-sidebar-items">
    <div class="overflow-auto">
      <ng-container *ngFor="let menuItem of menuItems">

        <a *ngIf="menuItem?.controlName" [ngClass]="{'disabled-item': doctorStatus.Active !== status}"
           [routerLink]="menuItem?.routerLink"
           class="vc-menu-item" routerLinkActive="active">
          <mat-icon [matBadgeHidden]="badgesFormGroup.get(menuItem.controlName).value <= 0 || sidebarExpanded"
                    [matBadge]="badgesFormGroup.get(menuItem.controlName).value"
                    [svgIcon]="menuItem?.icon"
                    class="icon">
          </mat-icon>

          <p *ngIf="sidebarExpanded" class="vc-menu-item-label">{{menuItem.title | translate}}</p>

          <div *ngIf="sidebarExpanded && badgesFormGroup?.get(menuItem.controlName).value > 0"
               class="vc-menu-item-badge">
            {{badgesFormGroup?.get(menuItem.controlName).value}}
          </div>
        </a>

        <a
          *ngIf="menuItem?.icon && !menuItem?.controlName && !menuItem?.children && menuItem?.routerLink !== 'manage-practice/my-network'"
          [ngClass]="{'disabled-item': doctorStatus.Active !== status}" [routerLink]="menuItem.routerLink"
          class="vc-menu-item" routerLinkActive="active">
          <mat-icon [svgIcon]="menuItem.icon"
                    class="icon">
          </mat-icon>

          <div *ngIf="menuItem?.routerLink === 'account-settings' && !completedPatientPersonalInformation" class="red-point"></div>
          <div *ngIf="menuItem?.routerLink === 'medical-information' && !hasMedicalInformation" class="red-point"></div>

          <div [ngClass]="{'w-100': sidebarExpanded}" class="d-flex">

            <p *ngIf="sidebarExpanded" class="vc-menu-item-label">{{menuItem.title | translate}}</p>

            <mat-icon
              *ngIf="!!menuItem?.tooltip && sidebarExpanded"
              matTooltip="{{'sideBar.doctorViTooltip' | translate}}"
              class="dr-viai-support-icon"
              matTooltipClass="tooltip-ai"
              matTooltipPosition="right">
              contact_support
            </mat-icon>
          </div>
        </a>

        <a (click)="toggleManagePractice()"
           *ngIf="(bankingStatus$ | async) && menuItem?.routerLink === 'manage-practice'"
           [ngClass]="{'disabled-item': doctorStatus.Active !== status}"
           [routerLink]="[menuItem.routerLink]" class="vc-menu-item"
           routerLinkActive="active">
          <mat-icon [svgIcon]="menuItem.icon" class="icon"></mat-icon>

          <p *ngIf="sidebarExpanded" class="vc-menu-item-label">{{menuItem.title | translate}}</p>

          <div *ngIf="sidebarExpanded">
            <img *ngIf="!openManagePracticeItem" alt="arrow" src="assets/icons/general/arrow-top-icon.svg">
            <img *ngIf="openManagePracticeItem" alt="arrow" src="assets/icons/general/arrow-bottom-icon.svg">
          </div>
        </a>

        <div
          *ngIf="sidebarExpanded && openManagePracticeItem && (bankingStatus$ | async) && menuItem?.routerLink === 'manage-practice'"
          class="vc-menu-item-element-wrapper">
          <a *ngFor="let item of menuItem?.children"
             [ngClass]="{'disabled-item': doctorStatus.Active !== status}"
             [routerLink]="item.routerLink"
             class="vc-menu-item-element" routerLinkActive="active">
            <p>{{item.title | translate}}</p>
          </a>

        </div>

        <a *ngIf="!(bankingStatus$ | async) && menuItem?.routerLink === 'manage-practice/my-network'"
           [ngClass]="{'disabled-item': doctorStatus.Active !== status}"
           [routerLink]="menuItem.routerLink" class="vc-menu-item"
           routerLinkActive="active">
          <mat-icon [svgIcon]="menuItem.icon" class="icon"></mat-icon>

          <p *ngIf="sidebarExpanded" class="vc-menu-item-label">{{menuItem.title | translate}}</p>
        </a>

        <a (click)="toggleManageProfileSettings()" *ngIf="menuItem?.routerLink === 'profile-settings'"
           [ngClass]="{'disabled-item': !isProfileSettingsAvailable}"
           [routerLink]="menuItem.routerLink" class="vc-menu-item"
           routerLinkActive="active">
          <mat-icon [svgIcon]="menuItem.icon" class="icon"></mat-icon>

          <p *ngIf="sidebarExpanded" class="vc-menu-item-label">{{menuItem.title | translate}}</p>

          <div *ngIf="sidebarExpanded">
            <mat-icon>{{openManageProfileSettingsItem ? 'expand_less' : 'expand_more'}}</mat-icon>
          </div>
        </a>

        <div *ngIf="sidebarExpanded && openManageProfileSettingsItem && menuItem?.routerLink === 'profile-settings'"
             [ngClass]="{'disabled-item': !isProfileSettingsAvailable}" class="vc-menu-item-element-wrapper">
          <ng-container *ngFor="let item of menuItem.children">
            <div *ngIf="!(item.scrollElement === 'bankingInformation' && !(completedPersonalInformation$ | async))"
                 (click)="scrollToElement(item.scrollElement)"
                 [ngClass]="{'active-profile-settings-item': currentProfileSettingsSection === item.scrollElement && checkProfileSettingsUrl}"
                 class="vc-menu-item-element">
              <p>{{item.title | translate}}</p>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>

    <div>
      <div *ngIf="sidebarExpanded" class="vc-menu-item logout-wrapper">
        <mat-slide-toggle *ngIf="doctorStatus.Active == status && currentUserRole === userRole.Doctor"
                          [formControl]="isAvailable" [labelPosition]="'before'">
          <span class="slide-toggle-label">
             {{isAvailable.value ? ('sideBar.availableLabel' | translate) : ('sideBar.notAvailableLabel' | translate)}}
          </span>
        </mat-slide-toggle>

        <button class="button-red" (click)="logout()" [disabled]="isLogoutProcessing"
                mat-raised-button>
          {{'general.logoutLabel' | translate}}
        </button>
      </div>

      <div *ngIf="!sidebarExpanded" class="vc-menu-item">
        <button (click)="logout()" [disabled]="isLogoutProcessing">
          <mat-icon [svgIcon]="'log-out-icon'"
                    class="icon">
          </mat-icon>
        </button>
      </div>

      <a [routerLink]="['support']" class="vc-menu-item"
         routerLinkActive="active">
        <mat-icon [svgIcon]="'question-mark'"
                  class="icon">
        </mat-icon>

        <p *ngIf="sidebarExpanded" class="vc-menu-item-label">{{'sideBar.helpSupportLabel' | translate}}</p>
      </a>

      <p *ngIf="sidebarExpanded" class="vc-copyright">© Copyright ViClinic LLC</p>
    </div>
  </div>
</div>
