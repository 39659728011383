<div class="payments-wrapper">
  <ng-container [ngSwitch]="mode">
    <ng-container *ngSwitchCase="modeEnum.cardList">
      <div class="cards-wrapper">
        <div *ngFor="let card of cards" class="card-wrapper">
          <div (click)="selectCard(card)"
               [ngClass]="{'selected-card': selectedCard?.id === card.id, 'expired-card': card.isExpired}"
               class="card">
            <div class="w-100">
              <p  matTooltip="{{card.name}}"
                  matTooltipClass="tooltip" class="card-nickname">{{card.name}}</p>
              <div class="card-number">
                <img [src]="card.brand === 'Visa' ? 'assets/images/visa.svg' : 'assets/images/mastercard.svg'"
                     alt="">
                <p> <span class="card-asterisks">****</span> {{card.lastFour}}</p>
              </div>
              <p class="card-date">{{card.expirationMonth}}/{{card.shortExpirationYear}}</p>
            </div>
            <div *ngIf="!card.isExpired; else isExpired" class="green-radio-button">
              <mat-radio-button disableRipple *ngIf="isSelectedVisit" (change)="selectCard(card)" [checked]="selectedCard?.id === card.id"></mat-radio-button>
            </div>

            <ng-template #isExpired>
              <span class="expired-message">{{'payment.expiredLabel' | translate}}</span>
            </ng-template>
          </div>

          <button *ngIf="!isSelectedVisit" (click)="openConfirmDialog(card.id)" class="delete-icon" mat-icon-button>
            <mat-icon [svgIcon]="'delete'"></mat-icon>
          </button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="modeEnum.addNew">
      <div class="add-card-wrapper" [formGroup]="creditCardForm">
        <mat-label class="payment-label">{{'payment.nameOfCardLabel' | translate}}</mat-label>

        <input [placeholder]="'payment.nameOfCardLabel' | translate" class="payment-input" name="name"
               formControlName="name" type="text"/>

        <div
          ngxStripeCardGroup [elementsOptions]="elementsOptions"
        >
          <mat-label class="payment-label">{{'payment.cardNumberLabel' | translate}}</mat-label>

          <div class="payment-input">
            <ngx-stripe-card-number [options]="cardOptions"></ngx-stripe-card-number>
          </div>

          <div class="d-flex justify-content-space-between">
            <div class="card-expiry">
              <mat-label class="payment-label">{{'payment.expirationDateLabel' | translate}}</mat-label>

              <div class="payment-input">
                <ngx-stripe-card-expiry [options]="cardOptions"></ngx-stripe-card-expiry>
              </div>
            </div>

            <div class="card-cvv">
              <mat-label class="payment-label">{{'payment.cVVLabel' | translate}}</mat-label>

              <div class="payment-input">
                <ngx-stripe-card-cvc [options]="cardOptions"></ngx-stripe-card-cvc>
              </div>
            </div>
          </div>
        </div>

        <div class="green-checkbox">
          <mat-checkbox formControlName="saveDetails"><span
            class="checkbox-label">{{'payment.saveCardDetailsLabel' | translate}}</span></mat-checkbox>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="mode === modeEnum.cardList; else addCard">
      <div class="d-flex justify-content-center">
        <button class="basic-icon-button" mat-button (click)="changeMode(modeEnum.addNew)">
          <mat-icon [svgIcon]="'plus'"
                    class="icon">
          </mat-icon>
          {{'payment.addNewCardLabel' | translate}}
        </button>
      </div>
    </ng-container>

    <ng-template #addCard>
      <button [disabled]="isLoading" (click)="createToken()" class="button-green big-button"
              mat-button>{{'payment.addNewCardLabel' | translate}}</button>
    </ng-template>
  </ng-container>
</div>
