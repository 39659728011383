import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { LanguageTypeEnum, TimeModeEnum } from '../../enums';
import {
  BankingInfoInterface,
  BiographyInterface,
  BoardCertificationsInterface,
  DoctorProfileInterface,
  EducationExperienceInterface,
  LanguageInterface,
  LicenseInterface,
  LicenseSuspensionHistoryInterface,
  PersonalInfoInterface,
  PriceDataInterface,
  PriceListInterface,
  SpecialityInterface,
  SpecialtyUpdateInterface,
  WorkExperienceInterface
} from '../../interfaces';
import { ResultMessageInterface } from '../../interfaces/patients/result-message.interface';
import { DoctorActivationInterface } from '../../interfaces/profile-settings/doctor-activation.interface';

import { AuthService } from '../auth.service';


@Injectable({
  providedIn: 'root'
})
export class ProfileSettingsApiService {
  public doctorBio$: BehaviorSubject<BiographyInterface> = new BehaviorSubject(null);
  public languages$: BehaviorSubject<LanguageInterface[]> = new BehaviorSubject([]);
  public completedPersonalInformation$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public completedMedicalLicense$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public completedWorkExperience$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public completedSpeciality$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public completedEducation$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public completedLicenseSuspensionHistory$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public completedBoardCertification$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public bankingStatus$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public activatedInfoSubject$: Subject<void> = new Subject<void>();
  public updateLanguages$: Subject<void> = new Subject<void>();

  private readonly doctorUrl: string = `${environment.apiAddress}/api/doctor/`;

  constructor(private readonly httpClient: HttpClient,
              private readonly authService: AuthService) {
  }

  public getSpecialities(): Observable<SpecialityInterface[]> {
    return this.httpClient.get<SpecialityInterface[]>(`${environment.apiAddress}/api/specialty`);
  }

  public getCurrentDoctorData(): Observable<PersonalInfoInterface> {
    return this.httpClient.get<PersonalInfoInterface>(`${this.doctorUrl}ensure-created`)
      .pipe(tap((doctor) => {
        this.bankingStatus$.next(doctor.bankAccountType !== 1);
        this.doctorBio$.next({doctorId: doctor.id, biography: doctor.biography});
        this.languages$.next(doctor.languages);
        this.authService.status$.next(doctor.status);
      }));
  }

  public getDoctorProfile(): Observable<DoctorProfileInterface> {
    return this.httpClient.get<DoctorProfileInterface>(`${this.doctorUrl}profile`);
  }

  public getPriceList(): Observable<PriceListInterface> {
    return this.httpClient.get<PriceListInterface>(`${this.doctorUrl}price-configuration`);
  }

  public getBankingInfo(): Observable<BankingInfoInterface> {
    return this.httpClient.get<BankingInfoInterface>(`${environment.apiAddress}/api/doctorbilling`);
  }

  public saveAvatar(file: FormData): Observable<ResultMessageInterface> {
    return this.httpClient.post<ResultMessageInterface>(`${environment.apiAddress}/api/commonuser/avatar`, file);
  }

  public saveTimeFormat(timeMode: TimeModeEnum): Observable<void> {
    return this.httpClient.patch<void>(`${environment.apiAddress}/api/commonuser/timemode`, {timeMode});
  }

  public saveTimeZone(timeZoneId: string): Observable<void> {
    return this.httpClient.patch<void>(`${environment.apiAddress}/api/commonuser/timezone`, {timeZoneId});
  }

  public saveLanguage(language: LanguageTypeEnum): Observable<void> {
    return this.httpClient.put<void>(`${environment.apiAddress}/api/commonuser/language
`, {language});
  }

  public updateDoctorProfile(data: PersonalInfoInterface): Observable<null> {
    return this.httpClient.put<null>(this.doctorUrl, data);
  }

  public updateDoctorSpecialty(data: SpecialtyUpdateInterface): Observable<null> {
    return this.httpClient.put<null>(`${this.doctorUrl}specialty`, data);
  }

  public saveWorkExperience(data: WorkExperienceInterface): Observable<WorkExperienceInterface> {
    return this.httpClient.post<WorkExperienceInterface>(`${environment.apiAddress}/api/workexperience`, data);
  }

  public updateWorkExperience(data: WorkExperienceInterface, id: string): Observable<WorkExperienceInterface> {
    return this.httpClient.put<WorkExperienceInterface>(`${environment.apiAddress}/api/workexperience/${id}`, data);
  }

  public deleteWorkExperience(id: string): Observable<null> {
    return this.httpClient.delete<null>(`${environment.apiAddress}/api/workexperience/${id}`);
  }

  public saveEducationExperience(data: EducationExperienceInterface): Observable<EducationExperienceInterface> {
    return this.httpClient.post<EducationExperienceInterface>(`${environment.apiAddress}/api/education`, data);
  }

  public updateEducationExperience(data: EducationExperienceInterface, id: string): Observable<EducationExperienceInterface> {
    return this.httpClient.put<EducationExperienceInterface>(`${environment.apiAddress}/api/education/${id}`, data);
  }

  public deleteEducationExperience(id: string): Observable<null> {
    return this.httpClient.delete<null>(`${environment.apiAddress}/api/education/${id}`);
  }

  public saveLicenseSuspensionHistory(data: LicenseSuspensionHistoryInterface): Observable<void> {
    return this.httpClient.post<void>(`${environment.apiAddress}/api/MedicalLicense/licenseSuspensionHistory`, data);
  }

  public updateLicenseSuspensionHistory(data: LicenseSuspensionHistoryInterface): Observable<void> {
    return this.httpClient.put<void>(`${environment.apiAddress}/api/MedicalLicense/licenseSuspensionHistory`, data);
  }

  public saveBoardCertifications(data: BoardCertificationsInterface): Observable<void> {
    return this.httpClient.post<void>(`${environment.apiAddress}/api/MedicalLicense/boardCertification`, data);
  }

  public updateBoardCertifications(data: BoardCertificationsInterface): Observable<void> {
    return this.httpClient.put<void>(`${environment.apiAddress}/api/MedicalLicense/boardCertification`, data);
  }

  public saveLicenseExperience(data: FormData): Observable<LicenseInterface> {
    return this.httpClient.post<LicenseInterface>(`${environment.apiAddress}/api/medicallicense`, data);
  }

  public updateLicenseExperience(data: FormData, id: string): Observable<LicenseInterface> {
    return this.httpClient.put<LicenseInterface>(`${environment.apiAddress}/api/medicallicense/${id}`, data);
  }

  public deleteLicenseExperience(id: string): Observable<null> {
    return this.httpClient.delete<null>(`${environment.apiAddress}/api/medicallicense/${id}`);
  }

  public removeLicenseFile(fileId: string, licenseId: string): Observable<null> {
    return this.httpClient.post<null>(`${environment.apiAddress}/api/medicallicense/${licenseId}/deleteattachment`, {fileId});
  }

  public updateBankingInfo(data: BankingInfoInterface): Observable<null> {
    return this.httpClient.post<null>(`${environment.apiAddress}/api/doctorbilling`, data);
  }

  public updatePriceInfo(data: PriceDataInterface): Observable<null> {
    return this.httpClient.post<null>(`${this.doctorUrl}/price-configuration`, data);
  }

  public updateSpokenLanguages(data: { languageCodes: string[] }): Observable<null> {
    return this.httpClient.put<null>(`${this.doctorUrl}spoken-languages`, data);
  }

  public requestApproval(): Observable<null> {
    return this.httpClient.put<null>(`${this.doctorUrl}/requestapproval`, {});
  }

  public getDoctorActivationRequiredInfo(): Observable<DoctorActivationInterface> {
    return this.httpClient.get<DoctorActivationInterface>(`${this.doctorUrl}activationRequiredInfo`)
      .pipe(tap(result => {
        this.completedPersonalInformation$.next(result.completedPersonalInformation);
        this.completedMedicalLicense$.next(result.hasAtLeastOneMedicalLicense);
        this.completedWorkExperience$.next(result.hasAtLeastOneWorkExperience);
        this.completedSpeciality$.next(result.hasSpecialty);
        this.completedEducation$.next(result.hasAtLeastOneEducation);
        this.completedLicenseSuspensionHistory$.next(result.hasLicenseSuspensionHistory);
        this.completedBoardCertification$.next(result.hasBoardCertification);
      }));
  }

  public updateBio(model: any): Observable<string> {
    return this.httpClient.put<any>(`${this.doctorUrl}biography`, model);
  }

  public deleteDoctor(): Observable<void> {
    return this.httpClient.delete<void>(`${this.doctorUrl}`);
  }
}
