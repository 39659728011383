import {Component, Input} from '@angular/core';

import {ParticipantsInterface} from '../../../core/interfaces';

import {ApplicationRoleEnum} from '../../../core/enums';


@Component({
  selector: 'vi-clinic-video-card',
  templateUrl: './video-card.component.html',
  styleUrls: ['./video-card.component.scss']
})
export class VideoCardComponent {
  @Input() public participant: ParticipantsInterface;

  public role = ApplicationRoleEnum;
}
