import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { DoctorProfilePageRightPartEnum } from '../../../core/enums/doctor-profile-page-right-part.enum';
import {
  DoctorInterface,
  DoctorProfileSettingsInterface,
  DoctorReviewsInterface,
  FavoriteDoctorInterface,
  FavoriteEventInterface
} from '../../../core/interfaces';
import { User } from '../../../core/models/user.model';
import {
  AuthService,
  DoctorApiService,
  DoctorService,
  MessageApiService,
  NotificationService
} from '../../../core/services';

@Component({
  selector: 'vi-clinic-doctor-profile',
  templateUrl: './doctor-profile.component.html',
  styleUrls: ['./doctor-profile.component.scss']
})
export class DoctorProfileComponent implements OnInit, OnDestroy {

  @Output() public favoriteDoctorMarked: EventEmitter<FavoriteEventInterface> = new EventEmitter<FavoriteEventInterface>();
  public doctorInfo: DoctorInterface | FavoriteDoctorInterface;
  public personalInfo: DoctorProfileSettingsInterface;
  public doctorReviews: DoctorReviewsInterface[] = [];
  public selectedTab: 0 | 1;
  public doctorProfilePageRightPartEnum = DoctorProfilePageRightPartEnum;
  public selectedDoctorProfilePageRightPart: number = this.doctorProfilePageRightPartEnum.Profile;
  private user: User;
  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private readonly doctorService: DoctorService,
    private readonly authService: AuthService,
    private readonly doctorApiService: DoctorApiService,
    private readonly notificationService: NotificationService,
    private readonly messageApiService: MessageApiService,
    private readonly router: Router
  ) {
    this.getCurrentUserInfo();
  }

  public ngOnInit(): void {
    this.subscribeToDoctorPersonalInfo();
  }

  private getCurrentUserInfo(): void {
    this.authService.currentUser$
      .pipe(tap(user => this.user = user))
      .pipe(takeUntil(this.unsubscribe$)).subscribe();
  }

  private subscribeToDoctorPersonalInfo(): void {
    this.doctorService.getDoctorInfo()
      .pipe(filter((doctor) => !!doctor),
        takeUntil(this.unsubscribe$))
      .subscribe(doctor => {
        this.doctorInfo = doctor;
        this.personalInfo = null;
        if (!doctor.isVirtualAssistant) {
          this.getCurrentDoctor(doctor.id);
        }
      });
  }

  private getCurrentDoctor(id: string): void {
    this.doctorApiService.getDoctor(id)
      .pipe(
        takeUntil(this.unsubscribe$))
      .subscribe(doctorInfo => {
        this.doctorReviews = [];
        this.personalInfo = doctorInfo;
        this.personalInfo.cost /= 100;
        this.personalInfo.isFavorite = !!doctorInfo.relationId;
        this.getReviews();

        this.selectedDoctorProfilePageRightPart = this.doctorProfilePageRightPartEnum.Profile;
      }, (error) => this.notificationService.showErrorNotification(error?.error?.detail));
  }

  private getReviews(): void {
    if (this.selectedTab === 1 && !this.doctorReviews.length) {
      this.doctorApiService.getDoctorReviews(this.personalInfo.id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(reviews => {
          this.doctorReviews = reviews.items;
        });
    }
  }

  public setChatId(): void {
    this.messageApiService.getChatByPartnerId(this.personalInfo.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (chatHistory) => {
          this.router.navigate(['doctors'], {queryParams: {chatId: chatHistory.id}});
        },
        () => {
          this.router.navigate(['doctors'], {queryParams: {doctorId: this.personalInfo.id}});
        }
      );
    this.selectRightPage(this.doctorProfilePageRightPartEnum.Message);
  }

  public selectRightPage(selectPage: number): void {
    this.selectedDoctorProfilePageRightPart = selectPage;
  }

  public markAsFavorite(): void {
    this.personalInfo.isFavorite = !this.personalInfo.isFavorite;
    let obs$: Observable<void>;
    if (!this.personalInfo.isFavorite) {
      obs$ = this.doctorApiService.removeDoctorAsFavorite(this.personalInfo.id);
    } else {
      obs$ = this.doctorApiService.markDoctorAsFavorite(this.personalInfo.id, this.user.id);
    }
    obs$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.favoriteDoctorMarked.emit({id: this.personalInfo.id, isFavorite: this.personalInfo.isFavorite});
    });
  }

  public tabChanged(ev: MatTabChangeEvent): void {
    this.selectedTab = (ev.index as 0 | 1);
    this.getReviews();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
