<div [formGroup]="proposeOptionsForm" class="options-container">
  <p class="label">{{'requests.firstTimeOptionLabel' | translate}}</p>

  <div [formGroup]="firstTimeOption" class="options-item">
    <div class="input-container-item">
      <mat-form-field appearance="outline" class="input-container-item-datepicker" floatLabel="always">
        <mat-label>{{'general.setDateLabel' | translate}}</mat-label>

        <input [matDatepicker]="firstDate" [max]="lastDate" [min]="newDate"
               [placeholder]="'general.datePlaceholderLabel' | translate"
               (click)="firstDate.open()"
               autocomplete="off" formControlName="date"
               matInput/>

        <vi-clinic-control-validation-message [control]="firstTimeOption.controls.time">
        </vi-clinic-control-validation-message>
      </mat-form-field>

      <mat-datepicker-toggle [for]="firstDate" matSuffix></mat-datepicker-toggle>

      <mat-datepicker #firstDate></mat-datepicker>
    </div>

    <div class="input-time-picker">
      <mat-form-field appearance="outline" class="input-container-item-datepicker" floatLabel="always">
        <mat-label>{{'general.setTimeLabel' | translate}}</mat-label>

        <input formControlName="time" [ngxTimepicker]="firstOptionPicker" [format]="timeFormat"  matInput readonly
               type="text">
        <ngx-material-timepicker [minutesGap]="15" #firstOptionPicker></ngx-material-timepicker>
      </mat-form-field>
    </div>

    <button mat-icon-button (click)="resetTimeOptionGroup(firstTimeOption)"
            *ngIf="proposeOptionsForm.controls.firstTimeOption.value.date
            || proposeOptionsForm.controls.firstTimeOption.value.time">
      <mat-icon>restart_alt</mat-icon>
    </button>
  </div>

  <p class="label">{{'requests.secondTimeOptionLabel' | translate}}</p>

  <div [formGroup]="secondTimeOption" class="options-item">
    <div class="input-container-item">
      <mat-form-field appearance="outline" class="input-container-item-datepicker" floatLabel="always">
        <mat-label>{{'general.setDateLabel' | translate}}</mat-label>

        <input [matDatepicker]="secondDate" [max]="lastDate" [min]="newDate"
               [placeholder]="'general.datePlaceholderLabel' | translate"
               autocomplete="off" formControlName="date"
               (click)="secondDate.open()"
               matInput/>

        <vi-clinic-control-validation-message [control]="secondTimeOption.controls.time">
        </vi-clinic-control-validation-message>
      </mat-form-field>

      <mat-datepicker-toggle [for]="secondDate" matSuffix></mat-datepicker-toggle>

      <mat-datepicker #secondDate></mat-datepicker>
    </div>

    <div class="input-time-picker">
      <mat-form-field appearance="outline" class="input-container-item-datepicker" floatLabel="always">
        <mat-label>{{'general.setTimeLabel' | translate}}</mat-label>

        <input formControlName="time" [ngxTimepicker]="secondOptionPicker" [format]="timeFormat"  matInput readonly
               type="text">
        <ngx-material-timepicker [minutesGap]="15" #secondOptionPicker></ngx-material-timepicker>
      </mat-form-field>
    </div>

    <button mat-icon-button (click)="resetTimeOptionGroup(secondTimeOption)"
            *ngIf="proposeOptionsForm.controls.secondTimeOption.value.date
            || proposeOptionsForm.controls.secondTimeOption.value.time"
            >
      <mat-icon>restart_alt</mat-icon>
    </button>
  </div>

  <p class="label">{{'requests.thirdTimeOptionLabel' | translate}}</p>

  <div [formGroup]="thirdTimeOption" class="options-item">
    <div class="input-container-item">
      <mat-form-field appearance="outline" class="input-container-item-datepicker" floatLabel="always">
        <mat-label>{{'general.setDateLabel' | translate}}</mat-label>

        <input [matDatepicker]="thirdDate" [max]="lastDate" [min]="newDate"
               [placeholder]="'general.datePlaceholderLabel' | translate"
               autocomplete="off" formControlName="date"
                (click)="thirdDate.open()"
               matInput/>

        <vi-clinic-control-validation-message [control]="thirdTimeOption.controls.time">
        </vi-clinic-control-validation-message>
      </mat-form-field>

      <mat-datepicker-toggle [for]="thirdDate" matSuffix></mat-datepicker-toggle>

      <mat-datepicker #thirdDate></mat-datepicker>
    </div>

    <div class="input-time-picker">
      <mat-form-field appearance="outline" class="input-container-item-datepicker" floatLabel="always">
        <mat-label>{{'general.setTimeLabel' | translate}}</mat-label>

        <input formControlName="time" [ngxTimepicker]="thirdOptionPicker"  [format]="timeFormat"  matInput readonly
               type="text">
        <ngx-material-timepicker [minutesGap]="15" #thirdOptionPicker></ngx-material-timepicker>
      </mat-form-field>
    </div>

    <button mat-icon-button (click)="resetTimeOptionGroup(thirdTimeOption)"
            *ngIf="proposeOptionsForm.controls.thirdTimeOption.value.date
            || proposeOptionsForm.controls.thirdTimeOption.value.time"
            >
      <mat-icon>restart_alt</mat-icon>
    </button>
  </div>
</div>
