import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import {
  PrescriptionInterface,
  RecommendationInterface,
  RequestAppointmentInterface,
} from '../../../core/interfaces';
import {
  AppointmentsApiService, InvitationsApiService,
  MyNetworkApiService,
  NotificationService,
  PatientApiService
} from '../../../core/services';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { forkJoin, Subject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { PatientSearchInterface } from '../../../core/interfaces/patients/patient-search.interface';
import { Router } from '@angular/router';
import {
  OfflineEhrPersonalMedicalHistoryDialogComponent
} from '../offline-ehr-personal-medical-history-dialog/offline-ehr-personal-medical-history-dialog.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';


@Component({
  selector: 'vi-clinic-offline-visit-dialog',
  templateUrl: './offline-visit-dialog.component.html',
  styleUrls: ['./offline-visit-dialog.component.scss']
})
export class OfflineVisitDialogComponent implements OnInit, OnDestroy {
  public offlineVisit: FormGroup;
  public minSinceDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 6);
  public currentDate = new Date();
  public patients: PatientSearchInterface[] = [];
  public prescriptionData: { prescription?: { appointmentId: string }, isEdit: boolean };
  public currentPatient: PatientSearchInterface;
  public recommendation: RecommendationInterface;

  private patientsPage: number = 0;
  private totalPatient: number = 0;
  private reachedListBottom$: Subject<void> = new Subject();
  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private readonly notificationService: NotificationService,
              private readonly dialog: MatDialog,
              private readonly appointmentsApiService: AppointmentsApiService,
              private readonly invitationsApiService: InvitationsApiService,
              private readonly fb: FormBuilder,
              private readonly router: Router,
              private readonly patientApiService: PatientApiService,
              private readonly matDialog: MatDialog,
              private readonly dialogRef: MatDialogRef<OfflineVisitDialogComponent>,
              private readonly myNetworkApiService: MyNetworkApiService,
              @Inject(MAT_DIALOG_DATA) private readonly data: { appointments: RequestAppointmentInterface[] }) {
  }

  @HostListener('window:beforeunload', ['$event'])
  public unloadHandler(event: BeforeUnloadEvent): void {
    event.preventDefault();
    event.returnValue = '';

    return this.openConfirmDialog();
  }

  public ngOnInit(): void {
    this.setForm();
    this.subscribeScrollBottom();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public onListScroll($event): void {
    const offsetBottom = $event.target.scrollHeight - $event.target.offsetHeight - $event.target.scrollTop;

    if (offsetBottom < 100 && this.totalPatient > this.patients.length) {
      this.reachedListBottom$.next();
    }
  }

  public openEHRDialog(): void {
    this.dialog.open(OfflineEhrPersonalMedicalHistoryDialogComponent, {
      data: {
        patientId: this.currentPatient.patientId,
        familyMemberId: this.currentPatient?.familyMemberId,
        readonly: true
      },
      disableClose: true
    });
  }

  public displayFn(user: PatientSearchInterface): string {
    return user && user.fullName ? user.fullName : '';
  }

  public firstStep(stepper: MatStepper): void {
    const request = {
      patientId: this.currentPatient.patientId,
      viCode: this.offlineVisit.controls.viCode.value,
      familyMemberId: this.currentPatient.familyMemberId ? this.currentPatient.familyMemberId : null
    };

    this.invitationsApiService.validateInvitation(request)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(res => {
        if (res.isValid) {
          stepper.next();
        } else {
          this.notificationService.showErrorNotification(null, 'invitations.invalidViCodeLabel');
        }
      }, (error) => this.notificationService.showErrorNotification(error?.error?.detail));
  }

  public secondStep(event: RecommendationInterface, stepper: MatStepper): void {
    stepper.next();
    this.recommendation = event;
    this.prescriptionData = {prescription: {appointmentId: ''}, isEdit: false};
  }

  public thirdStep(data: PrescriptionInterface | boolean): void {
    const visit = {
      patientId: this.currentPatient.patientId,
      viCode: this.offlineVisit.controls.viCode.value,
      familyMemberId: this.currentPatient.familyMemberId ? this.currentPatient.familyMemberId : null
    };
    this.appointmentsApiService.createOfflineAppointment(visit).pipe(switchMap(appointment => {
      if (typeof data === 'boolean') {

        return this.patientApiService.editRecommendation(appointment?.id, this.recommendation);
      } else {
        data.appointmentId = appointment.id;

        return forkJoin([this.patientApiService.createPrescription(appointment?.id, data),
          this.patientApiService.editRecommendation(appointment?.id, this.recommendation)]);
      }
    }), takeUntil(this.unsubscribe$))
      .subscribe(
        () => {
          this.router.navigate(['visit-history']).then(() => this.dialogRef.close(true));
          this.notificationService.showSuccessNotification();
        },
        (error) => this.notificationService.showErrorNotification(error?.error?.detail));
  }

  public resetSearch(): void {
    this.offlineVisit.controls.patient.reset();
    this.patients = [];
    this.currentPatient = null
    this.patientsPage = 0;
  }

  public closeDialog(): void {
    if (this.offlineVisit.valid || !!this.recommendation) {
      this.openConfirmDialog();
    } else {
      this.dialogRef.close();
    }
  }

  public goBack(stepper: MatStepper): void {
    stepper.previous();
  }

  private getPatients(search: string, onScroll = false): void {
    if (!!search && search?.length > 1) {
      this.myNetworkApiService.patientSearch(0, 20, search)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (patients) => {
            if (onScroll) {
              this.patients.push(...patients.items);
              this.patientsPage++;
            } else {
              this.patients = patients.items;
              this.patientsPage = 1;
            }
            this.totalPatient = patients.total;
            this.patients.forEach(element => element.fullName = element.firstName + ' ' + element.lastName);
          },
          (error) => this.notificationService.showErrorNotification(error?.error?.detail)
        );
    }
  }

  private setForm(): void {
    this.offlineVisit = this.fb.group({
      patient: this.fb.control<string | PatientSearchInterface>('' || '', Validators.required),
      viCode: this.fb.control<string>('', Validators.required)
    });

    this.offlineVisit.controls.patient.valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        if (typeof value === 'string' || value === null) {
          this.patientsPage = 0;
          if (value) {
            this.getPatients(value as string);
          }
          this.currentPatient = null;
        } else {
          this.currentPatient = value;
        }
      });
  }

  private subscribeScrollBottom(): void {
    this.reachedListBottom$
      .pipe(
        debounceTime(100),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => {
          this.getPatients(this.offlineVisit.controls.patient.value as string, true);
        }
      );
  }

  private openConfirmDialog(): void {
    const confirmDialog = this.dialog.open(ConfirmationDialogComponent, {
      data: {title: 'notification.anSavedDataLabel'},
      disableClose: true
    });
    confirmDialog.afterClosed().pipe(takeUntil(this.unsubscribe$)).subscribe(value => value ? this.dialogRef.close() : '');
  }
}
