import {StrengthTypeEnum} from '../enums';

export const strengthTypeListConstant = [
  {
    'code': StrengthTypeEnum.Mg,
    'name': 'patientEHR.strength.mgLabel',
  },
  {
    'code': StrengthTypeEnum.Ml,
    'name': 'patientEHR.strength.mlLabel',
  },
  {
    'code': StrengthTypeEnum.G,
    'name': 'patientEHR.strength.gLabel',
  },
  {
    'code': StrengthTypeEnum.MgMl,
    'name': 'patientEHR.strength.mgMlLabel',
  },
  {
    'code': StrengthTypeEnum.Mcg,
    'name': 'patientEHR.strength.mcgLabel',
  },
  {
    'code': StrengthTypeEnum.Iu,
    'name': 'patientEHR.strength.iuLabel',
  }
];
