import {Injectable} from '@angular/core';

import {CalendarEvent, CalendarEventTitleFormatter} from 'angular-calendar';


@Injectable()
export class CustomEventTitleFormatter extends CalendarEventTitleFormatter {
  public disableTooltips = true;

  public weekTooltip(event: CalendarEvent, title: string): string {
    if (!this.disableTooltips) {
      return super.weekTooltip(event, title);
    }

    return;
  }
}
