import {UsageFormEnum} from '../enums';

export const usageFormListConstant = [
  {
    'code': UsageFormEnum.Caps,
    'name': 'patientEHR.usageForm.capsLabel',
  },
  {
    'code': UsageFormEnum.Tabs,
    'name': 'patientEHR.usageForm.tabsLabel',
  },
  {
    'code': UsageFormEnum.Vial,
    'name': 'patientEHR.usageForm.vialLabel',
  },
  {
    'code': UsageFormEnum.Amp,
    'name': 'patientEHR.usageForm.ampLabel',
  },
  {
    'code': UsageFormEnum.Drops,
    'name': 'patientEHR.usageForm.dropsLabel',
  },
  {
    'code': UsageFormEnum.Ml,
    'name': 'patientEHR.usageForm.mlLabel',
  },
  {
    'code': UsageFormEnum.Mg,
    'name': 'patientEHR.usageForm.mgLabel',
  },
  {
    'code': UsageFormEnum.Spray,
    'name': 'patientEHR.usageForm.sprayLabel',
  }
];
