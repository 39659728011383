import { Component } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'vi-clinic-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss']
})
export class MobileHeaderComponent {
  public systemOs: string;
  public display: boolean;

  constructor(private deviceService: DeviceDetectorService) {
    const mobileHeaderSession = localStorage.getItem('mobileHeader');
    if (mobileHeaderSession === null) {
      this._displayMobileHeader();
      localStorage.setItem('mobileHeader', 'true');
    }
  }

  private _displayMobileHeader(): void {
    this.display = this.deviceService.isMobile();
    this.systemOs = this.deviceService.os.toLowerCase();
  }
}
