import {Injectable} from '@angular/core';

import {BehaviorSubject} from 'rxjs';

import { RequestAppointmentInterface } from '../interfaces';


@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  public selectedVisit$: BehaviorSubject<RequestAppointmentInterface> = new BehaviorSubject(null);

  public selectedOptionId$: BehaviorSubject<string> = new BehaviorSubject(null);

  public setSelectedVisit(visit: RequestAppointmentInterface | null): void {
    this.selectedVisit$.next(visit);
  }

  public setSelectedOptionId(id: string): void {
    this.selectedOptionId$.next(id);
  }
}
