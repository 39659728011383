import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit
} from '@angular/core';
import {Subject} from 'rxjs';
import {NotificationService, PatientApiService} from '../../../core/services';
import { finalize, takeUntil } from 'rxjs/operators';
import {OfflineMedicalHistoryInterface} from '../../../core/interfaces/patients/offline-medical-history.interface';
import {
  OfflineEditAnswersMedicalHistoryInterface
} from '../../../core/interfaces/patients/offline-edit-answers-medical-history.interface';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { QuestionAnswerInterface } from '../../../core/interfaces';

@Component({
  selector: 'vi-clinic-offline-ehr-personal-medical-history-dialog',
  templateUrl: './offline-ehr-personal-medical-history-dialog.component.html',
  styleUrls: ['./offline-ehr-personal-medical-history-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OfflineEhrPersonalMedicalHistoryDialogComponent implements OnInit, OnDestroy {
  public ehr: OfflineMedicalHistoryInterface;
  public questionAnswers: QuestionAnswerInterface[];
  public changed: boolean = false;

  private editMedicalHistory: OfflineEditAnswersMedicalHistoryInterface = {
    answers: [],
    questionnaireId: ''
  };
  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private readonly patientApiService: PatientApiService,
              private readonly changeDetectorRef: ChangeDetectorRef,
              private readonly dialogRef: MatDialogRef<OfflineEhrPersonalMedicalHistoryDialogComponent>,
              private readonly notificationService: NotificationService,
              @Inject(MAT_DIALOG_DATA) public readonly data: {
                patientId: string,
                readonly?: boolean,
                familyMemberId?: string
              }) {
  }

  @HostListener('click' || 'change', ['$event.target'])
  public onChange(event): void {
    if (!!event) {
      this.changed = true;
    }
  }

  public ngOnInit(): void {
    if(this.data?.readonly) {
      this.getQuestionAnswers();
    } else {
      this.getQuestionnaire();
    }

    this.changeDetectorRef.detectChanges();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public onNoClick(result: boolean): void {
    this.dialogRef.close(result);
  }

  public changeEvent(event: MatCheckboxChange): void {
    this.editMedicalHistory.answers.forEach(el => {
      if (el.questionId === event.source.ariaLabel) {
        event.checked ? el.selectedOptionIds.push(event.source.value) : el.selectedOptionIds.splice(el.selectedOptionIds.indexOf(event.source.value), 1);
      }
    });
  }

  public change(event: Event, questionId: string): void {
    this.editMedicalHistory.answers.map((el, index) => {
      if (el.questionId === questionId) {
        this.editMedicalHistory.answers[index].value = (event.target as HTMLInputElement).value;
      }
    });
  }

  public saveData(): void {
    this.patientApiService.editQuestionnaire(this.editMedicalHistory, this.data.patientId, this.data?.familyMemberId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
          this.notificationService.showSuccessNotification();
          this.onNoClick(true);
        },
        (error) => this.notificationService.showErrorNotification(error?.error?.detail));
  }

  private getQuestionnaire(): void {
    this.patientApiService.getQuestionnaire(this.data.patientId, this.data?.familyMemberId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(el => {
        this.ehr = el;
        if (el?.id) {
          this.editMedicalHistory.questionnaireId = el.id;
          el.questions.forEach(res => {
            const answer = {
              questionId: res.id,
              value: res?.answer ? res.answer.value : null,
              selectedOptionIds: res?.answer ? res.answer.selectedOptions : []
            };
            this.editMedicalHistory.answers.push(answer);
          });
          this.changeDetectorRef.detectChanges();
        }
      },
      (error) => {
        this.notificationService.showErrorNotification(error?.error?.detail);
        this.dialogRef.close();
      }
    );
  }

  private getQuestionAnswers(): void {
    this.patientApiService.getMedicalHistory(this.data.patientId, this.data?.familyMemberId)
      .pipe(
        takeUntil(this.unsubscribe$),
        finalize(() => this.changeDetectorRef.detectChanges())
      )
      .subscribe(questionAnswers => {
          this.questionAnswers = questionAnswers;
        },
        (error) => {
          this.notificationService.showErrorNotification(error?.error?.detail);
          this.dialogRef.close();
        }
      );
  }
}

