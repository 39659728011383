import {EnumValues} from 'enum-values';
import {ApplicationRoleEnum} from '../enums/application-role.enum';
import { RoleEnum } from '../enums';

export class User {
  public id?: string;
  public email: string;
  public phoneNumber: string;
  public phoneNumberVerified?: boolean;
  public emailVerified?: boolean;
  public firstName: string;
  public lastName: string;
  public roles?: ApplicationRoleEnum[] = [];
  public role?: RoleEnum;

  constructor(userData: any) {
    this.id = userData?.id;
    this.email = userData?.email;
    this.phoneNumber = userData?.phone_number;
    this.phoneNumberVerified = userData?.phone_number_verified;
    this.emailVerified = userData?.email_verified;
    this.firstName = userData?.given_name;
    this.lastName = userData?.family_name;
    this.roles = this.parseRoles(userData?.role);
    this.role = userData?.role;
  }

  get isDoctor(): boolean {
    return !!this.roles && this.roles?.includes(ApplicationRoleEnum.Doctor);
  }

  private parseRoles(rolesRawValue: string): ApplicationRoleEnum[] {
    const allRoles = EnumValues.getNamesAndValues(ApplicationRoleEnum);
    if (Array.isArray(rolesRawValue)) {
      const result: ApplicationRoleEnum[] = [];
      (rolesRawValue as string[]).forEach(role => {
        const targetRole = allRoles.find(t => t?.name?.toLowerCase() === role?.toLowerCase());
        if (!!targetRole) {
          result.push(targetRole?.value as ApplicationRoleEnum);
        }
      });

      return result;
    } else {
      const targetRole = allRoles.find(t => t?.name?.toLowerCase() === rolesRawValue?.toLowerCase());
      if (!!targetRole) {
        return [targetRole?.value as ApplicationRoleEnum];
      }
    }
  }
}
