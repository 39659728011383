export enum RequestVisitStepperEnum {
  requestVisit = '0' ,
  scheduleVisit = '1',
  choseFamilyMember = '2',
  choseLanguage = '3',
  chooseSpecialty = '4',
  medicalHistory = '5',
  contactInfo = '6',
  cards = '7',
  summary = '8'
}
