import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { OidcSecurityService } from 'angular-auth-oidc-client';

import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';

import { User } from '../models/user.model';

import { DoctorStatusEnum, RoleEnum, TimeModeEnum } from '../enums';

import { PatientGeneralInfoInterface, PersonalInfoInterface } from '../interfaces';
import { DateTimeService } from './date-time.service';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public isAuthenticated: boolean;
  public isDoctor: boolean;
  public timeMode: TimeModeEnum;
  public timeZoneId: string;
  public status$: ReplaySubject<DoctorStatusEnum> = new ReplaySubject(1);
  public userInfo$: BehaviorSubject<PersonalInfoInterface> = new BehaviorSubject(null);
  private currentUserSubject$: BehaviorSubject<User> = new BehaviorSubject(null);
  public currentUser$: Observable<User> = this.currentUserSubject$.asObservable();

  private patientSubject$: BehaviorSubject<PatientGeneralInfoInterface> = new BehaviorSubject(null);
  public patient$: Observable<PatientGeneralInfoInterface> = this.patientSubject$.asObservable();

  constructor(private readonly router: Router,
              private readonly dateTimeService: DateTimeService,
              private readonly languageService: LanguageService,
              private readonly oidcSecurityService: OidcSecurityService) {
  }

  public checkAuth(): void {
    this.oidcSecurityService.checkAuth().subscribe((loginResponse) => {
      this.handleAuthentication(loginResponse.isAuthenticated);
    }, () => {
      this.authorize();
    });
  }

  private handleAuthentication(isAuthenticated: boolean): void {
    if (isAuthenticated) {
      this.handleAuthenticatedUser();
    } else {
      this.authorize();
    }
  }

  public handleAuthenticatedUser(): void {
    this.oidcSecurityService.userData$.subscribe((value) => {
      this.updateUserDetails(value.userData);
    });
  }

  private updateUserDetails(value: any): void {
    this.isAuthenticated = true;
    this.isDoctor = value?.role === RoleEnum.Doctor;
    this.timeZoneId = value?.time_zone_id;
    this.timeMode = +TimeModeEnum[value.time_mode];
    this.languageService.currentLanguage = value.locale?.toLowerCase();
    this.dateTimeService.format = this.timeMode === TimeModeEnum.TwelveHours ? 'M/DD/YYYY' : 'DD.MM.YYYY';
    this.dateTimeService.locale = this.languageService.currentLanguage;
    this.currentUserSubject$.next(new User(value));
  }

  public setPatient(patient: PatientGeneralInfoInterface): void {
    this.patientSubject$.next(patient);
  }

  public authorize(): void {
    this.oidcSecurityService.authorize();
  }

  public renewToken(): void {
    this.oidcSecurityService.forceRefreshSession().subscribe();
  }

  public logout(): void {
    localStorage.removeItem('mobileHeader');
    this.oidcSecurityService.logoff().subscribe();
  }
}
