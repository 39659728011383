import { Component, Input, OnInit } from '@angular/core';
import {
  Event as RouterEvent,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';

import {map} from 'rxjs/operators';
import {merge, Observable} from 'rxjs';

import {SpinnerService} from '../../services';
import { FamilyMemberInterface } from '../../interfaces';


@Component({
  selector: 'vi-clinic-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
  @Input() public isProgressBar = false;

  public isLoadingHttp$: Observable<boolean> = this.spinnerService.onLoadingChanged$;
  public isLoading$: Observable<boolean>;

  constructor(private spinnerService: SpinnerService, private router: Router) {
  }

  public ngOnInit(): void {
    this.isLoading$ = merge(this.router.events.pipe(map(event => this.navigationInterceptor(event))), this.isLoadingHttp$);
  }

  private navigationInterceptor(event: RouterEvent): boolean {
    if (event instanceof NavigationStart) {
      return true;
    }
    if (event instanceof NavigationEnd) {
      return false;
    }

    if (event instanceof NavigationCancel) {
      return false;
    }
    if (event instanceof NavigationError) {
      return false;
    }

    return false;
  }
}
