import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import { EarningInterface, EarningsBalanceInterface, WithdrawalInterface } from '../../interfaces';


@Injectable({
  providedIn: 'root'
})
export class EarningsApiService {

  constructor(private readonly httpClient: HttpClient) {
  }

  public getEarnings(): Observable<EarningInterface[]> {
    return this.httpClient.get<EarningInterface[]>(`${environment.apiAddress}/api/doctor/earnings`);
  }

  public getWithdrawals(): Observable<WithdrawalInterface[]> {
    return this.httpClient.get<WithdrawalInterface[]>(`${environment.apiAddress}/api/withdraw`);
  }

  public getBalance(): Observable<EarningsBalanceInterface> {
    return this.httpClient.get<EarningsBalanceInterface>(`${environment.apiAddress}/api/doctor/balance`);
  }

  public requestWithdraw(amount: number): Observable<any> {
    return this.httpClient.post(`${environment.apiAddress}/api/withdraw`, {
      amount,
      currency: 'usd'
    });
  }

  public cancelWithdrawal(): Observable<null> {
    return this.httpClient.delete<null>(`${environment.apiAddress}/api/withdraw`);
  }


}
