import {Component, EventEmitter, OnInit, Output} from '@angular/core';

import { FilterPatientsInterface } from '../../../core/interfaces';

import {FilterItemEnum} from '../../../core/enums';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FilterPatientsFormGroup } from '../../../core/types/form-groups/filter-patients-form-group';


@Component({
  selector: 'vi-clinic-filter-patients',
  templateUrl: './filter-patients.component.html',
  styleUrls: ['./filter-patients.component.scss']
})
export class FilterPatientsComponent implements OnInit {
  @Output() public patientFilters = new EventEmitter<FilterPatientsInterface>();

  public filterItem = FilterItemEnum;
  public patientFiltersFormGroup: FormGroup<FilterPatientsFormGroup>;

  constructor(private readonly fb: FormBuilder) {
  }

  public ngOnInit(): void {
    this.setForms();
  }

  public applyFilters(): void {
    this.patientFilters.emit(this.patientFiltersFormGroup.value as FilterPatientsInterface);
  }

  public selectionChangePatientName(event: number): void {
    if (event > FilterItemEnum.None && this.patientFiltersFormGroup.controls.visitTime.value !== FilterItemEnum.None) {
      this.patientFiltersFormGroup.patchValue({
        visitTime: FilterItemEnum.None
      });
    }
  }

  public selectionChangeVisitTime(event: number): void {
    if (event > FilterItemEnum.None && this.patientFiltersFormGroup.controls.patientName.value !== FilterItemEnum.None) {
      this.patientFiltersFormGroup.patchValue({
        patientName: FilterItemEnum.None
      });
    }
  }

  public setForms(): void {
    this.patientFiltersFormGroup = this.fb.group<FilterPatientsFormGroup>({
      patientName: this.fb.control(FilterItemEnum.None),
      visitTime: this.fb.control(FilterItemEnum.None),
      location: this.fb.control(''),
    });
  }
}
