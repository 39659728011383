import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';

import {environment} from 'src/environments/environment';

import {AlertsInterface, PaginationInterface} from '../../interfaces/';
import {alertsTypeConstant} from '../../constants';


@Injectable({
  providedIn: 'root',
})
export class AlertsApiService {
  private readonly apiUrl: string = `${environment.apiAddress}/api`;

  constructor(private readonly httpClient: HttpClient) {
  }

  public getAlerts(userId: string, skip: number, take: number): Observable<PaginationInterface<AlertsInterface>> {

    return this.httpClient.get<PaginationInterface<AlertsInterface>>(`${this.apiUrl}/notifications?userId=${userId}&skip=${skip}&take=${take}`);
  }

  public updateAlerts(alertsId: string[]): Observable<void> {
    const alerts = {
      notificationIds: alertsId
    };

    return this.httpClient.post<void>(`${this.apiUrl}/notifications`, alerts);
  }

  public removeAlert(alertId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.apiUrl}/notifications/${alertId}`);
  }
}
