<div [ngClass]="{'video-card-inactive-wrapper': !participant?.isActive}" class="video-card-wrapper">
  <div class="video-card-img">
    <img *ngIf="participant?.photoUrl" alt="" src="{{participant?.photoUrl}}">

    <p *ngIf="!participant?.photoUrl">
      {{participant?.firstName[0].toUpperCase() + '' + participant?.lastName[0].toUpperCase() }}
    </p>
  </div>

  <h2 class="video-card-title">{{participant.firstName + ' ' + participant?.lastName}}</h2>

  <h3 *ngIf="participant?.role === role.Doctor" class="video-card-subtitle">{{'general.doctorLabel' | translate}}</h3>

  <h3 *ngIf="participant?.role === role.Patient" class="video-card-subtitle">{{'general.patientLabel' | translate}}</h3>
</div>
