<div *ngIf="patient?.id || patient?.patientId" class="patient-ehr-wrapper">
  <div class="patient-ehr-header">
    <span>{{'patientEHR.patientEHRLabel' | translate}}</span>

    <button (click)="exportEhr()" class="button-primary" mat-button>{{'patientEHR.exportEHRLabel' | translate}}</button>
  </div>

  <div *ngIf="patient?.id && currentRecord !== currentRecordPages.FamilyMembers" class="patient-information-block">
    <div class="patient-basic-information">
      <div class="patient-basic-information-left-part">
        <div class="patient-last-visit-wrapper">
          <span class="patient-title">{{patient?.fullName}}</span>

          <vi-clinic-request-status *ngIf="appointmentInfo"
                                    [appointmentInfo]="appointmentInfo"
                                    [showAppointmentStatus]="false"
                                    [role]="currentUser.role"
                                    class="request-status">
          </vi-clinic-request-status>

          <div>
          <span *ngIf="patient?.lastVisitDate" class="patient-last-visit">
            {{'general.lastVisitLabel' | translate}}:
          </span>

            <span *ngIf="patient?.visitDate" class="patient-last-visit">
            {{'general.visitTimeLabel' | translate}}:
          </span>

            <span *ngIf="patient?.lastVisitDate || patient?.visitDate" class="patient-last-date">
            {{patient?.lastVisitDate || patient?.visitDate | localDate:'shortDateTime'}}
          </span>
          </div>
        </div>
        <div class="patient-location-language">
          <div class="patient-location">
            <img alt="" src="assets/icons/general/location.svg">

            <span class="country-name">{{patient?.countryName}}</span>
          </div>

          <div *ngIf="currentRecord === currentRecordPages.VisitHistory && allowingEditingDate && isDoctorMode"
               class="follow-up-block">
            <span class="follow-up-label">{{'requestAppointmentStatus.followUpLabel' | translate}}:</span>
            <div class="follow-up-content">
            <span
              *ngIf="!appointmentInfo?.followUpScheduledStart">{{'visitHistory.notScheduledLabel' | translate}}</span>
              <span
                *ngIf="appointmentInfo?.followUpScheduledStart">{{appointmentInfo?.followUpScheduledStart | localDate:'shortDateTime' }}</span>
            </div>
          </div>

          <button
            (click)="scheduleNow()"
            *ngIf="currentRecord === currentRecordPages.VisitHistory && appointmentInfo.visitType === 0 && allowingEditingDate && appointmentInfo?.status === appointmentStatus.VisitFinished && isDoctorMode"
            class="button-primary"
            mat-button>{{'visitHistory.scheduleNowLabel' | translate}}</button>
        </div>
      </div>
      <div class="patient-basic-information-right-part">
        <p *ngIf="generalMedicalHistory?.lastUpdateDate" class="patient-last-update-title">
          {{'general.lastUpdateEHRLabel' | translate}}
        </p>

        <p *ngIf="generalMedicalHistory?.lastUpdateDate" class="patient-last-update-date">
          {{generalMedicalHistory?.lastUpdateDate | localDate:'shortDateTime'}}
        </p>
      </div>
    </div>

    <div *ngIf="isDoctorMode" class="control-buttons">
      <button (click)="openWriteMessageDialog()" class="button-white basic-icon-button" mat-button>
        <mat-icon [svgIcon]="'message-circle'" class="icon"></mat-icon>
        {{'general.writeMessageLabel' | translate}}
      </button>

      <button (click)="joinToRoom()" *ngIf="waitingRoomAvailability" class="button-green" mat-button>
        {{'general.joinToWaitingRoomLabel' | translate}}
      </button>

      <button (click)="openCreateCustomAppointmentOfferDialog()"  *ngIf="currentRecord === currentRecordPages.Patients" class="button-primary" mat-button>
        {{'patients.customAppointmentOfferLabel' | translate}}
      </button>

      <button (click)="removePatient()" *ngIf="canRemovePatient" class="remove-patient-list" mat-button>
        {{'general.removePatientListLabel' | translate}}
      </button>
    </div>
  </div>

  <div *ngIf="generalMedicalHistory" class="patient-general-information">
    <div *ngIf="appointmentInfo?.description" class="patient-current-complaint">
      <div class="patient-current-complaint-header">
        <h2 class="patient-subtitle">{{'patientEHR.currentComplaintLabel' | translate}}</h2>
        <div class="small-icon-button">
          <button (click)="changeCurrentComplaintSection()" mat-icon-button>
            <mat-icon>{{sectionCurrentComplaintStatus ? 'expand_less' : 'expand_more'}}</mat-icon>
          </button>
        </div>
      </div>

      <div *ngIf="sectionCurrentComplaintStatus">
        <p class="patient-description">{{appointmentInfo.description}}</p>
      </div>
    </div>

    <div class="patient-medical-history">
      <div class="patient-medical-history-header">
        <h2 class="patient-subtitle">{{'patientEHR.medicalHistoryLabel' | translate}}</h2>

        <div class="d-flex">
          <div class="small-icon-button">
            <button (click)="changeMedicalHistorySection()" mat-icon-button>
              <mat-icon>{{sectionMedicalHistoryStatus ? 'expand_less' : 'expand_more'}}</mat-icon>
            </button>
          </div>

          <div *ngIf="canEditDialog &&
                   currentRecord === currentRecordPages.FamilyMembers" class="small-raised-button m-l-1">
            <button (click)="editMedicalHistoryDialog()"
                    class="button-primary"
                    mat-raised-button>
              <mat-icon>{{generalMedicalHistory ? 'edit' : 'add'}}</mat-icon>
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="sectionMedicalHistoryStatus">
        <h3 class="patient-gray-subtitle">{{'patientEHR.personalInformationLabel' | translate}}</h3>

        <div class="patient-personal-information-wrapper">
          <div class="patient-personal-information-block">
            <span class="patient-personal-information-block-label">{{'patientEHR.genderLabel' | translate}}</span>

            <span *ngIf="generalMedicalHistory.gender === genderText.Male"
                  class="patient-personal-information-block-info">{{'general.maleLabel' | translate}}</span>

            <span *ngIf="generalMedicalHistory.gender === genderText.Female"
                  class="patient-personal-information-block-info">{{'general.femaleLabel' | translate}}</span>
          </div>

          <div class="patient-personal-information-block">
            <span class="patient-personal-information-block-label">{{'patientEHR.birthdayLabel' | translate}}</span>

            <span class="patient-personal-information-block-info">
              {{generalMedicalHistory?.dateOfBirth | localDate:'shortDate'}}</span>
          </div>

          <div class="patient-personal-information-block">
            <span class="patient-personal-information-block-label">{{'patientEHR.heightLabel' | translate}}</span>

            <span class="patient-personal-information-block-info">{{generalMedicalHistory?.height}}</span>
          </div>

          <div class="patient-personal-information-block">
            <span class="patient-personal-information-block-label">{{'patientEHR.weightLabel' | translate}}</span>

            <span class="patient-personal-information-block-info">{{generalMedicalHistory?.weight}}</span>
          </div>
        </div>

        <vi-clinic-medical-history
          [questionAnswers]="generalMedicalHistory.questionAnswers"></vi-clinic-medical-history>
      </div>
    </div>

    <div
      *ngIf="((callStarted && currentRecord === currentRecordPages.Video) || (currentRecord === currentRecordPages.VisitHistory))"
      class="patient-current-record">
      <h2 *ngIf="recommendationInfo || prescriptionInfo?.length"
          class="patient-subtitle">{{'patientEHR.currentRecordLabel' | translate}}</h2>

      <div class="patient-current-record-buttons">
        <div>
          <vi-clinic-records [role]="currentUser.role" [visitInfo]="recommendationInfo"></vi-clinic-records>

          <div *ngIf="isDoctorMode && allowingEditingDate" #editRecordButton>
            <button (click)="openEditRecommendationDialog()" class="patient-current-record-button" mat-button>
              <span *ngIf="recommendationInfo">{{'patientVisit.editRecordLabel' | translate}}</span>

              <span *ngIf="!recommendationInfo">{{'patientVisit.addRecordLabel' | translate}}</span>
            </button>
          </div>
        </div>

        <div>
          <vi-clinic-recommendation (prescriptionId)="deletedPrescription($event)"
                                    *ngFor="let prescription of prescriptionInfo"
                                    [appointmentId]="appointmentInfo.id"
                                    [doctorId]="doctorId"
                                    [showActionButtons]="isDoctorMode && allowingEditingDate"
                                    [recommendationInfo]="prescription">
          </vi-clinic-recommendation>

          <div *ngIf="isDoctorMode && allowingEditingDate" #addNewPrescriptionButton>
            <button (click)="openAddNewPrescriptionDialog()" class="button-primary" mat-button>
              {{'patientVisit.addNewRecommendationsLabel' | translate}}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="patient-previous-visits">
      <div class="patient-previous-visits-header ">
        <h2 class="patient-subtitle">{{'patientEHR.previousVisitsLabel' | translate}}</h2>

      <div class="basic-icon-button">
        <button
          *ngIf="isDoctorMode"
          #filterMenu="matMenuTrigger"
          [matMenuTriggerFor]="filters" mat-button>
          <mat-icon>filter_alt</mat-icon>

          {{'general.filterLabel' | translate}}
        </button>
      </div>

        <mat-menu #filters="matMenu" xPosition="before">
          <vi-clinic-filter-visits (visitFilters)="applyFilters($event)"></vi-clinic-filter-visits>
        </mat-menu>
      </div>
      <vi-clinic-patient-visit [role]="currentUser.role" *ngFor="let previousVisitsInfo of previousVisits"
                               [doctorId]="doctorId" [visitInfo]="previousVisitsInfo"
                               [showNewRecommendationButton]="isDoctorMode">
      </vi-clinic-patient-visit>

      <p *ngIf="!previousVisits?.length" class="no-record-found">
        {{'managePractice.noRecordsFoundLabel' | translate}}
      </p>
    </div>
  </div>

  <div *ngIf="currentRecord === currentRecordPages.FamilyMembers && patient?.id" class="patient-ehr-footer">
    <button (click)="openConfirmDialog()"
            mat-button>{{'patientEHR.deleteFamilyMemberLabel' | translate}}</button>
  </div>
</div>
