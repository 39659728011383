import {Component, Input} from '@angular/core';

import {ValidationService} from '../../../core/services';
import { FormControl } from '@angular/forms';


@Component({
  selector: 'vi-clinic-control-validation-message',
  styleUrls: ['./control-validation-message.component.scss'],
  templateUrl: './control-validation-message.component.html'
})
export class ControlValidationMessageComponent {
  @Input() public control: FormControl<any>;
  @Input() public translation: any;

  constructor(private readonly validationService: ValidationService) {
  }

  public get errorMessage(): string {
    return this.validationService.getValidatorErrorMessage(this.control, this.translation);
  }
}
