import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CountryISO } from 'ngx-intl-tel-input';
import { Subject } from 'rxjs';

import { filter, switchMap, take, takeUntil } from 'rxjs/operators';

import { RoleEnum } from './core/enums';
import { PatientGeneralInfoInterface } from './core/interfaces';

import { User } from './core/models/user.model';

import {
  AuthService,
  IconRegistry,
  LanguageService,
  PatientRequestsApiService,
  ProfileService,
  ProfileSettingsApiService, SideBarService,
  SocketService,
  StaticDataApiService,
  StaticDataService, VisitsApiService
} from './core/services';
import { MatDrawerMode } from '@angular/material/sidenav';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'vi-clinic-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  public sidebarExpanded = false;
  public isAuthenticated: boolean;
  public user: User;
  private readonly browser: string;
  private readonly unsubscribe$: Subject<void> = new Subject();

  public sidebarMode: MatDrawerMode = 'side';

  constructor(private readonly matIconRegistry: MatIconRegistry,
              private readonly domSanitizer: DomSanitizer,
              private readonly authService: AuthService,
              private readonly profileService: ProfileService,
              private readonly translateService: TranslateService,
              private readonly patientRequestsApiService: PatientRequestsApiService,
              private readonly languageService: LanguageService,
              private readonly profileSettingsApiService: ProfileSettingsApiService,
              private readonly socketService: SocketService,
              private readonly router: Router,
              private readonly deviceService: DeviceDetectorService,
              private readonly staticDataApiService: StaticDataApiService,
              private readonly staticDataService: StaticDataService,
              private readonly breakPointObserver: BreakpointObserver,
              private readonly visitsApiService: VisitsApiService,
              private readonly sideBarService: SideBarService
  ) {
    this.browser = this.deviceService.browser.toLowerCase();
  }

  public get currentLanguage(): string {
    return this.languageService.currentLanguage;
  }

  public get isSupportComponent(): boolean {
    return this.router.url.includes('support');
  }

  public ngOnInit(): void {
    this.authService.checkAuth();

    this.authService.currentUser$
      .pipe(
        filter((user) => !!user),
        switchMap((user) => {
          this.user = user;
          if (user.role === RoleEnum.Doctor) {
            return this.profileSettingsApiService.getCurrentDoctorData();
          } else if (this.user.role === RoleEnum.Patient) {
            return this.patientRequestsApiService.getPatientInfo();
          }
        }),
        take(1),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(
        (personalInfo) => {
          this.authService.userInfo$.next(personalInfo);
          this.profileService.updateCountryCode(personalInfo.address.countryCode);
          this.isAuthenticated = true;
          this.socketService.start();

          if (this.user.role === RoleEnum.Patient) {
            this.authService.setPatient(personalInfo as PatientGeneralInfoInterface);
            this.getPatientRequiredInfo();
          }
        }
      );

    IconRegistry.register(this.matIconRegistry, this.domSanitizer);

    this.translateService.use(this.currentLanguage);

    // GET STATIC DATA FROM API
    this.getCountriesFromApi();
    this.getStatesFromApi();
    // END OF GET STATIC DATA FROM API

    this.listenToDynamicSidebarModeChange();
  }

  public toggleSidebar(): void {
    this.sidebarExpanded = !this.sidebarExpanded;
  }

  public listenToDynamicSidebarModeChange(): void {
    this.breakPointObserver.observe('(max-width: 1000px)')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(result => {
        if (result.matches) {
          this.sidebarMode = 'over';
          this.sidebarExpanded = false;
        } else {
          this.sidebarMode = 'side';
          this.sidebarExpanded = true;
        }
      });
  }
  private getPatientRequiredInfo(): void {
    this.visitsApiService.getRequiredInfo().pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
      this.sideBarService.setPatientRequiredInfo(value);
    });
  }

  private getCountriesFromApi(): void {
    this.staticDataApiService.getCountries()
      .subscribe(countries => this.staticDataService.setCountries(countries));
  }

  private getStatesFromApi(): void {
    this.staticDataApiService.getStates(CountryISO.UnitedStates)
      .subscribe(states => this.staticDataService.setStates(states));
  }
}
