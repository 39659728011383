<mat-horizontal-stepper #stepper [linear]="true">
  <mat-step>
    <ng-template matStepLabel>
      <h1 class="page-title">
        {{'requestAppointmentStatus.acceptRequestLabel' | translate}}
      </h1>
    </ng-template>

    <div class="input-container">
      <mat-form-field appearance="outline" class="input-container-item-large">
        <mat-label>{{'patients.confirmRegistrationLabel' | translate}}</mat-label>

        <input matInput [formControl]="smsCode" type="number"/>
      </mat-form-field>

      <button (click)="resendCode()" class="button-primary" mat-button>
        {{'patients.resendCodeLabel' | translate}}
      </button>

      <div class="button-container w-100">
        <button (click)="closeDialog()" class="transparent-button" mat-button>
          {{'general.closeLabel' | translate}}
        </button>

        <button [disabled]="!smsCode.value" (click)="sendCode(stepper)" class="button-primary" mat-button>
          {{'general.saveLabel' | translate}}
        </button>
      </div>
    </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>
      <h1 class="page-title">
        {{'patients.patientsEHRLabel' | translate}} {{'patientEHR.personalInformationLabel' | translate | lowercase}}
      </h1>
    </ng-template>

      <vi-clinic-offline-ehr-general-information (noClick)="closeDialog()"
                                                 (patient)="addPersonalInfo($event)"
                                                 [patientId]="data.patientId">
      </vi-clinic-offline-ehr-general-information>
  </mat-step>
</mat-horizontal-stepper>

