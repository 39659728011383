import {Component, Input} from '@angular/core';

import {RecommendationInterface} from '../../../core/interfaces';
import { RoleEnum } from '../../../core/enums';


@Component({
  selector: 'vi-clinic-records',
  templateUrl: './records.component.html',
  styleUrls: ['./records.component.scss']
})
export class RecordsComponent {
  @Input() public visitInfo: RecommendationInterface;
  @Input() public role: RoleEnum;

 public roleEnum = RoleEnum;
}
