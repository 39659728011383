import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import { AcceptRequestDataInterface, RequestAppointmentInterface, RequestsPaginationInterface } from '../../interfaces';


@Injectable({
  providedIn: 'root'
})
export class RequestsApiService {

  constructor(private readonly httpClient: HttpClient) {
  }

  public getRequests(currentPage = 0, pageSize = 20): Observable<RequestsPaginationInterface<RequestAppointmentInterface>> {
    return this.httpClient.get<RequestsPaginationInterface<RequestAppointmentInterface>>(`${environment.apiAddress}/api/doctor/appointment-requests?currentPage=${currentPage}&pageSize=${pageSize}`);
  }

  public getRequestById(id: string): Observable<RequestAppointmentInterface> {
    return this.httpClient.get<RequestAppointmentInterface>(`${environment.apiAddress}/api/Appointment/${id}`);
  }

  public rejectKnownPatientRequest(id: string): Observable<null> {
    return this.httpClient.patch<null>(`${environment.apiAddress}/api/appointment/doctorreject/${id}`, {});
  }

  public rejectUnknownPatientRequest(id: string): Observable<null> {
    return this.httpClient.put<null>(`${environment.apiAddress}/api/appointment/doctorignore/${id}`, {});
  }

  public acceptTimeOptions(id: string, data: AcceptRequestDataInterface): Observable<RequestAppointmentInterface> {
    return this.httpClient.patch<RequestAppointmentInterface>(`${environment.apiAddress}/api/appointment/doctoraccept/${id}`, data);
  }

  public sendProposedTimeOptions(id: string, data: { options: Date[] }): Observable<null> {
    return this.httpClient.patch<null>(`${environment.apiAddress}/api/appointment/proposeschedule/${id}`, data);
  }

  public getCurrentDoctorSchedule(): Observable<{ result: string[] }> {
    return this.httpClient.get<{ result: string[] }>(`${environment.apiAddress}/api/doctor/schedule`);
  }

  public postScheduleVisit(id: string, data: { options: Date[] }): Observable<null> {
    return this.httpClient.post<null>(`${environment.apiAddress}/api/FollowUp/schedule/${id}`, data);
  }
}
