import {Component, OnInit} from '@angular/core';
import {CalendarEvent} from 'angular-calendar';

import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

import * as moment from 'moment';

import {
  AppointmentsApiService,
  LanguageService,
  PatientService,
  SelectedAppointmentService
} from '../../../core/services';

import {
  Patient,
  PatientInterface,
  RequestAppointmentInterface,
  UserGeneralInfoInterface
} from '../../../core/interfaces';

import {AppointmentStatusEnum, AppointmentTypeEnum} from '../../../core/enums';


@Component({
  selector: 'vi-clinic-calendar-month-view',
  templateUrl: './calendar-view.component.html',
  styleUrls: ['./calendar-view.scss']
})
export class CalendarViewComponent implements OnInit {
  public viewDate: Date = new Date();
  public events: CalendarEvent[] = [];
  public myView: string = 'week';
  private unsubscribe$: Subject<void> = new Subject<void>();
  private appointmentStatus = AppointmentStatusEnum;
  private appointmentType = AppointmentTypeEnum;
  private appointments: RequestAppointmentInterface[];
  private currentPatientInfo: UserGeneralInfoInterface;
  private startDayOfWeek: Date;
  private endDayOfWeek: Date;

  constructor(private appointmentsApiService: AppointmentsApiService,
              private readonly patientService: PatientService,
              private readonly selectedAppointmentService: SelectedAppointmentService,
              private readonly languageService: LanguageService) {
  }

  public get localLanguage(): string {
    return this.languageService.currentLanguage;
  }

  public ngOnInit(): void {
    this.updateDate(this.viewDate);
    this.getAppointments();
  }

  public startOfWeek(date): any {
    this.updateDate(date);
    this.getAppointments();
  }

  public onClickEvent(id: string): void {
    if (id) {
      const index = this.appointments.findIndex(element => element.id === id);

      this.currentPatientInfo = this.appointments[index].familyMemberInfo || this.appointments[index].patientInfo;
      this.patientService.setPatientInfo(new Patient(this.currentPatientInfo, this.appointments[index]) as PatientInterface);
      this.selectedAppointmentService.setSelectedAppointment(this.appointments[index]);
    }
  }

  private getAppointments(): void {
    this.appointmentsApiService.getCalendarAppointments(this.startDayOfWeek, this.endDayOfWeek)
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe((appointments) => {
        this.events = [];
        this.appointments = appointments;
        appointments.forEach((el) => {
          this.events.push({
            id: el?.id,
            start: el.followUpScheduledStart ? this.getDate(el.followUpScheduledStart) : this.getDate(el.scheduledStart),
            title: this.createTitle(el?.familyMemberInfo?.firstName, el.patientInfo?.firstName, el.followUpScheduledStart || el.scheduledStart),
            cssClass: this.checkStatus(el)
          });
        });
      });
  }

  private updateDate(date: any): void {
    const d = new Date(date);
    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6 : 1);

    this.endDayOfWeek = new Date();
    this.startDayOfWeek = new Date(d.setDate(diff));
    this.startDayOfWeek.setHours(0, 0, 0);
    this.endDayOfWeek.setDate(this.startDayOfWeek.getDate() + 7);
  }

  private getDate(date: string): Date {
    const newDate = new Date(date);

    return newDate;
  }

  private createTitle(familyMemberFirstName: string, patientFirstName: string, time: string): string {
    const date = moment(time).format('HH:mm');
    const title = familyMemberFirstName ? familyMemberFirstName + ' ' + date : patientFirstName + ' ' + date;

    return title;
  }

  private checkStatus(appointmentInfo: RequestAppointmentInterface): string {
    if (appointmentInfo.status === this.appointmentStatus.FollowUpScheduled) {
      return 'follow-up';
    } else if (appointmentInfo.status === this.appointmentStatus.Scheduled
      && appointmentInfo.appointmentType === this.appointmentType.CertainDoctor) {
      return 'patient-appointment';
    } else if (appointmentInfo.status === this.appointmentStatus.Scheduled
      && appointmentInfo.appointmentType === this.appointmentType.AvailableDoctor) {
      return 'on-demand';
    } else if (appointmentInfo.status === this.appointmentStatus.Ongoing) {
      return 'ongoing';
    } else {
      return 'finished';
    }
  }
}
