import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DoctorAvailabilityStatusEnum } from '../../../core/enums';

import { DoctorInterface } from '../../../core/interfaces';

import { DoctorService } from '../../../core/services';


@Component({
  selector: 'vi-clinic-doctor-list-item',
  templateUrl: './doctor-list-item.component.html',
  styleUrls: ['./doctor-list-item.component.scss']
})
export class DoctorListItemComponent implements OnInit, OnDestroy {
  @Input() public doctorInfo: DoctorInterface;

  public doctorAvailabilityStatusEnum = DoctorAvailabilityStatusEnum;
  public isCurrentDoctor: boolean;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private readonly doctorService: DoctorService) {
  }

  public ngOnInit(): void {
    this.doctorService.getDoctorInfo()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (doctor) => {
          this.isCurrentDoctor = doctor?.id === this.doctorInfo.id;
        }
      );
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public sendDoctorInfo(): void {
    if (!this.isCurrentDoctor) {
      this.doctorService.setDoctorInfo(this.doctorInfo);
    }
  }
}
