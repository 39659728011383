import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { PrescriptionInterface } from '../../../core/interfaces';
import {
  strengthTypeListConstant,
  usageFormListConstant,
  usageFrequencyListConstant,
  usageRouteListConstant
} from '../../../core/constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PrescriptionFormGroup } from '../../../core/types';


@Component({
  selector: 'vi-clinic-edit-prescription',
  templateUrl: './edit-prescription.component.html',
  styleUrls: ['./edit-prescription.component.scss']
})
export class EditPrescriptionComponent implements OnInit {
  @Input() public isOffline: boolean = false;

  @Output() public closeForm = new EventEmitter<void>();
  @Output() public prescriptionResult = new EventEmitter<PrescriptionInterface | boolean>();
  @Output() public back = new EventEmitter<void>();

  public data: {
    prescription?: PrescriptionInterface,
    isEdit?: boolean;
  } = {isEdit: false};

  public prescriptionFormGroup: FormGroup<PrescriptionFormGroup>;
  public usageFrequency = usageFrequencyListConstant;
  public strengthType = strengthTypeListConstant;
  public usageForm = usageFormListConstant;
  public usageRoute = usageRouteListConstant;

  constructor(private readonly fb: FormBuilder) {
  }

  @Input()
  public set setData(data: {
    prescription?: PrescriptionInterface,
    isEdit?: boolean;
  }) {
    this.data = data;
  }

  public ngOnInit(): void {
    this.prescriptionFormGroup = this.fb.group<PrescriptionFormGroup>({
      tradeName: this.fb.control(this.data?.prescription?.tradeName || ''),
      genericName: this.fb.control(this.data?.prescription?.genericName || ''),
      strength: this.fb.control(this.data?.prescription?.strength, [Validators.required, Validators.min(0), Validators.max(100000)]),
      strengthType: this.fb.control(this.data?.prescription?.strengthType, Validators.required),
      usageCount: this.fb.control(this.data?.prescription?.usageCount, [Validators.required, Validators.min(0), Validators.max(100000)]),
      usageForm: this.fb.control(this.data?.prescription?.usageForm, Validators.required),
      usageRoute: this.fb.control(this.data?.prescription?.usageRoute, Validators.required),
      usageReps: this.fb.control(this.data?.prescription?.usageReps, [Validators.required, Validators.min(0), Validators.max(100000)]),
      usageFrequency: this.fb.control(this.data.prescription.usageFrequency, Validators.required),
      during: this.fb.control(this.data?.prescription?.during, [Validators.required, Validators.min(0), Validators.max(100000)]),
      description: this.fb.control(this.data?.prescription?.description || ''),
      appointmentId: this.fb.control(this.data?.prescription?.appointmentId || ''),
      id: this.fb.control(this.data?.prescription?.id || '')
    });
  }


  public onCloseForm(): void {
    this.closeForm.emit();
  }

  public goBack(): void {
    this.back.emit();
  }

  public saveForm(save: boolean): void {
    this.prescriptionResult.emit(save ? save : this.prescriptionFormGroup?.value as PrescriptionInterface | boolean);
  }
}
