import {Pipe, PipeTransform} from '@angular/core';

import {UserGeneralInfoInterface} from '../../core/interfaces';


@Pipe({
  name: 'chatName'
})

export class ChatNamePipe implements PipeTransform {
  public transform(participants: UserGeneralInfoInterface[], id: string): string {
    const filteredParticipants = participants.filter((user) => user.id !== id);

    return filteredParticipants.map((user) => `${user.firstName} ${user.lastName}`).join(', ');
  }
}
