import {Injectable} from '@angular/core';
import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

import {environment} from '../../../environments/environment';


@Injectable()
export class LanguageInterceptor implements HttpInterceptor {

  private languageCode: string = localStorage.getItem('currentLanguageLocale');

  public intercept(httpRequest: HttpRequest<unknown>, next: HttpHandler): any {

    if (httpRequest.url.includes(environment.apiAddress)) {
      return next.handle(
        httpRequest.clone({
          setParams: {language: this.languageCode}
        }));
    }

    return next.handle(httpRequest);
  }
}


