<div *ngIf="isVideoCall || isAppointmentPage || isVisitHistoryPage || isDoctorProfilePage || chatHistory" class="chat"
     xmlns="http://www.w3.org/1999/html">
  <div class="header">
    <div class="header-info">
      <h2 class="full-name">
        {{ chatHistory?.chatName }}

        <span class="participants-names">{{ participantsNames }}</span>
      </h2>
    </div>

    <div
      *ngIf="!isVideoCall && !isAppointmentPage && !isVisitHistoryPage && !isDoctorProfilePage && !isRequestsPage else patientsEhrOrReturnBackButton">
      <button [matMenuTriggerFor]="chatOptions"
              mat-icon-button>
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #chatOptions>
        <button (click)="openEhrPage()"
                *ngIf="!chatHistory?.isDoctors && currentUser?.role !== roleEnum.Patient && !chatHistory.isGroupChat"
                class="option-button"
                mat-menu-item>
          {{ 'patients.patientsEHRLabel' | translate }}
        </button>

        <button (click)="openChatMembers()" *ngIf="chatHistory.isGroupChat"
                mat-menu-item>
          {{ 'messages.chatMembersLabel' | translate }}
        </button>

        <button (click)="openAddColleaguesDialog()" *ngIf="chatHistory.isGroupChat && chatHistory?.isDoctors"
                mat-menu-item>
          {{ 'messages.addAColleagueLabel' | translate }}
        </button>

        <button (click)="openAddPatientsDialog()" *ngIf="chatHistory.isGroupChat && chatHistory?.isDoctors"
                mat-menu-item>
          {{ 'messages.addAPatientLabel' | translate }}
        </button>

        <button (click)="editChatName()" *ngIf="chatHistory.isGroupChat"
                mat-menu-item>
          {{ 'messages.editChatNameLabel' | translate }}
        </button>

        <button (click)="cleanChat()" mat-menu-item>
          {{'messages.clearChatLabel' | translate}}
        </button>

        <div>
          <button *ngIf="!chatHistory?.isAIChat" (click)="removeChat()" class="red-menu-item"
                  mat-menu-item>
            {{'messages.removeChatLabel' | translate}}
          </button>
        </div>
      </mat-menu>
    </div>

    <ng-template #patientsEhrOrReturnBackButton>
      <button class="basic-icon-button button-white transparent-button white-space-nowrap"
              *ngIf="(currentUser?.role !== roleEnum.Patient || isVideoCall) && !isRequestsPage" (click)="openEhrPage()" mat-button>
        <mat-icon [svgIcon]="'info-icon'"></mat-icon>

        {{ 'patients.patientsEHRLabel' | translate }}
      </button>

      <button mat-icon-button (click)="returnBack()"
              *ngIf="(currentUser?.role === roleEnum.Patient && !isVideoCall) || isRequestsPage">
        <mat-icon>close</mat-icon>
      </button>
    </ng-template>
  </div>

  <div #messageContainer (scroll)="onChatScroll($event)" [ngClass]="{'ai-chat-wrapper': isDoctorAiPage}" class="chat-wrapper">
    <div *ngFor="let message of messages; let i = index; trackBy: trackMessages" [ngClass]="{'current-user-message': message?.createdBy === currentUser.id,
                     'last-block-message': i !== messages.length - 1 &&  message?.createdBy !== messages[i - 1]?.createdBy,
                     'empty-message': !message.text && !message.originalFileName}" class="message-wrapper">
      <div (becomeVisible)="messageBecomesVisible(message)"
           *ngIf="message.text || message.originalFileName"
           [debounceTime]="300"
           [ngClass]="{'file-message': !message.text && message.originalFileName,
                       'message-item-top': message.text && message.originalFileName}"
           class="message-item"
           viClinicObserveVisibility>
        <div class="info">
          <div class="details">
            <span class="name">{{ message.participant.firstName + ' ' + message.participant.lastName }}</span>

            <span class="time">{{ message.createdTimestamp | timeAgo }}</span>
          </div>

          <div class="small-icon-button">
            <button *ngIf="message.id" [matMenuTriggerFor]="messageOptions"
                    class="round-button message-option-button"
                    mat-icon-button>
              <mat-icon>more_vert</mat-icon>
            </button>
          </div>

          <mat-menu #messageOptions>
            <button (click)="removeMessage(message.id)" class="red-menu-item"
                    mat-menu-item>
              {{ 'general.removeLabel' | translate }}
            </button>
          </mat-menu>
        </div>

        <div class="text">
          <a [href]="message.attachmentUrl" download>
            <img *ngIf="!message.text && message.originalFileName"
                 src="assets/icons/general/download-icon.svg">
          </a>

          <p>{{ message.text || message.originalFileName }}</p>
        </div>
      </div>

      <div *ngIf="message.text && message.originalFileName" class="message-item file-message file-message-bottom">
        <div class="text">
          <a [href]="message.attachmentUrl" download>
            <img src="assets/icons/general/download-icon.svg">
          </a>
          <p>{{ message.originalFileName }}</p>
        </div>
      </div>
    </div>
  </div>

  <vi-clinic-chat-footer
    [isAttachmentsAllowed]="chatHistory?.isAttachmentsAllowed"
    (onAttachFileClick)="openFileUploadDialog()"
    (onSendMessage)="sendMessage($event)">
  </vi-clinic-chat-footer>
</div>
