import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';

import {environment} from 'src/environments/environment';

import {
  ColleaguesInterface,
  InvitationInterface,
  PatientInterface,
  PostInvitationUserInterface,
  RequestsPaginationInterface
} from '../../interfaces';
import {PatientSearchInterface} from '../../interfaces/patients/patient-search.interface';


@Injectable({
  providedIn: 'root',
})
export class MyNetworkApiService {
  private readonly apiUrl: string = `${environment.apiAddress}/api`;

  constructor(private readonly httpClient: HttpClient) {
  }

  public getRelatedPatients(): Observable<PatientInterface[]> {
    return this.httpClient.get<PatientInterface[]>(`${this.apiUrl}/doctor/relatedpatients`);
  }

  public getRelatedDoctors(): Observable<ColleaguesInterface[]> {
    return this.httpClient.get<ColleaguesInterface[]>(`${this.apiUrl}/doctor/relateddoctors`);
  }

  public getColleagues(): Observable<ColleaguesInterface[]> {
    return this.httpClient.get<ColleaguesInterface[]>(`${this.apiUrl}/chat/doctor-invite-list`);
  }

  public deleteRelatedUsers(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.apiUrl}/doctor/relatedusers/${id}`);
  }

  public getInvitations(): Observable<InvitationInterface[]> {
    return this.httpClient.get<InvitationInterface[]>(`${this.apiUrl}/invitation`);
  }

  public postInvitation(user: number, inviteUser: PostInvitationUserInterface): Observable<PostInvitationUserInterface> {
    return this.httpClient.post<PostInvitationUserInterface>(`${this.apiUrl}/invitation/${user}`, [inviteUser]);
  }

  public deleteInvitation(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.apiUrl}/invitation/${id}`);
  }

  public patientSearch(currentPage: number, pageSize: number, search: string): Observable<RequestsPaginationInterface<PatientSearchInterface>> {
    return this.httpClient.get<RequestsPaginationInterface<PatientSearchInterface>>(`${this.apiUrl}/PatientSearch`, {
      params: {
        currentPage: currentPage.toString(),
        pageSize: pageSize.toString(),
        search: search
      }
    });
  }
}
