import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { CountryISO } from 'ngx-intl-tel-input';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import {
  CountryInterface,
  FilterDoctorsInterface,
  SpecialityInterface
} from '../../../core/interfaces';
import { FilterSelectService, StaticDataService } from '../../../core/services';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FilterDoctorsFormGroup } from '../../../core/types';


@Component({
  selector: 'vi-clinic-filter-doctors',
  templateUrl: './filter-doctors.component.html',
  styleUrls: ['./filter-doctors.component.scss']
})
export class FilterDoctorsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public countries$: Observable<CountryInterface[]> = new Observable<CountryInterface[]>();
  @Input() public specialities: SpecialityInterface[] = [];
  @Input() public selectedCountry: string | null;
  @Input() public selectedSpeciality: string | null;
  @Input() public selectedState: string | null;
  @Input() public menuIsClosed: boolean;

  @Output() public doctorFilters = new EventEmitter<FilterDoctorsInterface>();

  public doctorFiltersFormGroup: FormGroup<FilterDoctorsFormGroup>;
  public filteredStates: string[] = [];
  public states: string[] = [];
  public stateSearch = this.fb.control<string>('');
  public countryISO = CountryISO;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private readonly fb: FormBuilder,
    private readonly staticDataService: StaticDataService,
    private readonly filterSelectService: FilterSelectService<string>
  ) {
  }

  public ngOnInit(): void {
    this.getStates();
    this.subscribeToSearchState();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('menuIsClosed' in changes) {
      this.setForms();
    }
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public applyFilters(): void {
    this.doctorFilters.emit(this.doctorFiltersFormGroup.value as FilterDoctorsInterface);
  }

  public clearFilters(): void {
    this.doctorFiltersFormGroup.reset();
    this.doctorFilters.emit(this.doctorFiltersFormGroup.value as FilterDoctorsInterface);
  }

  public setForms(): void {
    this.doctorFiltersFormGroup = this.fb.group<FilterDoctorsFormGroup>({
      countryCode: this.fb.control(this.selectedCountry ?? null),
      state: this.fb.control( this.selectedState ?? null),
      specialtyId: this.fb.control(this.selectedSpeciality ?? null),
    });

    this.doctorFiltersFormGroup.get('countryCode').valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((countryCode) => {
        if (countryCode !== this.countryISO.UnitedStates.toLowerCase()) {
          this.doctorFiltersFormGroup.patchValue({state: null});
        }
      });
  }

  private getStates(): void {
    this.staticDataService.getStates()
      .pipe(filter(states => !!states), takeUntil(this.unsubscribe$))
      .subscribe((states) => {
          this.states = states;
          this.filteredStates = [...this.states];
        }
      );
  }

  private subscribeToSearchState(): void {
    this.stateSearch.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      this.filteredStates = this.filterSelectService.filterSelectOptions(this.states, value);
    });
  }
}
