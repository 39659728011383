import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {DoctorApiService, NotificationService} from "../../../core/services";
import {DoctorProfileSettingsInterface} from "../../../core/interfaces";

@Component({
  templateUrl: './doctor-profile-dialog.component.html',
  styleUrls: ['./doctor-profile-dialog.component.scss']
})
export class DoctorProfileDialogComponent implements OnInit, OnDestroy {
  public doctorPersonalInfo: DoctorProfileSettingsInterface;

  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private readonly notificationService: NotificationService,
              private readonly doctorApiService: DoctorApiService,
              @Inject(MAT_DIALOG_DATA) public readonly data: { id: string }) {
  }

  public ngOnInit(): void {
    this.getDoctor();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getDoctor(): void {
    if (this.data?.id) {
      this.doctorApiService.getDoctor(this.data?.id)
        .pipe(
          takeUntil(this.unsubscribe$))
        .subscribe(doctorInfo => {
          this.doctorPersonalInfo = doctorInfo;
          this.doctorPersonalInfo.cost /= 100;
        }, (error) => this.notificationService.showErrorNotification(error?.error?.detail));
    }
  }
}
