import { Component, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import {
  DoctorInterface,
  DoctorRequestInterface,
  PostInvitationUserInterface,
} from '../../../core/interfaces';
import {
  DoctorApiService,
  MyNetworkApiService,
  NotificationService,
  PatientApiService
} from '../../../core/services';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { FormBuilder, FormControl } from '@angular/forms';
import { InvitationUserEnum } from '../../../core/enums';
import { InviteUserComponent } from '../../components/invite-user/invite-user.component';


@Component({
  selector: 'vi-clinic-add-new-colleague-dialog',
  templateUrl: './add-new-colleague-dialog.component.html',
  styleUrls: ['add-new-colleague-dialog.component.scss'],
})
export class AddNewColleagueDialogComponent implements OnInit, OnDestroy {
  @ViewChild('inviteUser') public inviteUserComponent: InviteUserComponent;

  public colleagueId: string;
  public colleagues: DoctorInterface[] = [];
  public colleagueControl: FormControl<string | any> = this.fb.control(null);

  private colleaguePage: number = 0;
  private totalColleagues: number = 0;

  private reachedListBottom$: Subject<void> = new Subject();
  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private readonly dialog: MatDialog,
              private readonly dialogRef: MatDialogRef<AddNewColleagueDialogComponent>,
              private readonly fb: FormBuilder,
              private readonly myNetworkApiService: MyNetworkApiService,
              private readonly notificationService: NotificationService,
              private readonly doctorApiService: DoctorApiService,
              private readonly patientApiService: PatientApiService,
              @Inject(MAT_DIALOG_DATA) private readonly data: { returnOfflineVisit: boolean }) {
  }


  @HostListener('window:beforeunload', ['$event'])
  public unloadHandler(event: BeforeUnloadEvent): void {
    event.preventDefault();
    event.returnValue = '';
  }

  public ngOnInit(): void {
    this.subscribeToSearchControl();
    this.subscribeScrollBottom();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public onNoClick(result: boolean | string): void {
    if (!result) {
      this.openConfirmDialog();
    } else {
      this.dialogRef.close(result);
    }
  }

  public sendInvitation(data: PostInvitationUserInterface): void {
    this.myNetworkApiService.postInvitation(InvitationUserEnum.Doctor, data)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        () => {
          this.inviteUserComponent.inviteFormGroup.reset();
          this.notificationService.showSuccessNotification();
          this.dialogRef.close();
        },
        (error) => this.notificationService.showErrorNotification(error.error.detail)
      );
  }

  public displayFn(user: DoctorInterface): string {
    return user && user.fullName ? user.fullName : '';
  }

  public resetSearch(): void {
    this.colleagueControl.reset();
    this.colleagues = [];
    this.colleagueId = null;
    this.colleaguePage = 0;
  }

  public addFavoriteUser(): void {
    this.patientApiService.favoriteUser(this.colleagueId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        () => {
          this.notificationService.showSuccessNotification();
          this.dialogRef.close(true);
        },
        (error) => this.notificationService.showErrorNotification(error?.error?.detail)
      );
  }

  public onListScroll($event) {
    const offsetBottom = $event.target.scrollHeight - $event.target.offsetHeight - $event.target.scrollTop;

    if (offsetBottom < 100 && this.totalColleagues > this.colleagues.length) {
      this.reachedListBottom$.next();
    }
  }

  private getDoctors(search: string, onScroll = false): void {
    if (!!search && search?.length > 1) {

      const params = {
        searchTerm: search,
        currentPage: this.colleaguePage,
        pageSize: 20
      }

      this.doctorApiService.getDoctors(params as DoctorRequestInterface)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (patients) => {
            if(onScroll) {
              this.colleagues.push(...patients.items);
              this.colleaguePage++;
            } else {
              this.colleagues = patients.items
              this.colleaguePage = 1;
            }

            this.totalColleagues = patients.total;
            this.colleagues.forEach(element => element.fullName = element.firstName + ' ' + element.lastName);
          },
          (error) => this.notificationService.showErrorNotification(error?.error?.detail)
        );
    }
  }

  private openConfirmDialog(): void {
    const confirmDialog = this.dialog.open(ConfirmationDialogComponent, {
      data: {title: 'notification.anSavedDataLabel'},
      disableClose: true
    });
    confirmDialog.afterClosed().pipe(takeUntil(this.unsubscribe$)).subscribe(value => value ? this.dialogRef.close() : '');
  }

  private subscribeToSearchControl(): void {
    this.colleagueControl.valueChanges
      .pipe(
        debounceTime(100),
        takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        if (typeof value === 'string' || value === null) {
          this.colleaguePage = 0;
          if (value) {
            this.getDoctors(value as string);
          }
          this.colleagueId = null;
        } else {
          this.colleagueId = value.id;
        }
      });
  }

  private subscribeScrollBottom(): void {
    this.reachedListBottom$
      .pipe(
        debounceTime(100),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => {
        this.getDoctors(this.colleagueControl.value as string, true);
        }
      );
  }
}
