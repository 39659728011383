import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { FamilyMemberInterface } from '../../../core/interfaces';

import { MedicalInformationService, PatientApiService, PatientService } from '../../../core/services';

import {
  CompleteMedicalHistoryDialogComponent
} from '../../dialogs/complete-medical-history-dialog/complete-medical-history-dialog.component';


@Component({
  selector: 'vi-clinic-medical-information-list-item',
  templateUrl: './medical-information-list-item.component.html',
  styleUrls: ['./medical-information-list-item.component.scss']
})
export class MedicalInformationListItemComponent implements OnInit, OnDestroy {
  @Input() public familyMember: FamilyMemberInterface;
  @Input() public patientId: string;

  @Output() public updateMedicalInformation = new EventEmitter<void>();

  public isCurrentFamilyMember: boolean;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private readonly medicalInformationService: MedicalInformationService,
              private readonly dialog: MatDialog,
              private readonly patientApiService: PatientApiService,
              private readonly patientService: PatientService) {
  }

  public ngOnInit(): void {
    this.medicalInformationService.getFamilyMemberInfo()
      .pipe(
        filter(value => !!value),
        takeUntil(this.unsubscribe$))
      .subscribe(
        (familyMember) => {
          this.isCurrentFamilyMember = familyMember?.id === this.familyMember?.id;

          if(this.isCurrentFamilyMember && familyMember.patientId === this.patientId && !this.familyMember.medicalHistoryUpdatedTimestamp) {
            this.openCompleteMedicalHistoryDialog();
          }
        }
      );
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public openFamilyMember(): void {

    if (!this.isCurrentFamilyMember) {
      this.medicalInformationService.setFamilyMemberInfo(this.familyMember);
      if (this.familyMember.medicalHistoryUpdatedTimestamp) {
        this.patientService.setPatientInfo({
          ...this.familyMember, ...{
            familyMemberId: this.familyMember.id,
            patientId: this.patientId
          }
        });
      } else {
        this.openCompleteMedicalHistoryDialog();
      }
    }
  }

  private openCompleteMedicalHistoryDialog(): void {
    this.patientService.setPatientInfo(null);
    const dialogRefs = this.dialog.open(CompleteMedicalHistoryDialogComponent);

    dialogRefs.afterClosed()
      .pipe(
        filter(value => !!value),
        takeUntil(this.unsubscribe$),
        switchMap((value) => this.patientApiService.editPersonalInfo(value, this.patientId, this.familyMember.id))
      )
      .subscribe(
        () => {
          this.medicalInformationService.setOpenMedicalHistoryDialog(true);
          this.patientService.setPatientInfo({
            ...this.familyMember, ...{
              familyMemberId: this.familyMember.id,
              patientId: this.patientId
            }
          });

          this.updateMedicalInformation.emit();
        });
  }
}
