<div class="doctor-profile-personal-info-wrapper">
  <div class="doctor-general-info d-flex">
    <div class="profile-avatar-container d-flex">
      <div class="avatar-image-wrapper">
        <div *ngIf="personalInfo?.availabilityStatus === doctorAvailabilityStatusEnum.Available"
             class="is-online"></div>

        <img class="avatar-image" *ngIf="personalInfo?.photoUrl else emptyAvatar" [src]="personalInfo?.photoUrl"
             alt="x">
        <ng-template #emptyAvatar>
          <img class="avatar-image" src="assets/icons/profile-settings/avatar-placeholder.svg" alt="x">
        </ng-template>
      </div>
      <div>
        <span class="profile-fullName">{{personalInfo?.firstName}} {{personalInfo?.lastName}}</span>
        <div class="d-block">
          <vi-clinic-star-rating [rating]="personalInfo?.averageRating"></vi-clinic-star-rating>
        </div>
        <span class="d-flex profile-address" *ngIf="personalInfo?.countryName || personalInfo?.city">
          <img class="location-marker" src="assets/icons/general/location.svg" alt="x">
          <span>{{personalInfo?.countryName}}, {{personalInfo?.city}}</span>
        </span>
      </div>
    </div>
    <div class="price-container">
      <button *ngIf="!personalInfo?.isVirtualAssistant && !isDialog" class="button-green m-b-1"
              [routerLink]="['../visits/create-appointment']"
              [queryParams]="{doctorId: personalInfo?.id}"
              mat-button>{{'doctorProfile.scheduleVisitLabel' | translate}}</button>

      <div class="visit-price-wrapper">
        <span class="visit-price">{{'doctorProfile.visitPriceLabel' | translate}}</span>
        <span class="visit-price-value">{{personalInfo?.cost | currency }}</span>
      </div>
    </div>
  </div>

  <div class="personal-information-wrapper">
    <div class="personal-info-box">
      <div class="info-box-description-wrapper d-flex">
        <span class="description-title">{{'managePractice.specialityLabel' | translate}}</span>
        <span class="info-description">{{personalInfo?.specialtyName}}</span>
      </div>
      <div class="info-box-description-wrapper d-flex">
        <span class="description-title">{{'doctorProfile.spokenLanguagesLabel' | translate}}</span>
        <span class="info-description">
          <ng-container *ngFor="let language of personalInfo?.languages; let last = last">
              <span>{{language.name}}<span *ngIf="!last">, </span></span>
          </ng-container>
        </span>
      </div>
    </div>

    <div class="personal-info-box">
      <div class="info-box-description-wrapper d-flex">
        <span class="description-title">{{'doctorProfile.areaOfExpertiseLabel' | translate}}</span>
        <span class="info-description">{{personalInfo?.specialtyDescription}}</span>
      </div>
    </div>

    <div class="personal-info-box">
      <div class="info-box-description-wrapper d-flex">
        <span class="description-title">{{'doctorProfile.medicalSchoolLabel' | translate}}</span>
        <span class="info-description">
          <ng-container *ngFor="let education of personalInfo?.educations; let last = last">
              <span>{{education.institutionName}}<span *ngIf="!last">, </span></span>
          </ng-container>
        </span>
      </div>
    </div>

    <div class="personal-info-box">
      <div class="info-box-description-wrapper d-flex">
        <span class="description-title">{{'profileSettings.practiceLocationLabel' | translate}}</span>
        <div class="info-description">
          <span *ngFor="let experience of personalInfo?.workExperiences" class="d-block">{{experience.address}}</span>
        </div>
      </div>
      <div class="info-box-description-wrapper d-flex">
        <span class="description-title">{{'doctorProfile.licenseStatesLabel' | translate}}</span>
        <div class="info-description">
          <span *ngFor="let experience of personalInfo?.medicalLicenses; let last = last">
            {{experience.stateOrRegion}}<span *ngIf="!last && experience.stateOrRegion">, </span>
          </span>
        </div>
      </div>
    </div>

    <div class="personal-info-box">
      <div class="info-box-description-wrapper d-flex">
        <span class="description-title">{{'doctorProfile.bioLabel' | translate}}
          ({{'doctorProfile.aboutLabel' | translate}})</span>
        <span class="info-description">{{personalInfo?.biography}}</span>
      </div>
    </div>

  </div>
</div>
