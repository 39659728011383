import {RequestAppointmentInterface, UserGeneralInfoInterface} from '../requests';

export interface PatientInterface {
  age?: number;
  firstName: string;
  lastName: string;
  countryName?: string;
  defaultLanguage?: number;
  gender?: number;
  id: string;
  familyMemberId?: string;
  appointmentId?: string;
  lastVisitDate?: Date | string;
  visitDate?: string;
  fullName?: string;
  patientId?: string;
}

export class Patient {
  constructor(generalInfo: UserGeneralInfoInterface, appointmentInfo: RequestAppointmentInterface) {
    const {firstName, lastName} = generalInfo;
    const {
      patientInfo,
      familyMemberInfo,
      id: appointmentId,
      scheduledStart: visitDate,
      countryName
    } = appointmentInfo;

    return {
      firstName,
      lastName,
      id: patientInfo.id,
      familyMemberId: familyMemberInfo?.id,
      appointmentId,
      visitDate,
      countryName,
      fullName: `${firstName} ${lastName}`
    };
  }
}
