<div class="list-item">
  <div (click)="selectItem(appointmentInfo)" [ngClass]="{'active': isItemSelected,
    'appointment': isAppointment,
    'callable-appointment': isAppointment && appointmentStatus}"
       class="list-item-wrapper">
    <div class="list-item-info-container">
      <vi-clinic-request-status (joinToWaitingRoomStatus)="checkAppointmentStatus($event)"
                                [appointmentInfo]="appointmentInfo"
                                [role]="role"
                                [isAppointment]="isAppointment">
      </vi-clinic-request-status>

      <div *ngIf="currentPatientInfo" class="patient-info">
        <h2 class="patient-full-name" *ngIf="role === 'doctor'; else patient">
          {{currentPatientInfo?.firstName + ' ' + currentPatientInfo?.lastName}}
        </h2>

        <ng-template #patient>
          <h2 class="patient-full-name">
            {{!!appointmentInfo.doctorInfo?.firstName && !!appointmentInfo.doctorInfo?.lastName ?
            (appointmentInfo.doctorInfo?.firstName + ' ' + appointmentInfo.doctorInfo?.lastName) : 'visits.availableDoctorLabel' | translate}}
          </h2>
        </ng-template>

        <span class="patient-location">
          <img alt="location icon" class="patient-location-icon" src="../../../../assets/icons/general/location.svg">

          <span>{{appointmentInfo?.countryName}}</span>
        </span>

        <img *ngIf="appointmentInfo?.countryCode?.toLocaleLowerCase() === countryCode?.Ukraine && isFree"
             class="patient-info-flag" src="assets/icons/general/ua-flag-icon.svg">
      </div>
    </div>

    <button (click)="$event.stopPropagation(); joinToRoom()" *ngIf="isAppointment && appointmentStatus else arrowButton"
            class="button-green" mat-button>
      {{'general.joinToWaitingRoomLabel' | translate}}
    </button>

    <ng-template #arrowButton>
      <button mat-icon-button>
        <mat-icon>arrow_forward</mat-icon>
      </button>
    </ng-template>
  </div>
</div>
