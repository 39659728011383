<div class="modal-wrapper">
  <h1 class="page-title">{{'medicalInformation.addNewFamilyMemberLabel' | translate}}</h1>

  <div [formGroup]="familyMemberFormGroup">
    <mat-form-field appearance="outline" class="input-container-item-large">
      <mat-label>{{'general.firstNameLabel' | translate}}</mat-label>

      <input formControlName="firstName" matInput/>
    </mat-form-field>

    <vi-clinic-control-validation-message [control]="familyMemberFormGroup.controls.firstName">
    </vi-clinic-control-validation-message>

    <mat-form-field appearance="outline" class="input-container-item-large">
      <mat-label>{{'general.lastNameLabel' | translate}}</mat-label>

      <input formControlName="lastName" matInput/>
    </mat-form-field>

    <vi-clinic-control-validation-message [control]="familyMemberFormGroup.controls.lastName">
    </vi-clinic-control-validation-message>

    <mat-form-field appearance="outline" class="input-container-item-large">
      <mat-label>{{'medicalInformation.familyMemberIsMyLabel' | translate}}</mat-label>

      <mat-select formControlName="relationship">
        <mat-option *ngFor="let familyMember of familyMemberRelationshipList" [value]="familyMember?.code">
          {{familyMember?.name | translate}}
        </mat-option>
      </mat-select>

      <vi-clinic-control-validation-message [control]="familyMemberFormGroup.controls.relationship">
      </vi-clinic-control-validation-message>
    </mat-form-field>
  </div>

  <div class="button-container">
    <button class="transparent-button" mat-button mat-dialog-close>{{'general.cancelLabel' | translate}}</button>

    <button (click)="add()" [disabled]="familyMemberFormGroup.invalid" class="button-primary"
            mat-button>{{'medicalInformation.addNewFamilyMemberLabel' | translate}}</button>
  </div>
</div>
`
