import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AuthService, LanguageService } from '../../core/services';
import { TimeModeEnum } from '../../core/enums';

@Pipe({name: 'localDate'})
export class LocalDatePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string,
              private readonly authService: AuthService,
              private readonly languageService: LanguageService) {
  }

  public transform(date: any, format = 'longDate', timezone?, locale?): any {
    const timeMode = this.authService.timeMode;
    let dateFormat;
    switch (format) {
      case 'longDateTime':
       dateFormat = timeMode === TimeModeEnum.TwelveHours ? 'MMMM dd,y, h:mm a' : 'dd.MMMM y, HH:mm';
        break;
      case 'shortDateTime':
        dateFormat = timeMode === TimeModeEnum.TwelveHours ? 'M/dd/y, h:mm a' : 'dd.MM.y, HH:mm';
        break;
      case 'shortDate':
        dateFormat = timeMode === TimeModeEnum.TwelveHours ? 'M/dd/y' : 'dd.MM.y';
        break;
      case 'shortTime':
        dateFormat = timeMode === TimeModeEnum.TwelveHours ? 'h:mm a' : 'HH:mm';
        break;
      default:
        dateFormat = format;
        break;
    }

    const datePipe = new DatePipe(this.languageService.currentLanguage);

    return datePipe.transform(date, dateFormat);
  }
}
