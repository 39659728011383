<div class="offline-visit-wrapper">
  <mat-horizontal-stepper #stepper [@.disabled]="true" [linear]="true">
    <mat-step>
      <ng-template matStepLabel>
        <h1 class="page-title">
          {{'patients.createInPersonVisitLabel' | translate}}
        </h1>
      </ng-template>

      <div [formGroup]="offlineVisit" class="filters">
        <mat-form-field appearance="outline" class="input-container-item-large mb-2">
          <mat-label>{{'patientEHR.searchPatientLabel' | translate}}</mat-label>

          <input [matAutocomplete]="auto"
                 formControlName="patient"
                 matInput/>

          <button mat-icon-button matSuffix class="m-r-1" (click)="resetSearch()"
                  *ngIf="offlineVisit.controls.patient.value">
            <mat-icon>close</mat-icon>
          </button>

          <mat-autocomplete [displayWith]="displayFn" #auto="matAutocomplete" autoActiveFirstOption class="no-scroll">
            <div (scroll)="onListScroll($event)" class="option-scroll">
              <mat-option
                *ngFor="let patient of patients"
                [value]="patient">
                {{patient.fullName}} {{patient.phoneNumber | mask}}
              </mat-option>

              <mat-option *ngIf="patients.length === 0" class="link-button alarm-message">
                {{'patientVisit.noRecordsLabel' | translate}}
              </mat-option>
            </div>
          </mat-autocomplete>
        </mat-form-field>

        <div class="add-new-patient">
          <p>{{'patientEHR.cantFindPatientLabel' | translate}}</p>
          <p>{{'patientEHR.addNewPatientFromLabel' | translate}}</p>
        </div>

        <mat-form-field appearance="outline" class="input-container-item-large mb-2">
          <mat-label>{{'patientVisit.viCodeLabel' | translate}}</mat-label>

          <input formControlName="viCode"
                 matInput/>

          <vi-clinic-control-validation-message [control]="offlineVisit.controls.viCode">
          </vi-clinic-control-validation-message>
        </mat-form-field>
      </div>

      <div class="button-container">
        <button (click)="closeDialog()" class="transparent-button" mat-button>
          {{'general.cancelLabel' | translate}}
        </button>

        <button (click)="firstStep(stepper)" [disabled]="offlineVisit?.invalid || !currentPatient?.patientId"
                class="button-primary" mat-button>
          {{'patientEHR.nextStepLabel' | translate}}
        </button>
      </div>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>
        <h1 class="page-title">
          {{'patientVisit.addRecordLabel' | translate}}
        </h1>
      </ng-template>

      <ng-template [ngIf]="stepper.selectedIndex===1">
        <vi-clinic-edit-recommendation (closeForm)="closeDialog()" (setForm)="secondStep($event, stepper)"
                                       [data]="{summary: recommendation, isEdit: false}"
                                       [isOffline]="true"></vi-clinic-edit-recommendation>
      </ng-template>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>
        <h1 class="page-title">
          {{'patientVisit.addNewRecommendationsLabel' | translate}}
        </h1>
      </ng-template>

      <ng-template [ngIf]="stepper.selectedIndex===2">
        <vi-clinic-edit-prescription (back)="goBack(stepper)"
                                     (closeForm)="closeDialog()"
                                     (prescriptionResult)="thirdStep($event)"
                                     [isOffline]="true"
                                     [setData]="prescriptionData"></vi-clinic-edit-prescription>
      </ng-template>
    </mat-step>
  </mat-horizontal-stepper>
</div>
