import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

export class IconRegistry {
  public static register(
    matIconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer): void {
    matIconRegistry.addSvgIcon(
      'star-icon-gray',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/rating/star-gray.svg'));
    matIconRegistry.addSvgIcon(
      'star-icon-gold',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/rating/star-gold.svg'));
    matIconRegistry.addSvgIcon(
      'request-patients',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/side-bar/requests-patients-icon.svg'));
    matIconRegistry.addSvgIcon(
      'appointments-icon',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/side-bar/appointments-icon.svg'));
    matIconRegistry.addSvgIcon(
      'manage-practice',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/side-bar/manage-practice-icon.svg'));
    matIconRegistry.addSvgIcon(
      'my-network',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/side-bar/my-network-icon.svg'));
    matIconRegistry.addSvgIcon(
      'message-icon',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/side-bar/message-icon.svg'));
    matIconRegistry.addSvgIcon(
      'notifications',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/side-bar/alerts-icon.svg'));
    matIconRegistry.addSvgIcon(
      'invitation-icon',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/side-bar/invitation-icon.svg'));
    matIconRegistry.addSvgIcon(
      'profile-settings',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/side-bar/profile-settings-icon.svg'));
    matIconRegistry.addSvgIcon(
      'question-mark',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/side-bar/question-mark-icon.svg'));
    matIconRegistry.addSvgIcon(
      'visit-history',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/side-bar/visit-history-icon.svg'));
    matIconRegistry.addSvgIcon(
      'ai-icon',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/side-bar/ai.svg'));
    matIconRegistry.addSvgIcon(
      'info',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/info-default.svg'));
    matIconRegistry.addSvgIcon(
      'message-circle',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/message-circle-icon.svg'));
    matIconRegistry.addSvgIcon(
      'info-icon',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/info-icon.svg'));
    matIconRegistry.addSvgIcon(
      'smile-icon',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/smile-icon.svg'));
    matIconRegistry.addSvgIcon(
      'copy-icon-green',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/copy-icon-green.svg'));
    matIconRegistry.addSvgIcon(
      'log-out-icon',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/side-bar/log-out-icon.svg'));
    matIconRegistry.addSvgIcon(
      'arrow-right',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/request-visit/arrow-right.svg'));
    matIconRegistry.addSvgIcon(
      'arrow-left',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/request-visit/arrow-left.svg'));
    matIconRegistry.addSvgIcon(
      'plus',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/plus.svg'));
    matIconRegistry.addSvgIcon(
      'active-heart',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/active-heart-icon.svg'));
    matIconRegistry.addSvgIcon(
      'inactive-heart',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/inactive-heart-icon.svg'));
    matIconRegistry.addSvgIcon(
      'calendar-icon',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/calendar-icon.svg'));
    matIconRegistry.addSvgIcon(
      'delete',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/delete-icon.svg'));
    matIconRegistry.addSvgIcon(
      'payments',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/payments.svg'));
    matIconRegistry.addSvgIcon(
      'rx',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/video/rx-icon.svg'));
    matIconRegistry.addSvgIcon(
      'prescription',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/video/prescription-icon.svg'));
  }
}
