import {Component, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import {
  CodeVerificationApiService,
  NotificationService,
  PatientApiService,
  PatientService
} from '../../../core/services';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { PatientEhrGeneralInfoInterface } from '../../../core/interfaces';
import { MatStepper } from '@angular/material/stepper';
import {
  OfflineEhrPersonalMedicalHistoryDialogComponent
} from '../offline-ehr-personal-medical-history-dialog/offline-ehr-personal-medical-history-dialog.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';


@Component({
  selector: 'vi-clinic-registration-patient-dialog',
  templateUrl: './registration-patient-dialog.component.html',
  styleUrls: ['./registration-patient-dialog.component.scss'],
})
export class RegistrationPatientDialogComponent implements OnDestroy {
  public smsCode: FormControl<string> = this.fb.control('', Validators.required);

  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private readonly dialog: MatDialog,
              private readonly fb: FormBuilder,
              private readonly dialogRef: MatDialogRef<RegistrationPatientDialogComponent>,
              private readonly codeVerificationsApiService: CodeVerificationApiService,
              private readonly patientApiService: PatientApiService,
              private readonly patientService: PatientService,
              private readonly notificationService: NotificationService,
              @Inject(MAT_DIALOG_DATA) public readonly data: { patientId: string }) {
  }

  @HostListener('window:beforeunload', ['$event'])
  public unloadHandler(event: BeforeUnloadEvent): void {
    event.preventDefault();
    event.returnValue = '';
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public resendCode(): void {
    this.codeVerificationsApiService.resendCodeVerification({userId: this.data.patientId})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.notificationService.showSuccessNotification(),
        (error) => this.notificationService.showErrorNotification(error?.error?.detail));
  }

  public sendCode(stepper: MatStepper): void {
    const model = {
      token: this.smsCode.value,
      userId: this.data.patientId
    };

    this.codeVerificationsApiService.sendCodeVerification(model)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
          stepper.next();
          this.patientService.refreshPatients$.next();
          this.notificationService.showSuccessNotification();
        },
        (error) => this.notificationService.showErrorNotification(error?.error?.detail));
  }

  public addPersonalInfo(event: PatientEhrGeneralInfoInterface): void {
    this.patientApiService.editPersonalInfo(event, this.data.patientId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
          this.dialog.open(OfflineEhrPersonalMedicalHistoryDialogComponent, {
            data: {
              patientId: this.data.patientId
            },
            disableClose: true
          });
          this.dialogRef.close()
          this.notificationService.showSuccessNotification();
        },
        (error) => this.notificationService.showErrorNotification(error?.error?.detail));
  }

  public closeDialog() {
    const confirmDialog = this.dialog.open(ConfirmationDialogComponent, {
      data: {title: 'notification.anSavedDataLabel'},
      disableClose: true
    });
    confirmDialog.afterClosed()
      .pipe(
        filter((value => !!value)),
        takeUntil(this.unsubscribe$))
      .subscribe(()=> this.dialogRef.close());
  }
}
