import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { waitElementToRender } from '../../../core/helpers/element-render-listener';

const RATING_SCALE = 10;

@Component({
  selector: 'vi-clinic-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
})
export class StarRatingComponent {

  @ViewChild('filledStars', {static: true}) public filledStars: ElementRef<HTMLDivElement>;
  @ViewChild('blankStars', {static: true}) public blankStars: ElementRef<HTMLDivElement>;
  @Output() public currentRating: EventEmitter<number> = new EventEmitter<number>();
  @Input() public selectable: boolean = false;
  @Input() public size: 'sm' | 'md' | 'lg' = 'sm';

  @Input() set rating(value: number) {
    if (value >= 0 && this.blankStars && this.filledStars) {
      this.originalRating = value;
      this.setRating(value);
    }
  }

  public originalRating: number;

  public onStarHover(starIndex: number): void {
    if (this.selectable) {
      const filledStars = this.filledStars.nativeElement;
      switch (starIndex) {
        case 1:
          filledStars.style.width = '20%';
          this.resetBlankStars();
          break;
        case 2:
          filledStars.style.width = '40%';
          this.resetBlankStars();
          break;
        case 3:
          filledStars.style.width = '60%';
          this.resetBlankStars();
          break;
        case 4:
          filledStars.style.width = '80%';
          this.resetBlankStars();
          break;
        case 5:
          filledStars.style.width = '100%';
          this.resetBlankStars();
          break;
      }
    }
  }

  public resetHover(): void {
    const filledStars = this.filledStars.nativeElement;
    filledStars.style.width = '0%';
    if (this.originalRating) {
      this.setRating(this.originalRating);
    }
  }

  private resetBlankStars(): void {
    const matIcons = Array.from(this.blankStars.nativeElement.getElementsByTagName('mat-icon'));
    matIcons.forEach(matIcon => {
      const svg = matIcon.getElementsByTagName('svg')[0];
      if (svg) {
        svg.getElementsByTagName('path')[0].style.fill = 'rgb(130, 142, 164)';
      }
    });
  }

  public setNewRating(rating: number): void {
    if (this.selectable) {
      this.originalRating = (RATING_SCALE / 5) * rating;
      this.setRating(this.originalRating);
      this.currentRating.emit(this.originalRating);
    }
  }

  private setRating(rating: number): void {
    this.resetRating();
    if (rating > 0) {
      const percentage = (rating / RATING_SCALE) * 100;
      let starCount = Math.round(percentage / 20 * 100) / 100;
      if (!Number.isInteger(starCount)) {
        starCount = Math.floor(starCount) + 1;
      }
      this.filledStars.nativeElement.style.width = `${percentage.toString()}%`;
      const matIcon = this.blankStars.nativeElement.getElementsByTagName('mat-icon')[starCount - 1];
      waitElementToRender(matIcon, 'svg').then((el: Element) => {
        el.getElementsByTagName('path')[0].style.fill = 'rgb(253, 187, 66)';
      });
    }
  }

  private resetRating(): void {
    this.filledStars.nativeElement.style.width = `0%`;
    this.resetBlankStars();
  }
}
