import { Component, Input } from '@angular/core';
import { DoctorReviewsInterface } from '../../../core/interfaces';

@Component({
  selector: 'vi-clinic-doctor-profile-reviews',
  templateUrl: './doctor-profile-reviews.component.html',
  styleUrls: ['./doctor-profile-reviews.component.scss']
})
export class DoctorProfileReviewsComponent {
  @Input() public doctorReviews: DoctorReviewsInterface[];
}
