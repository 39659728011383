import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';

import {environment} from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SupportApiService {

  constructor(private readonly httpClient: HttpClient) {
  }

  public postSupportMessage(message: string): Observable<string> {
    return this.httpClient.post<string>(`${environment.apiAddress}/api/support`, {message: message});
  }
}
