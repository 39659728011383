<div (click)="sendDoctorInfo()" class="list-item">
  <div [ngClass]="{'active': isCurrentDoctor}" class="list-item-wrapper">
    <div class="list-item-info-container">
      <div class="doctor-image">
        <div *ngIf="doctorInfo?.availabilityStatus === doctorAvailabilityStatusEnum.Available" class="is-online"></div>

        <img *ngIf="!doctorInfo.photoUrl; else avatarPhoto"
             src="assets/icons/profile-settings/avatar-placeholder.svg" alt="doctor">

        <ng-template #avatarPhoto>
          <img [src]="doctorInfo.photoUrl" alt="doctor">
        </ng-template>
      </div>

      <div>
        <div class="doctor-name">{{doctorInfo.firstName + ' ' + doctorInfo.lastName}}</div>

        <div class="doctor-specialty">{{doctorInfo.specialtyName}}</div>
      </div>
    </div>

    <button mat-icon-button>
      <mat-icon>arrow_forward</mat-icon>
    </button>
  </div>
</div>

