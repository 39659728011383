import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { StripeElementLocale } from '@stripe/stripe-js/types/stripe-js/elements-group';
import * as moment from 'moment';
import { StripeCardNumberComponent, StripeService } from 'ngx-stripe';
import { Subject } from 'rxjs';
import { filter, finalize, takeUntil } from 'rxjs/operators';
import { CardInterface } from '../../../core/interfaces';
import {
  DateTimeService,
  DeleteDialogService,
  NotificationService,
  PatientBillingApiService,
} from '../../../core/services';
import { PaymentProviderTypeEnum } from '../../../core/enums';
import { CardFormGroup } from '../../../core/types';
import { PaymentModeEnum } from '../../../core/enums';


@Component({
  selector: 'vi-clinic-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss']
})
export class CardsComponent implements OnInit, OnDestroy {
  @ViewChild(StripeCardNumberComponent) public card: StripeCardNumberComponent;

  @Input() public paymentProviderType = PaymentProviderTypeEnum.Stripe;
  @Input() public mode: PaymentModeEnum;
  @Input() public isSelectedVisit: boolean = false;

  @Output() public changeModeEmitter = new EventEmitter<PaymentModeEnum>();
  @Output() public selectedCardEmitter = new EventEmitter<CardInterface>();
  @Output() public isValidEmitter = new EventEmitter<boolean>();

  public cards: CardInterface[] = [];
  public elementsOptions: StripeElementsOptions = {
    locale: this.dateTimeService.locale as StripeElementLocale
  };
  public cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        color: '#262C38',
        fontWeight: '300',
        fontFamily: '"Roboto", sans-serif',
        fontSize: '14px',
        '::placeholder': {
          color: '#A2A2A4'
        },
      },
      invalid: {
        color: '#C93535'
      }
    },
  };
  public creditCardForm: FormGroup<CardFormGroup>;
  public modeEnum = PaymentModeEnum;
  public selectedOptionId: string;
  public selectedCard: CardInterface;
  public isLoading = false;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private readonly patientBillingApiService: PatientBillingApiService,
              private readonly stripeService: StripeService,
              private readonly fb: FormBuilder,
              private readonly deleteDialogService: DeleteDialogService,
              private readonly notificationService: NotificationService,
              private readonly dateTimeService: DateTimeService,
              private readonly translateService: TranslateService
  ) {
  }

  public ngOnInit(): void {
    this.setForm();
    this.getPatientBilling();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getPatientBilling(): void {
    this.isValidEmitter.next(false)
    this.patientBillingApiService.getPatientBilling(this.paymentProviderType)
      .pipe(
        finalize(() => this.validateCard()),
        takeUntil(this.unsubscribe$))
      .subscribe((patientBilling) => {
        this.cards = patientBilling.cards.map((el) => {
          return {
            ...el,
            shortExpirationYear: el.expirationYear.toString().substring(2, 4),
            isExpired: moment().isAfter(moment({
              year: el.expirationYear,
              month: el.expirationMonth - 1,
            }).endOf('month'))
          };
        });

        this.validateCard();
      });
  }

  private setForm(): void {
    this.creditCardForm = this.fb.group<CardFormGroup>({
      name: this.fb.control(''),
      saveDetails: this.fb.control(false)
    });
  }

  private addCard(token: string): void {
    const body = {
      cardToken: token,
      providerType: this.paymentProviderType,
    };

    this.patientBillingApiService.addPatientBilling(body)
      .pipe(
        finalize(() => this.isLoading = false),
        takeUntil(this.unsubscribe$))
      .subscribe(() => {
          this.notificationService.showSuccessNotification();
          this.changeMode(this.modeEnum.cardList);
          this.creditCardForm.reset();
          this.getPatientBilling();
        },
        (error) => this.notificationService.showErrorNotification(error?.error?.detail));
  }

  private deleteCard(cardId: string): void {
    this.isValidEmitter.next(false)
    this.patientBillingApiService.deletePatientBilling(this.paymentProviderType, cardId)
      .pipe(
        takeUntil(this.unsubscribe$))
      .subscribe(() => {
          this.getPatientBilling();
          this.notificationService.showSuccessNotification();
        },
        (error) => this.notificationService.showErrorNotification(error?.error?.detail));
  }

  private validateCard(): void {
    if(this.cards.length === 0 || this.cards.every((el) => el.isExpired === true)) {
      this.isValidEmitter.next(false)
    } else {
      this.isValidEmitter.next(true)
    }
  }

  public createToken(): void {
    this.isLoading = true;

    this.stripeService
      .createToken(this.card.element, {name: this.creditCardForm.get('name').value})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result.token) {
          this.addCard(result.token.id);
        } else if (result.error) {
          this.isLoading = false;
          this.notificationService.showErrorNotification(result?.error?.message);
        }
      });
  }

  public changeMode(mode: PaymentModeEnum): void {
    this.mode = mode;
    this.changeModeEmitter.next(mode);
  }

  public selectCard(card: CardInterface): void {
    if (card.isExpired || !this.isSelectedVisit) {
      return;
    }
    this.selectedCard = card;
    this.selectedCardEmitter.next(card);
  }

  public openConfirmDialog(cardId: string): void {
    const title = this.translateService.instant('payment.doYouWantDeleteCardLabel');

    this.deleteDialogService.openDeleteDialog(title)
      .pipe(
        filter(result => !!result),
      )
      .subscribe(() => {
        this.deleteCard(cardId);
      });
  }
}
